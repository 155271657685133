import { ApolloClient, InMemoryCache } from '@apollo/client';
import { BigNumber, constants, providers } from 'ethers';
import { ClaimInfo } from '../data-lib/data-model';
import {
    BullaItemEvent,
    categorizeBullaItemEvents,
    getLastPaymentDateFromLogs,
    getOnlyBullaItemRelatedLogs,
} from '../data-lib/data-transforms';
import { ClaimCreatedEvent } from '../data-lib/domain/bulla-claim-domain';
import { ERC721TransferEvent } from '../data-lib/domain/common-domain';
import { getClaimCreatedEvents, getERC721TransferEvents } from '../data-lib/dto/event-filters';
import { EventData, eventLogRequests, EventLogs, Requests } from '../data-lib/dto/events-dto';
import { EthAddress, toChecksumAddress } from '../data-lib/ethereum';
import { ClaimQueryResult, Claim__graphql, Token__graphql } from '../data-lib/graphql/graph-domain';
import { mapGraphEntitiesToBullaItemEvents } from '../data-lib/graphql/graphql-dto';
import {
    getItemEventsFromHumaQuery,
    getItemEventsFromUserQuery,
    HumaQueryResult,
    humaReceivableParamQuery,
    humaUserQuery,
    specificClaimQuery,
    specificInstantPaymentQuery,
    specificLoanOfferEvent,
    userQuery,
    UserQueryResult,
} from '../data-lib/graphql/userQuery';
import { intToDate } from '../data-lib/helpers';
import { getEventLogs } from '../data-lib/historical-logs';
import { ChainId, NetworkConfig, NETWORKS } from '../data-lib/networks';
import { buildExternalTxToken, unknownToken } from '../data-lib/tokens';
import { TokenInfoByChainIdAndAddress } from '../hooks/useTokenRepo';
import { LogsCache } from '../tools/storage';

export type RequestParams = {
    [reqeust in Requests]?: RequestInterface;
};
export type RequestInterface = {
    topics: (string | string[] | null)[] | (string | string[])[];
    eventData: EventData;
    fromAddress?: string;
};

const {
    bullaBankerCreated,
    debtorClaimCreated,
    creditorClaimCreated,
    claimPayment,
    claimRejected,
    claimRescinded,
    bullaTagUpdated,
    gnosisModuleDeploy,
    gnosisModuleEnabled,
    gnosisModuleDisabled,
    inboundTransfers,
    inboundInstantPayments,
    outboundInstantPayments,
    instantPaymentTagUpdates,
    outboundTransfers,
    transfers,
    transferredClaims,
    feePaid,
    financeAcceptedEvent,
    financeOfferedEvent,
    loanOfferAcceptedEvent,
    loanOfferRejectedEvent,
    loanOfferedEvent,
    invoiceApproved,
    invoiceFunded,
    invoiceKickbackAmountSent,
    invoiceUnfactored,
} = eventLogRequests;

export const getFactoringRequest = () => {
    return {
        invoiceApproved: {
            topics: invoiceApproved.topicSets(),
            eventData: invoiceApproved,
        },
        invoiceFunded: {
            topics: invoiceFunded.topicSets(),
            eventData: invoiceFunded,
        },
        invoiceKickbackAmountSent: {
            topics: invoiceKickbackAmountSent.topicSets(),
            eventData: invoiceKickbackAmountSent,
        },
        invoiceUnfactored: {
            topics: invoiceUnfactored.topicSets(),
            eventData: invoiceUnfactored,
        },
    };
};

export const getUserClaimIds = (claimEvents: EventLogs) => {
    const claimCreatedEvents = getClaimCreatedEvents([
        ...(claimEvents.debtorClaimCreated ?? []),
        ...(claimEvents.creditorClaimCreated ?? []),
        ...(claimEvents.transferredClaims ?? []),
    ]);
    return [...new Set(claimCreatedEvents.map(claimEvent => claimEvent.tokenId))];
};

export const filterClaimTransferEvents = (transferEvents: EventLogs) => {
    const mintFilter = (transfer: ERC721TransferEvent) => transfer.from !== constants.AddressZero;
    const allTransfers = getERC721TransferEvents([...(transferEvents.inboundTransfers ?? []), ...(transferEvents.outboundTransfers ?? [])]);
    const filteredTransferEvents = allTransfers.filter(mintFilter);
    return {
        filteredTransferEvents: {
            inboundTransfers: getERC721TransferEvents(transferEvents.inboundTransfers).filter(mintFilter),
            outboundTransfers: getERC721TransferEvents(transferEvents.outboundTransfers).filter(mintFilter),
        },
        transferredIds: [...new Set(filteredTransferEvents.map(claimEvent => claimEvent.tokenId))],
    };
};

export const filterDuplicateEventLogs = (events: BullaItemEvent[]) => {
    const uniqueEventLogMap = events.reduce<{ [txHashAndIndex: string]: BullaItemEvent }>(
        (acc, event) => ({ ...acc, [event.txHash + event.logIndex]: event }),
        {},
    );
    return Object.values(uniqueEventLogMap);
};

export const getBullaBankerCreatedRequest = (bullaManager: EthAddress) => ({
    bullaBankerCreated: { topics: bullaBankerCreated.topicSets(bullaManager), eventData: bullaBankerCreated },
});

export const getClaimTransfersRequest = (userAddress: EthAddress, bullaClaimAddress: EthAddress) => ({
    inboundTransfers: {
        topics: inboundTransfers.topicSets(userAddress),
        eventData: inboundTransfers,
        fromAddress: bullaClaimAddress,
    },
    outboundTransfers: {
        topics: outboundTransfers.topicSets(userAddress),
        eventData: outboundTransfers,
        fromAddress: bullaClaimAddress,
    },
});

/** @dev only get transferred claims if the user has any related transfer events */
export const getBullaInfoItemCreatedRequest = (
    userAddress: EthAddress,
    { bullaClaimAddress, bullaInstantPaymentAddress, bullaFinanceAddress, frendlendAddress }: NetworkConfig,
    transferredIds?: string[],
) => {
    const withoutFinance = {
        ...(transferredIds?.length
            ? {
                  transferredClaims: {
                      topics: transferredClaims.topicSets(transferredIds),
                      eventData: transferredClaims,
                      fromAddress: bullaClaimAddress,
                  },
              }
            : {}),
        debtorClaimCreated: {
            topics: debtorClaimCreated.topicSets(userAddress),
            eventData: debtorClaimCreated,
            fromAddress: bullaClaimAddress,
        },
        creditorClaimCreated: {
            topics: creditorClaimCreated.topicSets(userAddress),
            eventData: creditorClaimCreated,
            fromAddress: bullaClaimAddress,
        },
        inboundInstantPayments: {
            topics: inboundInstantPayments.topicSets(userAddress),
            eventData: inboundInstantPayments,
            fromAddress: bullaInstantPaymentAddress,
        },
        outboundInstantPayments: {
            topics: outboundInstantPayments.topicSets(userAddress),
            eventData: outboundInstantPayments,
            fromAddress: bullaInstantPaymentAddress,
        },
    };

    return !!bullaFinanceAddress && !!frendlendAddress
        ? {
              ...withoutFinance,
              financeOfferedEvent: {
                  topics: financeOfferedEvent.topicSets(),
                  eventData: financeOfferedEvent,
                  fromAddress: bullaFinanceAddress,
              },
              financeAcceptedEvent: {
                  topics: financeAcceptedEvent.topicSets(),
                  eventData: financeAcceptedEvent,
                  fromAddress: bullaFinanceAddress,
              },
              loanOfferedEvent: {
                  topics: loanOfferedEvent.topicSets(),
                  eventData: loanOfferedEvent,
                  fromAddress: frendlendAddress,
              },
              loanOfferAcceptedEvent: {
                  topics: loanOfferAcceptedEvent.topicSets(),
                  eventData: loanOfferAcceptedEvent,
                  fromAddress: frendlendAddress,
              },
              loanOfferRejectedEvent: {
                  topics: loanOfferRejectedEvent.topicSets(),
                  eventData: loanOfferRejectedEvent,
                  fromAddress: frendlendAddress,
              },
          }
        : withoutFinance;
};

export const getGnosisModuleDeployRequest = (safeAddress: EthAddress) => ({
    gnosisModuleDeploy: {
        topics: gnosisModuleDeploy.topicSets(safeAddress),
        eventData: gnosisModuleDeploy,
    },
});

export const getGnosisModuleEvents = (safeAddress: EthAddress) => ({
    gnosisModuleEnabled: {
        topics: gnosisModuleEnabled.topicSets(),
        eventData: gnosisModuleEnabled,
        fromAddress: safeAddress,
    },
    gnosisModuleDisabled: {
        topics: gnosisModuleDisabled.topicSets(),
        eventData: gnosisModuleDisabled,
        fromAddress: safeAddress,
    },
});

export const getBullaTagUpdatedRequest = (networkConfig: NetworkConfig, userAddress: EthAddress, tokenIds?: string[]) => {
    const { bullaManager, bullaBankerLatest } = networkConfig;
    return {
        bullaTagUpdated: {
            topics: bullaTagUpdated.topicSets(bullaManager, tokenIds, userAddress),
            eventData: bullaTagUpdated,
            fromAddress: bullaBankerLatest,
        },
    };
};

export const getBullaInfoItemUpdatesRequest = (userAddress: EthAddress, tokenIds: string[], networkConfig: NetworkConfig) => {
    const { bullaManager, bullaClaimAddress, batchCreate, bullaInstantPaymentAddress, bullaFinanceAddress } = networkConfig;
    const logTopics = {
        ...getBullaTagUpdatedRequest(networkConfig, userAddress),
        claimPayment: { topics: claimPayment.topicSets(bullaManager, tokenIds), eventData: claimPayment, fromAddress: bullaClaimAddress },
        transfers: { topics: transfers.topicSets(tokenIds), eventData: transfers, fromAddress: bullaClaimAddress },
        claimRejected: {
            topics: claimRejected.topicSets(bullaManager, tokenIds),
            eventData: claimRejected,
            fromAddress: bullaClaimAddress,
        },
        claimRescinded: {
            topics: claimRescinded.topicSets(bullaManager, tokenIds),
            eventData: claimRescinded,
            fromAddress: bullaClaimAddress,
        },
        feePaid: { topics: feePaid.topicSets(bullaManager, tokenIds), eventData: feePaid, fromAddress: bullaClaimAddress },
        instantPaymentTagUpdates: {
            topics: instantPaymentTagUpdates.topicSets(userAddress),
            eventData: instantPaymentTagUpdates,
            fromAddress: bullaInstantPaymentAddress,
        },
    };

    const withBatch = !!batchCreate
        ? {
              ...logTopics,
              batchBullaTagUpdated: {
                  topics: bullaTagUpdated.topicSets(bullaManager, undefined, userAddress),
                  eventData: bullaTagUpdated,
                  fromAddress: batchCreate.address,
              },
          }
        : logTopics;

    return !!bullaFinanceAddress
        ? {
              ...withBatch,
              financeAcceptedEvent: {
                  topics: financeAcceptedEvent.topicSets(tokenIds),
                  eventData: financeAcceptedEvent,
                  fromAddress: bullaFinanceAddress,
              },
          }
        : withBatch;
};

export const mergeCacheWithNewLogs = (cachedLogs: LogsCache | undefined, newLogs: EventLogs): EventLogs => {
    return cachedLogs
        ? Object.entries(cachedLogs.eventLogs).reduce<EventLogs>(
              (mergedLogs, [requestName, cachedLogs]) => ({
                  ...mergedLogs,
                  [requestName as Requests]: [...cachedLogs, ...(newLogs[requestName as Requests] ?? [])],
              }),
              {},
          )
        : newLogs;
};

export const getEventLogsViaEthGetLogs = async (
    networkConfig: NetworkConfig,
    queryAddress: EthAddress,
    provider: providers.Provider,
): Promise<BullaItemEvent[]> => {
    const eventLogs = await getEventLogs({
        networkConfig,
        provider,
        queryAddress,
    });
    const events = getOnlyBullaItemRelatedLogs(eventLogs);

    return events;
};

export const getEventLogsViaGraph = async (
    graphEndpoint: string,
    queryAddress: EthAddress,
    includeFinanceEvents?: boolean,
    includeFrendLendEvents?: boolean,
    includeFactoringEvents?: boolean,
    includeSwapEvents?: boolean,
): Promise<BullaItemEvent[]> => {
    const client = new ApolloClient({
        uri: graphEndpoint,
        cache: new InMemoryCache(),
    });
    let allEvents: BullaItemEvent[] = [];
    let skip = 0;

    while (true) {
        const { data } = await client.query<UserQueryResult>({
            query: userQuery(!!includeFinanceEvents, !!includeFrendLendEvents, !!includeFactoringEvents, !!includeSwapEvents),
            variables: {
                queryAddress: queryAddress.toLowerCase(),
                checksumAddress: queryAddress,
                first: 1000,
                skip,
            },
            fetchPolicy: 'network-only',
        });

        const events = getItemEventsFromUserQuery(data);
        allEvents = allEvents.concat(events);

        if (events.length < 1000) {
            break;
        }

        skip += 1000;
    }

    return allEvents;
};

export type HumaCreditLinesResult = {
    creditLines: {
        owner: string;
        state: number;
        pool: string;
        receivableParam: string;
        creditEvents: HumaQueryResult;
    }[];
};

export const queryIsClaimUnfactored = async (graphEndpoint: string, poolAddress: EthAddress, claimId: string) => {
    const client = new ApolloClient({
        uri: graphEndpoint,
        cache: new InMemoryCache(),
    });

    const { data } = await client.query<HumaQueryResult>({
        query: humaReceivableParamQuery(),
        variables: { poolAddress, claimId },
        fetchPolicy: 'network-only',
    });

    const hasPaymentMade = data.creditEvents.some(e => e.event === 4);
    const hasDrawdownMadeWithReceivable = data.creditEvents.some(e => e.event === 3);

    return hasPaymentMade && hasDrawdownMadeWithReceivable;
};

export const getHumaEventLogsViaGraph = async (graphEndpoint: string, queryAddress: EthAddress, poolAddress: EthAddress) => {
    const client = new ApolloClient({
        uri: graphEndpoint,
        cache: new InMemoryCache(),
    });

    let allCreditEvents: HumaQueryResult['creditEvents'] = [];
    let skip = 0;

    while (true) {
        const { data } = await client.query<HumaQueryResult>({
            query: humaUserQuery(),
            variables: { poolAddress, queryAddress: queryAddress.toLowerCase(), first: 1000, skip },
            fetchPolicy: 'network-only',
        });

        allCreditEvents = allCreditEvents.concat(data.creditEvents);

        if (data.creditEvents.length < 1000) {
            break;
        }

        skip += 1000;
    }

    return getItemEventsFromHumaQuery({ creditEvents: allCreditEvents });
};

type ReducedClaimInfo = Pick<
    ClaimInfo,
    | 'chainId'
    | 'claimAmount'
    | 'claimStatus'
    | 'creditor'
    | 'debtor'
    | 'dueBy'
    | 'created'
    | 'description'
    | 'paidAmount'
    | 'ipfsHash'
    | 'id'
    | 'tokenInfo'
>;

const resolveGqlToken = (chainId: ChainId, token: Token__graphql, getTokenByChainIdAndAddress: TokenInfoByChainIdAndAddress) => {
    if (!token.address) return unknownToken(chainId);
    if (token.address == constants.AddressZero) return NETWORKS[chainId].nativeCurrency.tokenInfo;

    const previouslyFetchedToken = getTokenByChainIdAndAddress(chainId)(token.address);
    if (previouslyFetchedToken) return previouslyFetchedToken;

    const { address, decimals, symbol } = token;
    return buildExternalTxToken(chainId, address, decimals, symbol);
};

export const tryGetSpecificClaim = async (
    { chainId, connections: { graphEndpoint }, factoringConfig }: NetworkConfig,
    getTokenByChainIdAndAddress: TokenInfoByChainIdAndAddress,
    id: string,
): Promise<ClaimInfo | undefined> => {
    const { data } = await new ApolloClient({
        uri: graphEndpoint,
        cache: new InMemoryCache(),
    }).query<ClaimQueryResult>({
        query: specificClaimQuery(!!factoringConfig),
        variables: { id: id.toLowerCase() },
        fetchPolicy: 'network-only',
    });

    if (!data.claim) return undefined;

    const claim = data.claim;
    const tokenInfo = resolveGqlToken(chainId, claim.token, getTokenByChainIdAndAddress);

    const allLogs = [
        ...claim.logs,
        ...(data.invoiceFundedEvents || []),
        ...(data.invoiceKickbackAmountSentEvents || []),
        ...(data.invoiceUnfactoredEvents || []),
    ];

    const logs = mapGraphEntitiesToBullaItemEvents(allLogs).reduce<BullaItemEvent[]>((acc, claimLog) => {
        const tagEvent = claimLog.__typename === 'BullaTagUpdatedEvent' || claimLog.__typename === 'InstantPaymentTagUpdatedEvent';

        return tagEvent ? acc : [...acc, claimLog];
    }, []);

    const { claimEvents, factoringEvents } = categorizeBullaItemEvents(logs);

    const allEvents = [...claimEvents, ...factoringEvents];
    const lastPaymentDate = getLastPaymentDateFromLogs(allEvents);

    const originalCreditor = allEvents.filter((x): x is ClaimCreatedEvent => x.__typename == 'ClaimCreatedEvent').map(x => x.creditor)[0];

    return {
        __type: 'Claim',
        claimType: 'Invoice',
        creditor: toChecksumAddress(claim.creditor.address),
        debtor: toChecksumAddress(claim.debtor.address),
        chainId: chainId as ChainId,
        claimAmount: BigNumber.from(claim.amount),
        claimStatus: claim.status,
        id: claim.tokenId,
        paidAmount: BigNumber.from(claim.paidAmount),
        tokenInfo,
        tags: [],
        description: claim.description,
        txHash: claim.transactionHash,
        created: intToDate(+claim.created),
        dueBy: intToDate(+claim.dueBy),
        ipfsHash: claim.ipfsHash ?? '',
        isTransferred: claim.isTransferred,
        origin: claim.creator.address,
        logs: allEvents,
        financingState: { kind: 'no-financing' },
        notes: '',
        lastPaymentDate,
        originalCreditor,
    };
};

export const tryGetSpecificLoanOfferEvent = async (
    graphEndpoint: string,
    id: string,
): Promise<{ creditor: string; debtor: string } | undefined> => {
    const { data } = await new ApolloClient({
        uri: graphEndpoint,
        cache: new InMemoryCache(),
    }).query<{ loanOfferedEvent: { creditor: string; debtor: string } | null }>({
        query: specificLoanOfferEvent,
        variables: { id: `LoanOffer-${id}` },
        fetchPolicy: 'network-only',
    });
    return data.loanOfferedEvent == null
        ? undefined
        : { creditor: toChecksumAddress(data.loanOfferedEvent.creditor), debtor: toChecksumAddress(data.loanOfferedEvent.debtor) };
};

export const tryGetSpecificInstantPayment = async (
    graphEndpoint: string,
    id: string,
): Promise<{ creditor: string; debtor: string; description: string } | undefined> => {
    const { data } = await new ApolloClient({
        uri: graphEndpoint,
        cache: new InMemoryCache(),
    }).query<{ instantPayment: { from: { address: string }; to: { address: string }; description: string } | null }>({
        query: specificInstantPaymentQuery,
        variables: { id: id.toLowerCase() },
        fetchPolicy: 'network-only',
    });

    return data.instantPayment == null
        ? undefined
        : {
              creditor: toChecksumAddress(data.instantPayment.to.address),
              debtor: toChecksumAddress(data.instantPayment.from.address),
              description: data.instantPayment.description,
          };
};

export const getUserEventLogs = async (
    provider: providers.Provider,
    networkConfig: NetworkConfig,
    queryAddress: EthAddress,
): Promise<BullaItemEvent[]> => {
    const allEventLogs = networkConfig.connections.graphEndpoint
        ? await getEventLogsViaGraph(
              networkConfig.connections.graphEndpoint,
              queryAddress,
              !!networkConfig.bullaFinanceAddress,
              !!networkConfig.frendlendAddress,
              !!networkConfig.factoringConfig,
              !!networkConfig.bullaSwap,
          )
        : await getEventLogsViaEthGetLogs(networkConfig, queryAddress, provider);

    const humaEventLogs = networkConfig.humaConfig?.humagraphEndpoint
        ? await getHumaEventLogsViaGraph(networkConfig.humaConfig.humagraphEndpoint, queryAddress, networkConfig.humaConfig!.poolAddress)
        : [];

    return filterDuplicateEventLogs([...allEventLogs, ...humaEventLogs]);
};

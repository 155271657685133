[
    {
      "inputs": [],
      "stateMutability": "nonpayable",
      "type": "constructor"
    },
    {
      "inputs": [],
      "name": "AlreadyInitialized",
      "type": "error"
    },
    {
      "inputs": [],
      "name": "InvalidOrder",
      "type": "error"
    },
    {
      "inputs": [],
      "name": "InvalidSigner",
      "type": "error"
    },
    {
      "inputs": [],
      "name": "NewOwnerIsZeroAddress",
      "type": "error"
    },
    {
      "inputs": [],
      "name": "NoHandoverRequest",
      "type": "error"
    },
    {
      "inputs": [],
      "name": "OrderExpired",
      "type": "error"
    },
    {
      "inputs": [],
      "name": "SignerAllowanceLow",
      "type": "error"
    },
    {
      "inputs": [],
      "name": "SignerBalanceLow",
      "type": "error"
    },
    {
      "inputs": [],
      "name": "Unauthorized",
      "type": "error"
    },
    {
      "anonymous": false,
      "inputs": [
        {
          "indexed": true,
          "internalType": "uint256",
          "name": "orderId",
          "type": "uint256"
        },
        {
          "indexed": true,
          "internalType": "address",
          "name": "sender",
          "type": "address"
        },
        {
          "indexed": true,
          "internalType": "address",
          "name": "signerWallet",
          "type": "address"
        },
        {
          "components": [
            {
              "internalType": "uint256",
              "name": "orderId",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "expiry",
              "type": "uint256"
            },
            {
              "internalType": "address",
              "name": "signerWallet",
              "type": "address"
            },
            {
              "internalType": "address",
              "name": "signerToken",
              "type": "address"
            },
            {
              "internalType": "uint256",
              "name": "signerAmount",
              "type": "uint256"
            },
            {
              "internalType": "address",
              "name": "senderWallet",
              "type": "address"
            },
            {
              "internalType": "address",
              "name": "senderToken",
              "type": "address"
            },
            {
              "internalType": "uint256",
              "name": "senderAmount",
              "type": "uint256"
            }
          ],
          "indexed": false,
          "internalType": "struct IBullaSwap.OrderERC20",
          "name": "order",
          "type": "tuple"
        }
      ],
      "name": "OrderCreated",
      "type": "event"
    },
    {
      "anonymous": false,
      "inputs": [
        {
          "indexed": true,
          "internalType": "uint256",
          "name": "orderId",
          "type": "uint256"
        },
        {
          "indexed": true,
          "internalType": "address",
          "name": "signerWallet",
          "type": "address"
        },
        {
          "components": [
            {
              "internalType": "uint256",
              "name": "orderId",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "expiry",
              "type": "uint256"
            },
            {
              "internalType": "address",
              "name": "signerWallet",
              "type": "address"
            },
            {
              "internalType": "address",
              "name": "signerToken",
              "type": "address"
            },
            {
              "internalType": "uint256",
              "name": "signerAmount",
              "type": "uint256"
            },
            {
              "internalType": "address",
              "name": "senderWallet",
              "type": "address"
            },
            {
              "internalType": "address",
              "name": "senderToken",
              "type": "address"
            },
            {
              "internalType": "uint256",
              "name": "senderAmount",
              "type": "uint256"
            }
          ],
          "indexed": false,
          "internalType": "struct IBullaSwap.OrderERC20",
          "name": "order",
          "type": "tuple"
        }
      ],
      "name": "OrderDeleted",
      "type": "event"
    },
    {
      "anonymous": false,
      "inputs": [
        {
          "indexed": true,
          "internalType": "uint256",
          "name": "orderId",
          "type": "uint256"
        },
        {
          "indexed": true,
          "internalType": "address",
          "name": "sender",
          "type": "address"
        },
        {
          "indexed": true,
          "internalType": "address",
          "name": "recipient",
          "type": "address"
        },
        {
          "components": [
            {
              "internalType": "uint256",
              "name": "orderId",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "expiry",
              "type": "uint256"
            },
            {
              "internalType": "address",
              "name": "signerWallet",
              "type": "address"
            },
            {
              "internalType": "address",
              "name": "signerToken",
              "type": "address"
            },
            {
              "internalType": "uint256",
              "name": "signerAmount",
              "type": "uint256"
            },
            {
              "internalType": "address",
              "name": "senderWallet",
              "type": "address"
            },
            {
              "internalType": "address",
              "name": "senderToken",
              "type": "address"
            },
            {
              "internalType": "uint256",
              "name": "senderAmount",
              "type": "uint256"
            }
          ],
          "indexed": false,
          "internalType": "struct IBullaSwap.OrderERC20",
          "name": "order",
          "type": "tuple"
        }
      ],
      "name": "OrderExecuted",
      "type": "event"
    },
    {
      "anonymous": false,
      "inputs": [
        {
          "indexed": true,
          "internalType": "address",
          "name": "pendingOwner",
          "type": "address"
        }
      ],
      "name": "OwnershipHandoverCanceled",
      "type": "event"
    },
    {
      "anonymous": false,
      "inputs": [
        {
          "indexed": true,
          "internalType": "address",
          "name": "pendingOwner",
          "type": "address"
        }
      ],
      "name": "OwnershipHandoverRequested",
      "type": "event"
    },
    {
      "anonymous": false,
      "inputs": [
        {
          "indexed": true,
          "internalType": "address",
          "name": "oldOwner",
          "type": "address"
        },
        {
          "indexed": true,
          "internalType": "address",
          "name": "newOwner",
          "type": "address"
        }
      ],
      "name": "OwnershipTransferred",
      "type": "event"
    },
    {
      "inputs": [],
      "name": "cancelOwnershipHandover",
      "outputs": [],
      "stateMutability": "payable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "orderId",
          "type": "uint256"
        }
      ],
      "name": "check",
      "outputs": [
        {
          "internalType": "bytes32[]",
          "name": "",
          "type": "bytes32[]"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "pendingOwner",
          "type": "address"
        }
      ],
      "name": "completeOwnershipHandover",
      "outputs": [],
      "stateMutability": "payable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "components": [
            {
              "internalType": "uint256",
              "name": "expiry",
              "type": "uint256"
            },
            {
              "internalType": "address",
              "name": "signerWallet",
              "type": "address"
            },
            {
              "internalType": "address",
              "name": "signerToken",
              "type": "address"
            },
            {
              "internalType": "uint256",
              "name": "signerAmount",
              "type": "uint256"
            },
            {
              "internalType": "address",
              "name": "senderWallet",
              "type": "address"
            },
            {
              "internalType": "address",
              "name": "senderToken",
              "type": "address"
            },
            {
              "internalType": "uint256",
              "name": "senderAmount",
              "type": "uint256"
            }
          ],
          "internalType": "struct IBullaSwap.CreateOrderParams",
          "name": "params",
          "type": "tuple"
        }
      ],
      "name": "createOrder",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "orderId",
          "type": "uint256"
        }
      ],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "orderId",
          "type": "uint256"
        }
      ],
      "name": "deleteOrder",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "orderId",
          "type": "uint256"
        }
      ],
      "name": "executeOrder",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "orderCount",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "owner",
      "outputs": [
        {
          "internalType": "address",
          "name": "result",
          "type": "address"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "pendingOwner",
          "type": "address"
        }
      ],
      "name": "ownershipHandoverExpiresAt",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "result",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "name": "pendingOrders",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "orderId",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "expiry",
          "type": "uint256"
        },
        {
          "internalType": "address",
          "name": "signerWallet",
          "type": "address"
        },
        {
          "internalType": "address",
          "name": "signerToken",
          "type": "address"
        },
        {
          "internalType": "uint256",
          "name": "signerAmount",
          "type": "uint256"
        },
        {
          "internalType": "address",
          "name": "senderWallet",
          "type": "address"
        },
        {
          "internalType": "address",
          "name": "senderToken",
          "type": "address"
        },
        {
          "internalType": "uint256",
          "name": "senderAmount",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "renounceOwnership",
      "outputs": [],
      "stateMutability": "payable",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "requestOwnershipHandover",
      "outputs": [],
      "stateMutability": "payable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "newOwner",
          "type": "address"
        }
      ],
      "name": "transferOwnership",
      "outputs": [],
      "stateMutability": "payable",
      "type": "function"
    }
  ]
import React from 'react';
import { allNetworks, ChainId, TokenInfo } from '../data-lib/networks';
import { useHistoricalPrices } from './useHistoricalPrices';

export type TokenSafetyContext = {
    isTokenSafe: (chainId: ChainId, _tokenAddress: string) => boolean;
    isTokenInfoSafe: (tokenInfo: TokenInfo) => boolean;
};

const TokenSafetyContext = React.createContext<TokenSafetyContext>({ isTokenSafe: (x, y) => true, isTokenInfoSafe: _ => true });

export const TokenSafetyProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const { USDMark } = useHistoricalPrices();
    const pricedTokens = React.useMemo(
        () =>
            Object.entries(USDMark).reduce<Set<string>>((acc, [key, price]) => {
                const number = Number(price);
                const keySeperated = key.split('_');
                const keyWithoutTimestamp = `${keySeperated[0]}_${keySeperated[1]}`;
                return isNaN(number) ? acc : acc.add(keyWithoutTimestamp);
            }, new Set()),
        [USDMark],
    );

    const knownTokens = React.useMemo(
        () =>
            allNetworks
                .flatMap(x => [
                    x.nativeCurrency.tokenInfo,
                    ...Object.values(x.supportedTokens),
                    ...(x.factoringConfig?.flatMap(config => config.bullaFactoringToken) ?? []),
                ])
                .filter((x): x is TokenInfo => x !== undefined)
                .reduce<Set<string>>(
                    (acc, tokenInfo) => acc.add(`${tokenInfo.chainId}_${tokenInfo.token.address.toLocaleLowerCase()}`),
                    new Set(),
                ),
        [],
    );

    const isTokenSafe: TokenSafetyContext['isTokenSafe'] = React.useCallback(
        (chainId, tokenAddress) => {
            const key = `${chainId}_${tokenAddress.toLowerCase()}`;
            return knownTokens.has(key) || pricedTokens.has(key);
        },
        [pricedTokens.size],
    );

    const isTokenInfoSafe: TokenSafetyContext['isTokenInfoSafe'] = React.useCallback(
        token => isTokenSafe(token.chainId, token.token.address),
        [pricedTokens.size],
    );

    const context = React.useMemo(
        () => ({
            isTokenSafe,
            isTokenInfoSafe,
        }),
        [isTokenSafe, isTokenInfoSafe],
    );

    return <TokenSafetyContext.Provider value={context}>{children}</TokenSafetyContext.Provider>;
};

export const useTokenSafety = () => {
    const context = React.useContext(TokenSafetyContext);
    if (!context) throw new Error('Error: you must call useTokenSafety with the TokenSafetyProvider');
    return context;
};

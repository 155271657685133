import { Flex } from '@chakra-ui/react';
import React, { useRef } from 'react';
import { BullaSwapInfoWithUSDMark } from '../../../data-lib/data-model';
import { useIsMobile } from '../../../hooks/useIsMobile';
import { useGlobalUserData } from '../../../hooks/useUserData';
import {
    shadowProps,
    ListViewCard,
    AMOUNT_COLUMN_WIDTH,
    DATE_COLUMN_WIDTH,
    FROM_TO_COLUMN_WIDTH,
    ListViewCardProps,
    VIEW_COLUMN_WIDTH,
    TOKEN_COLUMN_WIDTH,
    CHAIN_COLUMN_WIDTH,
    STATUS_COLUMN_WIDTH,
} from '../../layout/cards';
import { ResponsiveStack } from '../responsive-stack';
import { ClearFilterPillsStack } from './filters/common';
import { SwapFilter, swapFilterToPills, emptySwapFilterValues, getInitialSwapFilterValues, SwapFilterValues } from './filters/swap-filter';
import { ListItemProps } from '../../layout/cards';
import { TokenAmount } from '../../currency/token-display-amount';
import { toDateDisplay, toUSD } from '../../../tools/common';
import { FromAndToWallet } from '../../base/address-label';
import { ChainSymbol } from '../../chain-symbol';
import { ViewDetailsButton } from '../../inputs/buttons';
import { addressEquality, weiToDisplayAmt } from '../../../data-lib/ethereum';
import { useActingWalletAddress } from '../../../hooks/useWalletAddress';

const SWAP_HEADERS: ListViewCardProps['headers'] = [
    { label: 'from / to', relativeColumnWidth: FROM_TO_COLUMN_WIDTH },
    { label: 'date', relativeColumnWidth: DATE_COLUMN_WIDTH },
    { label: 'chain', relativeColumnWidth: CHAIN_COLUMN_WIDTH },
    { label: 'status', relativeColumnWidth: STATUS_COLUMN_WIDTH },
    { label: 'Amount In', relativeColumnWidth: TOKEN_COLUMN_WIDTH },
    { label: 'USD Mark in', relativeColumnWidth: TOKEN_COLUMN_WIDTH },
    { label: 'Amount Out', relativeColumnWidth: AMOUNT_COLUMN_WIDTH },
    { label: 'USD Mark out', relativeColumnWidth: AMOUNT_COLUMN_WIDTH },
    { label: '', relativeColumnWidth: VIEW_COLUMN_WIDTH },
];
const MOBILE_HEADERS = ['date', 'amountIn', 'amountOut'];

export type SwapsTableItem = BullaSwapInfoWithUSDMark & {
    viewSwap: VoidFunction;
};

export const mapBullaSwapToSwapsTableItem = (swaps: BullaSwapInfoWithUSDMark[]): SwapsTableItem[] =>
    swaps.map(item => {
        const viewSwap = () => null;

        return {
            ...item,
            viewSwap,
        };
    });

export const SwapsTable: React.FC = () => {
    const { swapsWithUSDMark } = useGlobalUserData('exclude-originating-claims');
    const [filters, setFilters] = React.useState<SwapFilterValues>(emptySwapFilterValues);
    const listViewCardRef = useRef<HTMLDivElement>(null);
    const isMobile = useIsMobile();
    const actingWallet = useActingWalletAddress();

    const lineItems: SwapsTableItem[] = mapBullaSwapToSwapsTableItem(swapsWithUSDMark);

    const displayedListItems = lineItems.map((item): ListItemProps => {
        const {
            signerWallet,
            signerAmount,
            signerToken,
            senderWallet,
            senderAmount,
            senderToken,
            chainId,
            created,
            status,
            USDMarkSignerToken,
            USDMarkSenderToken,
        } = item;

        const signerAmountDisplay = <TokenAmount amount={signerAmount} tokenInfo={signerToken} />;
        const signerAmountDisplayNumber = weiToDisplayAmt({ amountWei: signerAmount, token: signerToken.token });

        const senderAmountDisplay = <TokenAmount amount={senderAmount} tokenInfo={senderToken} />;
        const senderAmountDisplayNumber = weiToDisplayAmt({ amountWei: senderAmount, token: senderToken.token });

        const createdDate = toDateDisplay(created);

        return {
            columnValues: isMobile
                ? [createdDate, signerAmountDisplay, senderAmountDisplay]
                : [
                      <FromAndToWallet
                          chainId={chainId}
                          from={addressEquality(signerWallet, actingWallet) ? senderWallet : signerWallet}
                          to={actingWallet}
                      />,
                      createdDate,
                      <ChainSymbol chainId={chainId} />,
                      status,
                      signerAmountDisplay,
                      <Flex>
                          {USDMarkSignerToken == 'fetching'
                              ? 'Loading'
                              : USDMarkSignerToken == 'not-found'
                              ? 'Not Found'
                              : toUSD(signerAmountDisplayNumber * USDMarkSignerToken)}
                      </Flex>,
                      senderAmountDisplay,
                      <Flex>
                          {USDMarkSenderToken == 'fetching'
                              ? 'Loading'
                              : USDMarkSenderToken == 'not-found'
                              ? 'Not Found'
                              : toUSD(senderAmountDisplayNumber * USDMarkSenderToken)}
                      </Flex>,
                      <ViewDetailsButton onClick={() => null} />,
                  ],
        };
    });

    return (
        <>
            <ResponsiveStack align="flex-start">
                <SwapFilter filters={filters} setFilters={setFilters} />
            </ResponsiveStack>
            <ClearFilterPillsStack
                pb="4"
                clearAll={() => setFilters(getInitialSwapFilterValues())}
                filters={filters}
                filtersToPills={swapFilterToPills(setFilters)}
            />
            <Flex {...shadowProps} direction={'column'} flex="1" overflowX="auto" overflow={'visible'} ref={listViewCardRef}>
                <ListViewCard
                    headers={isMobile ? MOBILE_HEADERS : SWAP_HEADERS}
                    displayedListItems={displayedListItems}
                    bordered={false}
                    totalItemCount={displayedListItems.length}
                    emptyMessage="No swaps to display"
                />
            </Flex>
        </>
    );
};

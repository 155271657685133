export const ledgerExportTypes = [
    'CashDisbursements',
    'AccountsReceivables',
    'AccountsPayables',
    'LoanReceivables',
    'LoanPayables',
    'Transfers',
] as const;

export type LedgerExportType = typeof ledgerExportTypes[number];

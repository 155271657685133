import { WarningIcon } from '@chakra-ui/icons';
import { Spacer, Flex, Thead, Tr, Th, Td, Center, Tbody, Text, Table } from '@chakra-ui/react';
import { formatUnits } from 'ethers/lib/utils';
import { Spinner } from 'phosphor-react';
import React from 'react';
import { BullaSwapInfo } from '../../../data-lib/data-model';

export const PastSwapsTable = ({ swaps, readyToTransact }: { swaps: BullaSwapInfo[]; readyToTransact: boolean }) => {
    const noPastTransactionWarning = swaps.length === 0;

    return (
        <>
            <Spacer />
            <Flex border="1px solid #E2E8F0" borderRadius={'8px'} flexDir="column" p="5" boxShadow="md" w="100%">
                <Text fontSize={'16px'} fontWeight="700">
                    Past Swaps with this address
                </Text>
                <Table variant="simple">
                    <Thead>
                        <Tr>
                            <Th fontSize={'12px'}>Date</Th>
                            <Th fontSize={'12px'}>Amount</Th>
                        </Tr>
                    </Thead>
                    {!readyToTransact ? (
                        <Tr>
                            <Td colSpan={3}>
                                <Flex align={'center'} justifyContent="center" height="80px">
                                    <Center>
                                        <Spinner />
                                    </Center>
                                </Flex>
                            </Td>
                        </Tr>
                    ) : !noPastTransactionWarning ? (
                        <Tbody>
                            {swaps.map((swap, index) => (
                                <Tr key={index}>
                                    <Td>{new Date(swap.created).toLocaleDateString()}</Td>
                                    <Td>
                                        {`${formatUnits(swap.signerAmount, swap.signerToken.token.decimals)} ${
                                            swap.signerToken.token.symbol
                                        } for ${formatUnits(swap.senderAmount, swap.senderToken.token.decimals)} ${
                                            swap.senderToken.token.symbol
                                        }`}
                                    </Td>
                                </Tr>
                            ))}
                        </Tbody>
                    ) : (
                        <Tr>
                            <Td colSpan={3}>
                                <Flex align={'center'} justifyContent="center" height="80px">
                                    <Center color="red.500">
                                        <WarningIcon mr="2" /> No swaps made with this address
                                    </Center>
                                </Flex>
                            </Td>
                        </Tr>
                    )}
                </Table>
            </Flex>
        </>
    );
};

import { Flex, HStack, Spacer, Text } from '@chakra-ui/react';
import React, { useRef } from 'react';
import { WarningIconWithTooltip } from '../../../assets/warning';
import { BullaItemInfo, FinancedClaimInfo, lastPaymentDate } from '../../../data-lib/data-model';
import { EthAddress } from '../../../data-lib/ethereum';
import { getItemRelationToUser } from '../../../data-lib/helpers';
import { useOpenBullaItem } from '../../../hooks/useClaimDetailDisclosure';
import { useIsMobile } from '../../../hooks/useIsMobile';
import { usePagination } from '../../../hooks/usePagination';
import { BullaItemInfoWithPayment } from '../../../hooks/useUserData';
import { useActingWalletAddress } from '../../../hooks/useWalletAddress';
import { toDateDisplay } from '../../../tools/common';
import { FromAndToWallet } from '../../base/address-label';
import { getStatusBadge } from '../../base/status-badge';
import { ChainSymbol } from '../../chain-symbol';
import { TokenAmount } from '../../currency/token-display-amount';
import { ViewDetailsButton } from '../../inputs/buttons';
import {
    shadowProps,
    ListViewCard,
    AMOUNT_COLUMN_WIDTH,
    CHAIN_COLUMN_WIDTH,
    DATE_COLUMN_WIDTH,
    FROM_TO_COLUMN_WIDTH,
    ListViewCardProps,
    STATUS_COLUMN_WIDTH,
    VIEW_COLUMN_WIDTH,
    TwoLineTextWithLabel,
    ListItemProps,
} from '../../layout/cards';
import { PageSelector, TableItem } from '../claim-table';
import { ResponsiveStack } from '../responsive-stack';
import { emptyFilterValues } from './account-tag-view';
import { buildItemInfoFilters, PaymentFilter, paymentFilterToPills, PaymentFilterValues } from './filters/claim-filter';
import { ClearFilterPillsStack, TableFilter } from './filters/common';

const getInitialLoanFilterValues = (initialSelectedWallet?: string): PaymentFilterValues => ({
    search: '',
    date: { startDate: undefined, endDate: undefined },
    type: undefined,
    priceRange: undefined,
    direction: 'In And Out',
    selectedWallets: new Set(initialSelectedWallet ? [initialSelectedWallet] : []),
    selectedNetworks: new Set(),
    selectedTokenSymbols: new Set(),
    category: undefined,
});

const LOAN_HEADERS: ListViewCardProps['headers'] = [
    { label: 'debtor / creditor', relativeColumnWidth: FROM_TO_COLUMN_WIDTH },
    { label: 'description' },
    { label: 'chain', relativeColumnWidth: CHAIN_COLUMN_WIDTH },
    { label: 'status', relativeColumnWidth: STATUS_COLUMN_WIDTH },
    { label: 'created date', relativeColumnWidth: DATE_COLUMN_WIDTH },
    { label: 'due date', relativeColumnWidth: DATE_COLUMN_WIDTH },
    { label: 'principal', relativeColumnWidth: AMOUNT_COLUMN_WIDTH },
    { label: 'fee', relativeColumnWidth: AMOUNT_COLUMN_WIDTH },
    { label: 'amount paid', relativeColumnWidth: AMOUNT_COLUMN_WIDTH },
    { label: '', relativeColumnWidth: VIEW_COLUMN_WIDTH },
];
const MOBILE_HEADERS = ['date', 'amountPaid'];

export const mapFinancedClaimInfoToLoanTableItem = (
    items: FinancedClaimInfo[],
    userAddress: EthAddress,
    openItem: (item: BullaItemInfo) => void,
): TableItem[] =>
    items.map(item => {
        const onClick = () => openItem(item);
        const { direction } = getItemRelationToUser(userAddress, item);
        const isPending = item.claimStatus === 'Repaying';
        const isOverdue = item.dueBy.getTime() < new Date().getTime() && direction == 'Out' && isPending;
        const displayAmount = item.paidAmount;

        return {
            ...item,
            itemStatus: item.claimStatus,
            onClick,
            direction,
            isPending,
            isOverdue,
            displayAmount,
            displayDate: item.dueBy,
            financingClaimState: item.financingState,
        };
    });

export type LoanTableItem = BullaItemInfoWithPayment & {
    onClick: VoidFunction;
};

interface LoansTableProps {
    loans: FinancedClaimInfo[];
}

export const LoanListTemplate: React.FC<LoansTableProps> = ({ loans }) => {
    const [filters, setFilters] = React.useState<PaymentFilterValues>(emptyFilterValues);
    const [claimFilters, setClaimFilters] = React.useState<TableFilter<BullaItemInfo>[] | []>([]);
    const actingWallet = useActingWalletAddress();
    React.useEffect(() => setClaimFilters(buildItemInfoFilters(actingWallet)(filters)), [filters]);
    const userAddress = useActingWalletAddress();
    const openItem = useOpenBullaItem();
    const lineItems: TableItem[] = mapFinancedClaimInfoToLoanTableItem(loans, userAddress, openItem);
    const isMobile = useIsMobile();
    const listViewCardRef = useRef<HTMLDivElement>(null);

    const filterItems = (items: TableItem[]) => [...claimFilters].reduce((claims, filterFunc) => claims.filter(filterFunc), [...items]);
    const filteredItems = filterItems(lineItems);

    const pageSelectorProps = usePagination(filteredItems);
    const visibleLoans = pageSelectorProps.shownItems;

    const stringifiedLoans = JSON.stringify(visibleLoans);

    const displayedListItems = visibleLoans.map((item): ListItemProps => {
        const {
            debtor,
            creditor,
            description,
            tokenInfo,
            direction,
            isOverdue,
            onClick,
            chainId,
            displayDate,
            created,
            financingClaimState,
        } = item;

        const paidPaymentDate = toDateDisplay(lastPaymentDate(item));
        const dueDate = <Text textStyle="cell">{toDateDisplay(displayDate)}</Text>;
        const createdDate = <Text textStyle="cell">{toDateDisplay(created)}</Text>;

        const principal = (
            <HStack textStyle="cell">
                <TokenAmount amount={financingClaimState!.terms.principalAmount!} tokenInfo={tokenInfo} />
            </HStack>
        );
        const fee = (
            <HStack textStyle="cell">
                <TokenAmount amount={financingClaimState!.terms.interestValue!} tokenInfo={tokenInfo} />
            </HStack>
        );
        const paidAmount = (
            <HStack textStyle="cell">
                <TokenAmount amount={item.displayAmount} tokenInfo={tokenInfo} />
            </HStack>
        );

        return {
            columnValues: isMobile
                ? [dueDate, paidAmount]
                : [
                      <FromAndToWallet chainId={chainId} from={debtor} to={creditor} />,
                      <TwoLineTextWithLabel>{description}</TwoLineTextWithLabel>,
                      <ChainSymbol chainId={chainId} />,
                      <>
                          {getStatusBadge(item.itemStatus, direction, paidPaymentDate)}
                          {isOverdue && <WarningIconWithTooltip label="Payment Overdue" placement="top" warningOverrides={{ mx: '1' }} />}
                      </>,
                      createdDate,
                      dueDate,
                      principal,
                      fee,
                      paidAmount,
                      <ViewDetailsButton onClick={onClick} />,
                  ],
            selectId: item.id,
        };
    });

    const mainSection = React.useMemo(
        () => (
            <>
                <ResponsiveStack align="flex-start">
                    <PaymentFilter
                        filters={filters}
                        setFilters={setFilters}
                        searchPlaceholder={'loans'}
                        hideDirectionFilter
                        hideCategoryFilter
                    />
                    <Spacer />
                </ResponsiveStack>
                <ClearFilterPillsStack
                    pb="4"
                    clearAll={() => setFilters(getInitialLoanFilterValues())}
                    filters={filters}
                    filtersToPills={paymentFilterToPills(setFilters)}
                />
                <Flex {...shadowProps} direction={'column'} flex="1" overflowX="auto" overflow={'visible'} ref={listViewCardRef}>
                    <ListViewCard
                        headers={isMobile ? MOBILE_HEADERS : LOAN_HEADERS}
                        displayedListItems={displayedListItems}
                        bordered={false}
                        totalItemCount={displayedListItems.length}
                        emptyMessage="No loans to display"
                    />
                </Flex>
                <PageSelector {...pageSelectorProps} justifySelf="center" pt="6" />
            </>
        ),
        [stringifiedLoans, isMobile, filters],
    );

    return mainSection;
};

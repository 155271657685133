import { BigNumber } from 'ethers';
import { EventLogs } from '../data-lib/dto/events-dto';
import { MappedEventType } from '../data-lib/dto/mapped-event-types';
import { EthAddress } from '../data-lib/ethereum';
import { Membership } from '../state/auth-state';
import { PremiumTier, tryParseJson } from './common';
import { setLocalStorage, getLocalStorage } from './localStorage';

export type LogsCache = {
    eventLogs: EventLogs;
    latestLogBlock: number;
};

export const STORAGE_KEYS = {
    logsCache: 'logsCache',
    userRejectedSafePrompt: 'user-rejected-connect-safe-prompt',
    selectedWallet: 'onboard-selectedWallet',
    usedSafes: 'usedSafes',
    capturedParams: 'capturedParams',
    safeInfo: 'safeInfo',
    selfIdEnabled: 'selfIdEnabled',
    tempAccountTags: 'temp-account-tags',
    batchDrafts: 'batch-drafts',
    companyDetails: 'company-details',
    showGettingStarted: 'show-getting-started',
    enableFinancing: 'enable-financing',
    externalTxs: 'external-txs',
    seenBatchPricingModal: 'seen-batch-pricing-modal',
    namingServiceCache: 'namingServiceCache',
    tokenInfoCache: 'tokenInfoCache',
    welcomePremium: 'welcome-premium',
};

export type ActiveMembershipInfo = {
    id: string;
    exp: number;
    tier: PremiumTier;
};

export type BullaJWTSchema = {
    exp: number;
    iss: string;
    wallet: EthAddress;
    membership: Membership | null | undefined;
    memberships: ActiveMembershipInfo[] | undefined;
};

export const COOKIE_KEYS = {
    auth: 'bulla-jwt',
};

export const setSessionStorage = (key: string, value: any) => sessionStorage.setItem(key, JSON.stringify(value));

export const clearSessionStorage = (key?: string) => (key ? sessionStorage.setItem(key, 'undefined') : sessionStorage.clear());

export const getSessionStorage = <T>(key: string): T | undefined => {
    const value = sessionStorage.getItem(key);
    return value ? tryParseJson(value) : value || undefined;
};

const rehydrateObjects = (key: string, value: any) => {
    switch (key) {
        case 'blocktime':
        case 'dueBy':
            return new Date(value);
        case 'paymentAmount':
        case 'transactionFee':
        case 'claimAmount':
            return BigNumber.from(value.hex);
        default:
            return value;
    }
};

export const cacheLogs = (chainId: number, userAddress: string, eventLogs: EventLogs, latestLogBlock: number) =>
    setLocalStorage(`${chainId}:${userAddress}:${STORAGE_KEYS.logsCache}`, { eventLogs, latestLogBlock });

/**
 * @notice
 *  object instances are not preserved when json stringify is used,
 *  we have re-init them when we load the cache.
 */
export const getLogsCache = (chainId: number, userAddress: string): LogsCache | undefined => {
    const cache = getLocalStorage(`${chainId}:${userAddress}:${STORAGE_KEYS.logsCache}`) as LogsCache | undefined;
    if (cache) {
        const hydratedLogs = Object.entries(cache.eventLogs).reduce(
            (parsedLogs, [requestName, logs]) => ({
                ...parsedLogs,
                [requestName]: logs.map(log =>
                    Object.entries(log).reduce<MappedEventType>(
                        (parsedLog, [key, value]) => ({ ...parsedLog, [key]: rehydrateObjects(key, value) }),
                        {} as MappedEventType,
                    ),
                ),
            }),
            {},
        );
        return {
            eventLogs: hydratedLogs,
            latestLogBlock: cache.latestLogBlock,
        };
    }
};

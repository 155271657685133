import { Box, Container, Spinner, VStack, Image } from '@chakra-ui/react';
import { default as React, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { intToDate } from '../../../data-lib/helpers';
import { ChainId, NETWORKS } from '../../../data-lib/networks';
import { useAuthApi } from '../../../hooks/useAuthApi';
import { LedgerExportResponse, useExternalTransactionsApi } from '../../../hooks/useExternalTransactionsApi';
import { useHistoricalPrices } from '../../../hooks/useHistoricalPrices';
import { useMembership } from '../../../hooks/useMembership';
import { useTokenRepo } from '../../../hooks/useTokenRepo';
import { useActingWalletAddress } from '../../../hooks/useWalletAddress';
import { useWeb3 } from '../../../hooks/useWeb3';
import { PageLayoutProvider } from '../../layout/page-layout';
import { BullaCloseButton } from '../common';
import { AddWalletsChainsCard, WalletChainSelection } from '../common-reporting/add-wallets-chains-card';
import { LedgerExportSuccessCard } from './ledger-export-success-card';
import { ReportNameCard } from '../common-reporting/report-name-card';
import FileIcon from 'url:../../../assets/file-icon.svg';
import { Sparkle, Wallet, Clipboard } from 'phosphor-react';
import { ProgressSteps, Step } from '../common-reporting/progress-steps';

export type LedgerReportMetadata = {
    reportId: string;
    createdOn: string;
};

export type SelectNameState = {
    step: 'selectName';
    reportName: string;
} & LedgerReportMetadata;

export type SelectWalletState = {
    step: 'selectWallet';
    walletChainSelection: WalletChainSelection;
} & LedgerReportMetadata;

export type ReportFetchingState = {
    step: 'reportFetching';
    walletChainSelection: WalletChainSelection;
} & LedgerReportMetadata;

export type SuccessState = {
    step: 'exportSuccess';
    walletChainSelection: WalletChainSelection;
    ledgerExportResponse: LedgerExportResponse;
} & LedgerReportMetadata;

export type LedgerWizardState = SelectNameState | SelectWalletState | ReportFetchingState | SuccessState;

const FetchingCard: React.FC<{ state: ReportFetchingState; setState: React.Dispatch<React.SetStateAction<LedgerWizardState>> }> = ({
    state,
    setState,
}) => {
    const { buildLedgerExport } = useExternalTransactionsApi();
    const { resolveTokenInfo } = useTokenRepo();

    useEffect(() => {
        buildLedgerExport(state.walletChainSelection)
            .then(async response => {
                const priceFetchParams = [
                    ...response.claimEntries.map(x => ({
                        chainId: x.chainId as ChainId,
                        tokenAddress: x.token.address,
                    })),
                    ...response.paymentEntries.map(x => ({
                        chainId: x.chainId as ChainId,
                        timestamp: intToDate(x.timestamp),
                        tokenAddress: x.token.address,
                    })),
                ];
                const uniqueTokens = Array.from(
                    new Map(priceFetchParams.map(item => [`${item.chainId}-${item.tokenAddress}`, item])).values(),
                );

                await Promise.all([...uniqueTokens.map(x => resolveTokenInfo(x.chainId, x.tokenAddress))]);
                return response;
            })
            .then(result =>
                setState(oldState => ({
                    ...(oldState as ReportFetchingState),
                    ledgerExportResponse: result,
                    step: 'exportSuccess',
                })),
            );
    }, []);
    return <Spinner />;
};

export type LedgerWizardStep = LedgerWizardState['step'];

const steps: Step<LedgerWizardStep>[] = [
    { icon: Clipboard, label: 'Start Tax Report', step: 'selectName' },
    { icon: Wallet, label: 'Add Wallets', step: 'selectWallet' },
    { icon: Sparkle, label: 'Build report', step: 'reportFetching' },
];

const mapStepToProgress = (step: LedgerWizardStep): number => {
    switch (step) {
        case 'selectName':
            return 0;
        case 'selectWallet':
            return 50;
        case 'reportFetching':
            return 100;
        default:
            return 0;
    }
};

export const LedgerWizard = () => {
    const navigate = useNavigate();
    const onClose = () => navigate('/reporting');
    const actingWallet = useActingWalletAddress();
    const initialReportId = crypto.randomUUID();
    const [reportName, setReportName] = useState('');
    const { connectedNetwork } = useWeb3();
    const { getFullMembershipInfo } = useAuthApi();
    const membership = useMembership();

    const [selectableWallets, setSelectableWallets] = useState<Set<string>>(new Set());

    const membershipIds = membership?.membershipIds ?? [];

    useEffect(() => {
        if (membership == null) return;
        if (membership.isFreeTrial || membershipIds.length == 0) {
            setSelectableWallets(new Set([actingWallet.toLowerCase()]));
            return;
        }

        Promise.all(membershipIds.map(getFullMembershipInfo)).then(x => {
            const selectableWallets = new Set(
                x.flatMap(dto => (dto ? [dto.mainAddress, ...dto.additionalAddresses] : [])).map(x => x.toLowerCase()),
            );
            return setSelectableWallets(selectableWallets);
        });
    }, [membershipIds, membership]);

    const initialReportCreationState: SelectNameState = {
        step: 'selectName',
        reportName: '',
        reportId: initialReportId,
        createdOn: new Date().toLocaleDateString(),
    };

    const [wizardState, setWizardState] = useState<LedgerWizardState>(initialReportCreationState);

    const addWalletCard = wizardState.step === 'selectWallet' && (
        <AddWalletsChainsCard
            selectableWallets={selectableWallets}
            onContinue={walletChainSelection =>
                setWizardState({
                    ...(wizardState as LedgerReportMetadata),
                    step: 'reportFetching',
                    walletChainSelection,
                } as ReportFetchingState)
            }
            onCancel={() =>
                setWizardState(
                    prev =>
                        ({
                            ...prev,
                            step: 'selectName',
                        } as SelectNameState),
                )
            }
        />
    );

    const loadingCard = wizardState.step == 'reportFetching' && <FetchingCard state={wizardState} setState={setWizardState} />;

    const startCard = wizardState.step === 'selectName' && (
        <ReportNameCard
            image={<Image src={FileIcon} />}
            explanation="Export CSVs for receivables, payables, and cash disbursements for import into your general ledger."
            welcomeMessage="Welcome to Ledger Export"
            reportName={reportName}
            setReportName={setReportName}
            onStartExport={() =>
                setWizardState({
                    ...(wizardState as LedgerReportMetadata),
                    step: 'selectWallet',
                    reportName: reportName,
                    walletChainSelection: {
                        addresses: new Set([actingWallet.toLowerCase()]),
                        chainIds: [NETWORKS[connectedNetwork].chainId],
                    },
                } as SelectWalletState)
            }
        />
    );

    const ledgerExportCard = wizardState.step === 'exportSuccess' && <LedgerExportSuccessCard wizardState={wizardState} />;

    return (
        <PageLayoutProvider>
            {wizardState.step === 'exportSuccess' ? (
                ledgerExportCard
            ) : (
                <Box h="100vh" display={'flex'} flexDir="column" flex="1 1 auto" overflowY="scroll" p="12">
                    <Box display="flex" justifyContent="flex-end" width="100%">
                        <BullaCloseButton onClose={onClose} />
                    </Box>
                    <Container maxW="xl" p="0">
                        <VStack spacing={8} align="center">
                            {startCard}
                            {addWalletCard}
                            {loadingCard}
                            <ProgressSteps mapStepToProgress={mapStepToProgress} steps={steps} currentStep={wizardState.step} />
                        </VStack>
                    </Container>
                </Box>
            )}
        </PageLayoutProvider>
    );
};

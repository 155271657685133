import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import {
    Box,
    Center,
    Flex,
    Heading,
    HStack,
    Image,
    Menu,
    MenuButton,
    Modal,
    ModalCloseButton,
    ModalContent,
    ModalOverlay,
    Text,
    VStack,
} from '@chakra-ui/react';
import React, { useRef, useState } from 'react';
import CheckIcon from '../../assets/check-icon.svg';
import { NetworkDropdown } from '../../components/display/wallet-info';
import { OrangeButton, SecondaryButton } from '../../components/inputs/buttons';
import { ShadowBox } from '../../components/layout/cards';
import { PageLayoutProvider } from '../../components/layout/page-layout';
import { getPriceForPremiumTier, PurchasePremium } from '../../components/modals/premium-pricing-modal';
import { EXTERNAL_TRANSACTIONS_SUPPORTED_NETWORKS, NETWORKS } from '../../data-lib/networks';
import { useCanChangeNetwork } from '../../hooks/useCanChangeNetwork';
import { useOnboard, useWeb3 } from '../../hooks/useWeb3';
import { PremiumTier } from '../../tools/common';
import { enableBullaPro, testingUpgradePath } from '../../tools/featureFlags';

const ListItemWithIcon = ({ text }: { text: string }) => (
    <Flex align="left" mb={4}>
        <Image src={CheckIcon} w={6} />
        <Text ml={2}>{text}</Text>
    </Flex>
);

interface PricingCardProps {
    title: string;
    subtitle?: string;
    price: string;
    additionalText?: string;
    features: string[];
    button: React.ReactNode;
}

export const PricingCard: React.FC<PricingCardProps> = ({ title, subtitle, price, features, button, additionalText }) => {
    return (
        <ShadowBox w={['100%', '80%', '60%', '500px']}>
            <Flex direction="column" justifyContent="space-between" p="4" h="100%">
                <VStack textAlign="center" mb="7">
                    <Heading color="#E66C0A">{title}</Heading>
                    <Heading size="md" color="gray.400" mb="2">
                        {subtitle}
                    </Heading>
                    <Heading size="lg">{price}</Heading>
                </VStack>
                <Text mb="4">{additionalText}</Text>
                {features.map((feature, index) => (
                    <ListItemWithIcon key={index} text={feature} />
                ))}
                <Box h="5" />
                <Box mt="auto">{button}</Box>
            </Flex>
        </ShadowBox>
    );
};

interface UpgradePageProps {
    heading?: string;
    text?: string;
}

export const UpgradePage: React.FC<UpgradePageProps> = ({
    heading = 'Take control of your finances',
    text = 'Decide on one of our pricing options below',
}) => {
    const [purchaseView, setPurchaseView] = useState<PremiumTier | undefined>();
    const canChangeNetwork = useCanChangeNetwork();
    const supportedNetworks = EXTERNAL_TRANSACTIONS_SUPPORTED_NETWORKS;
    const { connectedNetwork } = useWeb3();
    const { changeNetwork } = useOnboard();
    const chains = Object.values(NETWORKS).filter(({ chainId }) => supportedNetworks.includes(chainId as any));

    const onClose = () => {
        setPurchaseView(undefined);
    };

    const button = (tier: PremiumTier) =>
        testingUpgradePath || supportedNetworks.includes(connectedNetwork as any) ? (
            <OrangeButton w="full" onClick={() => setPurchaseView(tier)}>
                Buy Now
            </OrangeButton>
        ) : (
            <Menu>
                {({ isOpen, onClose }) => (
                    <Center>
                        <MenuButton as={OrangeButton} isActive={isOpen} px="4" w="full">
                            <HStack>
                                <Text fontWeight={'600'}>Unsupported Network</Text>
                                {canChangeNetwork && (!isOpen ? <ChevronDownIcon h="6" w="5" /> : <ChevronUpIcon h="6" w="5" />)}
                            </HStack>
                        </MenuButton>
                        {canChangeNetwork && (
                            <NetworkDropdown
                                maxH={'100%'}
                                chains={chains}
                                onSelect={chainId => chainId && changeNetwork(chainId).then(onClose)}
                            />
                        )}
                    </Center>
                )}
            </Menu>
        );

    const modalContentRef = useRef<HTMLDivElement>(null);

    return (
        <PageLayoutProvider>
            <Flex p={['8', '8', '12']} bg={'white'} direction="column" flex="1">
                <Flex direction="column" justifyContent="space-between" alignItems="center">
                    <Flex direction="column">
                        <Flex direction="column" mb="12" mt="4">
                            <Heading color="heading" mb="4">
                                {heading}
                            </Heading>
                            <Text color="gray.600"> {text} </Text>
                        </Flex>
                        <Box display="flex" justifyContent="center" gap="6">
                            <PricingCard
                                title="Bulla Basic"
                                subtitle="2 Wallets"
                                price={`$${getPriceForPremiumTier('basic')} / year`}
                                features={[
                                    'Multichain explorer',
                                    'Onchain/Offchain invoicing',
                                    'Contact management',
                                    'Batch invoicing and payments',
                                    'In-depth reporting',
                                    'Use any ERC-20 token',
                                ]}
                                button={button('basic')}
                            />
                            {enableBullaPro && (
                                <PricingCard
                                    title="Bulla Pro"
                                    subtitle="6 Wallets"
                                    price={`$${getPriceForPremiumTier('pro')} / year`}
                                    features={[
                                        'Everything in Basic plus',
                                        'Support for up to 6 wallets ',
                                        'General ledger export (beta)',
                                        '1099 tax form generator (coming soon)',
                                    ]}
                                    button={button('pro')}
                                />
                            )}
                            <PricingCard
                                title="Bulla Business"
                                price="Contact Us"
                                features={[
                                    'Fiat conversion tracking',
                                    'Crypto payroll processing',
                                    'Customizable crypto reporting',
                                    'Tax Report generation',
                                    'Custom Invoicing',
                                    'General Ledger Support',
                                ]}
                                button={
                                    <SecondaryButton w="full" onClick={() => window.open('https://calendly.com/bullanetwork', '_blank')}>
                                        Schedule a demo
                                    </SecondaryButton>
                                }
                            />
                        </Box>
                    </Flex>
                </Flex>
            </Flex>
            <Modal
                isOpen={purchaseView !== undefined}
                onClose={onClose}
                size="xl"
                isCentered
                closeOnOverlayClick={false}
                closeOnEsc={false}
            >
                <ModalOverlay />
                <ModalContent ref={modalContentRef}>
                    <ModalCloseButton />
                    <PurchasePremium tier={purchaseView ?? 'basic'} onClose={onClose} modalContentRef={modalContentRef} />
                </ModalContent>
            </Modal>
        </PageLayoutProvider>
    );
};

import { ChevronDownIcon } from '@chakra-ui/icons';
import { Box, Button, Checkbox, HStack, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import { isAddress } from 'ethers/lib/utils';
import moment from 'moment';
import React, { SetStateAction } from 'react';
import { useLocation } from 'react-router-dom';
import { BullaItemInfo, ClaimStatus } from '../../../../data-lib/data-model';
import { addressEquality } from '../../../../data-lib/ethereum';
import { ChainId, NETWORKS, TokenInfo } from '../../../../data-lib/networks';
import { isContactsReady, useExtendedContacts } from '../../../../hooks/useExtendedContacts';
import { NftTransfer } from '../../../../hooks/useUserData';
import { useUserSummary } from '../../../../hooks/useUserSummary';
import { InvoiceTableFactoringFilterValues } from '../../../../pages/financing/pool-details-view';
import { useGnosisSafe } from '../../../../state/gnosis-state';
import { toDateDisplay } from '../../../../tools/common';
import { shortAddress } from '../../../base/address-label';
import { RecipientField } from '../../../modals/create-claim-modal/create-claim-inputs';
import { TableItem } from '../../claim-table';
import { ResponsiveStack } from '../../responsive-stack';
import { TokenMultiselect } from '../../token-multiselect';
import { NetworkMultiselect } from '../../view-network-selector';
import { PaymentTableItem } from '../payments';
import {
    ClaimStatusFilter,
    CollectionFilter,
    DateSelect,
    DirectionFilter,
    DirectionFilterValue,
    filterMenuItemFontProps,
    filterMenuProps,
    PillProps,
    SearchFilter,
    TableFilter,
    WalletFilter,
} from './common';

export type PaymentFilterValues = {
    search: string | undefined;
    date: { startDate?: Date; endDate?: Date };
    type: 'Invoice' | 'Payment' | 'All' | undefined;
    priceRange: string | undefined;
    direction: DirectionFilterValue;
    selectedWallets: Set<string>;
    selectedNetworks: Set<ChainId>;
    selectedTokenSymbols: Set<string>;
    category: string | undefined;
    claimStatus?: ClaimStatus;
};

type SetNftFilter = (key: keyof NftFilterValues, value: NftFilterValues[keyof NftFilterValues]) => void;
type SetFilter = (key: keyof PaymentFilterValues, value: PaymentFilterValues[keyof PaymentFilterValues]) => void;
type SetDate = (type: 'startDate' | 'endDate', date: Date | undefined) => void;
export type TableFilters<T> = TableFilter<T>[];
type SetItemFilters<T> = (filters: TableFilters<T>) => void;

export const filterButtonProps = {
    border: '1px solid #D0D5DD',
    backgroundColor: 'white',
    fontWeight: 600,
    fontSize: '14px',
    style: { filter: 'drop-shadow(0px 1px 2px rgba(16, 24, 40, 0.05))' },
    padding: '10px, 16px, 10px, 16px',
};

export const checkDate = (_date: Date, startDate: Date | undefined, endDate: Date | undefined) => {
    const date = moment(_date);
    return (!startDate || date.isAfter(startDate)) && (!endDate || date.isBefore(endDate));
};

export const buildItemInfoFilters =
    <T extends BullaItemInfo>(actingWallet: string) =>
    (filterValues: PaymentFilterValues): TableFilters<T> => {
        const {
            search,
            date: { startDate, endDate },
            selectedWallets,
            selectedNetworks,
            claimStatus,
            direction,
            selectedTokenSymbols,
            category,
        } = filterValues;
        const filterByText = !!search
            ? (item: T) => {
                  if (!search) return true;
                  const filterParameters = [item.tags, item.id, item.creditor, item.debtor, item.paidAmount, item.description, item.notes];
                  return filterParameters.some(value => String(value).toLowerCase().includes(search.trim().toLowerCase()));
              }
            : undefined;

        const filterByDate =
            !startDate && !endDate ? undefined : ({ created: claimCreatedDate }: T) => checkDate(claimCreatedDate, startDate, endDate);

        const filterByWallets =
            selectedWallets.size !== 0
                ? (item: T) => [item.creditor, item.debtor].map(x => x.toLowerCase()).some(x => selectedWallets.has(x))
                : undefined;

        const filterByClaimStatus =
            claimStatus === undefined ? undefined : (item: T) => item.__type !== 'Claim' || item.claimStatus == claimStatus;

        const filterByNetwork = selectedNetworks.size == 0 ? undefined : (item: T) => selectedNetworks.has(item.chainId);

        const filterByCategory = !!category ? ({ tags }: T) => tags.includes(category) : undefined;

        const filterByDirection =
            direction == 'In And Out'
                ? undefined
                : (item: T) =>
                      (direction == 'In' && !addressEquality(item.debtor, actingWallet)) ||
                      (direction == 'Out' && addressEquality(item.debtor, actingWallet));

        const filterByToken =
            selectedTokenSymbols.size == 0 ? undefined : (item: T) => selectedTokenSymbols.has(item.tokenInfo.token.symbol.toUpperCase());

        return [
            filterByText,
            filterByDate,
            filterByWallets,
            filterByNetwork,
            filterByClaimStatus,
            filterByDirection,
            filterByToken,
            filterByCategory,
        ].filter((x): x is TableFilter<T> => x !== undefined);
    };

export const buildPaymentsFilters = (filterValues: PaymentFilterValues): TableFilters<PaymentTableItem> => {
    const {
        search,
        date: { startDate, endDate },
        direction,
        selectedWallets,
        selectedNetworks,
        selectedTokenSymbols,
        category,
    } = filterValues;
    const filterByText = !!search
        ? (payment: PaymentTableItem) => {
              if (!search) return true;
              const filterParameters = [
                  payment.tags,
                  payment.id,
                  payment.creditor,
                  payment.debtor,
                  payment.paidAmount,
                  payment.description,
                  payment.notes,
              ];
              return filterParameters.some(value => String(value).toLowerCase().includes(search.trim().toLowerCase()));
          }
        : undefined;

    const filterByDate =
        !startDate && !endDate ? undefined : ({ paymentTimestamp }: PaymentTableItem) => checkDate(paymentTimestamp, startDate, endDate);

    const filterByDirection =
        direction == 'In And Out'
            ? undefined
            : (payment: PaymentTableItem) =>
                  (direction == 'In' && payment.direction == 'In') || (direction == 'Out' && payment.direction == 'Out');

    const filterByWallets =
        selectedWallets.size !== 0
            ? (item: PaymentTableItem) => [item.creditor, item.debtor].map(x => x.toLowerCase()).some(x => selectedWallets.has(x))
            : undefined;

    const filterByNetwork = selectedNetworks.size == 0 ? undefined : (payment: PaymentTableItem) => selectedNetworks.has(payment.chainId);

    const filterByCategory = !!category ? ({ tags }: PaymentTableItem) => tags.includes(category) : undefined;

    const filterByToken =
        selectedTokenSymbols.size == 0
            ? undefined
            : (payment: PaymentTableItem) => selectedTokenSymbols.has(payment.tokenInfo.token.symbol.toUpperCase());

    return [filterByText, filterByDate, filterByDirection, filterByWallets, filterByNetwork, filterByToken, filterByCategory].filter(
        (x): x is TableFilter<PaymentTableItem> => x !== undefined,
    );
};

export const buildNftFilters = (filterValues: NftFilterValues): TableFilters<NftTransfer> => {
    const {
        date: { startDate, endDate },
        direction,
        recipient,
        selectedNetworks,
        selectedCollections,
    } = filterValues;

    const filterByDate = !startDate && !endDate ? undefined : ({ timestamp }: NftTransfer) => checkDate(timestamp, startDate, endDate);

    const filterByDirection =
        direction == 'In And Out'
            ? undefined
            : (transfer: NftTransfer) =>
                  (direction == 'In' && transfer.direction == 'In') || (direction == 'Out' && transfer.direction == 'Out');

    const filterByRecipient =
        recipient && isAddress(recipient)
            ? (transfer: NftTransfer) => addressEquality(recipient, transfer.from) || addressEquality(recipient, transfer.to)
            : undefined;

    const filterByNetwork = selectedNetworks.size == 0 ? undefined : (transfer: NftTransfer) => selectedNetworks.has(transfer.chainId);

    const filterByCollection =
        selectedCollections.size === 0 ? undefined : (transfer: NftTransfer) => selectedCollections.has(transfer.tokenName);

    return [filterByDate, filterByDirection, filterByRecipient, filterByNetwork, filterByCollection].filter(
        (x): x is TableFilter<NftTransfer> => x !== undefined,
    );
};

type FilterProps = {
    searchPlaceholder: string;
    filters: PaymentFilterValues;
    setFilters: React.Dispatch<SetStateAction<PaymentFilterValues>>;
    hideDirectionFilter?: boolean;
    hideClaimStatusFilter?: boolean;
    hideCategoryFilter?: boolean;
};

type NftFilterProps<T> = {
    filters: NftFilterValues;
    setFilters: React.Dispatch<SetStateAction<NftFilterValues>>;
    hideDirectionFilter?: boolean;
    nftTransfers: NftTransfer[];
};

export const getRecipientFromParam = () => {
    const { search } = useLocation();
    const urlParams = new URLSearchParams(search);
    return urlParams.get('recipient');
};

export const getCategoryFromParam = () => {
    const { search } = useLocation();
    const urlParams = new URLSearchParams(search);
    return urlParams.get('category');
};

export const PaymentFilter = ({
    setFilters,
    searchPlaceholder,
    hideDirectionFilter,
    hideClaimStatusFilter,
    hideCategoryFilter,
    filters,
}: FilterProps) => {
    const { accountTagSummary } = useUserSummary();
    const { safeInfo } = useGnosisSafe();

    const setFilter: SetFilter = (key, value) => setFilters(filters => ({ ...filters, [key]: value }));
    const setDate: SetDate = (type, date) => setFilters(filters => ({ ...filters, date: { ...filters.date, [type]: date } }));
    const resetDates = () => setFilters(filters => ({ ...filters, date: {} }));

    return (
        <Box w="100%" pb="4">
            <ResponsiveStack>
                <SearchFilter value={filters.search} setValue={str => setFilter('search', str)} placeholder={searchPlaceholder} />
                <DateSelect
                    startDate={filters.date.startDate}
                    endDate={filters.date.endDate}
                    setDate={setDate}
                    resetDates={resetDates}
                    paymentTimestamps={[]}
                />
                <WalletFilter
                    selectedWallets={filters.selectedWallets}
                    onClick={address =>
                        setFilter(
                            'selectedWallets',
                            filters.selectedWallets.has(address)
                                ? (() => {
                                      filters.selectedWallets.delete(address);
                                      return filters.selectedWallets;
                                  })()
                                : filters.selectedWallets.add(address),
                        )
                    }
                />
                {!safeInfo && (
                    <HStack>
                        <NetworkMultiselect
                            selectedNetworks={filters.selectedNetworks}
                            toggleNetwork={chainId =>
                                setFilter(
                                    'selectedNetworks',
                                    filters.selectedNetworks.has(chainId)
                                        ? (() => {
                                              filters.selectedNetworks.delete(chainId);
                                              return filters.selectedNetworks;
                                          })()
                                        : filters.selectedNetworks.add(chainId),
                                )
                            }
                        />
                    </HStack>
                )}
                <TokenMultiselect
                    selectedTokenSymbols={filters.selectedTokenSymbols}
                    toggleTokenSymbol={symbol =>
                        setFilter(
                            'selectedTokenSymbols',
                            filters.selectedTokenSymbols.has(symbol)
                                ? (() => {
                                      filters.selectedTokenSymbols.delete(symbol);
                                      return filters.selectedTokenSymbols;
                                  })()
                                : filters.selectedTokenSymbols.add(symbol),
                        )
                    }
                />
                {!hideDirectionFilter && (
                    <DirectionFilter
                        direction={filters.direction}
                        setDirection={direction => setFilters(prev => ({ ...prev, direction }))}
                    />
                )}
                {!hideClaimStatusFilter && (
                    <ClaimStatusFilter
                        status={filters.claimStatus}
                        setStatus={claimStatus => setFilters(prev => ({ ...prev, claimStatus }))}
                    />
                )}
                {!hideCategoryFilter && (
                    <Menu>
                        <MenuButton as={Button} rightIcon={<ChevronDownIcon />} {...filterButtonProps}>
                            {filters.category ?? 'Category'}
                        </MenuButton>
                        <MenuList {...filterMenuProps}>
                            <MenuItem key="all" {...filterMenuItemFontProps} onClick={() => setFilter('category', undefined)}>
                                All categories
                            </MenuItem>
                            {Object.keys(accountTagSummary).map((tag, key) => (
                                <MenuItem key={key} {...filterMenuItemFontProps} onClick={() => setFilter('category', tag)}>
                                    {tag}
                                </MenuItem>
                            ))}
                        </MenuList>
                    </Menu>
                )}
            </ResponsiveStack>
        </Box>
    );
};

export type NftFilterValues = {
    search: string | undefined;
    date: { startDate?: Date; endDate?: Date };
    direction: DirectionFilterValue;
    recipient: string | undefined;
    selectedNetworks: Set<ChainId>;
    claimStatus?: ClaimStatus;
    selectedCollections: Set<string>;
};

export const NftTransferFilter = <T,>({ filters, setFilters, nftTransfers, hideDirectionFilter }: NftFilterProps<T>) => {
    const setFilter: SetNftFilter = (key, value) => setFilters(filters => ({ ...filters, [key]: value }));
    const setDate: SetDate = (type, date) => setFilters(filters => ({ ...filters, date: { ...filters.date, [type]: date } }));
    const resetDates = () => setFilters(filters => ({ ...filters, date: {} }));
    const { safeInfo } = useGnosisSafe();

    return (
        <Box w="100%" pb="4">
            <ResponsiveStack>
                {!safeInfo && (
                    <NetworkMultiselect
                        selectedNetworks={filters.selectedNetworks}
                        toggleNetwork={chainId =>
                            setFilter(
                                'selectedNetworks',
                                filters.selectedNetworks.has(chainId)
                                    ? (() => {
                                          filters.selectedNetworks.delete(chainId);
                                          return filters.selectedNetworks;
                                      })()
                                    : filters.selectedNetworks.add(chainId),
                            )
                        }
                    />
                )}
                {!hideDirectionFilter && (
                    <DirectionFilter
                        direction={filters.direction}
                        setDirection={direction => setFilters(prev => ({ ...prev, direction }))}
                    />
                )}
                <DateSelect
                    startDate={filters.date.startDate}
                    endDate={filters.date.startDate}
                    setDate={setDate}
                    resetDates={resetDates}
                    paymentTimestamps={[]}
                />
                <Box maxW="300px">
                    <RecipientField
                        initialValue={filters.recipient ?? ''}
                        field={{
                            name: '',
                            value: filters.recipient ?? '',
                            onBlur: undefined,
                            onChange: e => setFilter('recipient', e.target.value),
                        }}
                        isDisabled={false}
                        setRecipient={recipient => setFilter('recipient', recipient)}
                    />
                </Box>
                <CollectionFilter selectedCollections={filters.selectedCollections} setFilter={setFilter} nftTransfers={nftTransfers} />
            </ResponsiveStack>
        </Box>
    );
};

export const getInitialNftTransferFilterValues = (): NftFilterValues => ({
    search: '',
    date: { startDate: undefined, endDate: undefined },
    direction: 'In And Out',
    recipient: undefined,
    selectedNetworks: new Set(),
    selectedCollections: new Set(),
});

export const nftTransferFilterToPills = (setFilters: React.Dispatch<SetStateAction<NftFilterValues>>) => (filters: NftFilterValues) => {
    const contactsContext = useExtendedContacts();

    const dateFilterPills: PillProps[] = [
        ...(!!filters.date.startDate
            ? [
                  {
                      label: `From: ${toDateDisplay(filters.date.startDate)}`,
                      clear: () => setFilters(filters => ({ ...filters, date: { ...filters.date, startDate: undefined } })),
                  },
              ]
            : []),
        ...(!!filters.date.endDate
            ? [
                  {
                      label: `To: ${toDateDisplay(filters.date.endDate)}`,
                      clear: () => setFilters(filters => ({ ...filters, date: { ...filters.date, endDate: undefined } })),
                  },
              ]
            : []),
    ];

    const networkPills: PillProps[] = [...filters.selectedNetworks].map(network => ({
        label: NETWORKS[network].label,
        clear: () =>
            setFilters(filters => {
                const selectedNetworks = new Set([...filters.selectedNetworks]);
                selectedNetworks.delete(network);
                return { ...filters, selectedNetworks };
            }),
    }));

    const directionPills: PillProps[] =
        filters.direction == 'In And Out'
            ? []
            : [
                  {
                      label: filters.direction,
                      clear: () => setFilters(filters => ({ ...filters, direction: 'In And Out' })),
                  },
              ];

    const walletPills: PillProps[] = filters.recipient
        ? [
              {
                  label: (() => {
                      const contact = isContactsReady(contactsContext) ? contactsContext.getContact(filters.recipient) : 'not-found';
                      return contact !== 'not-found' && contact ? contact.name : shortAddress(filters.recipient, 3);
                  })(),
                  clear: () => setFilters(filters => ({ ...filters, recipient: undefined })),
              },
          ]
        : [];

    const collectionPills: PillProps[] = [...filters.selectedCollections].map(collection => ({
        label: collection,
        clear: () =>
            setFilters(filters => {
                const selectedCollections = new Set([...filters.selectedCollections]);
                selectedCollections.delete(collection);
                return { ...filters, selectedCollections };
            }),
    }));

    return [...dateFilterPills, ...networkPills, ...directionPills, ...walletPills, ...collectionPills];
};

export const paymentFilterToPills = (setFilters: React.Dispatch<SetStateAction<PaymentFilterValues>>) => (filters: PaymentFilterValues) => {
    const contactsContext = useExtendedContacts();

    const searchPillProps: PillProps[] =
        filters.search == undefined || filters.search.trim() == ''
            ? []
            : [{ label: `"${filters.search}"`, clear: () => setFilters(filters => ({ ...filters, search: '' })) }];

    const dateFilterPills: PillProps[] = [
        ...(!!filters.date.startDate
            ? [
                  {
                      label: `From: ${toDateDisplay(filters.date.startDate)}`,
                      clear: () => setFilters(filters => ({ ...filters, date: { ...filters.date, startDate: undefined } })),
                  },
              ]
            : []),
        ...(!!filters.date.endDate
            ? [
                  {
                      label: `To: ${toDateDisplay(filters.date.endDate)}`,
                      clear: () => setFilters(filters => ({ ...filters, date: { ...filters.date, endDate: undefined } })),
                  },
              ]
            : []),
    ];

    const walletPills: PillProps[] = [...filters.selectedWallets].map(wallet => {
        const contact = isContactsReady(contactsContext) ? contactsContext.getContact(wallet) : 'not-found';
        return {
            label: contact == 'not-found' ? shortAddress(wallet, 3) : contact.name,
            clear: () =>
                setFilters(filters => {
                    const selectedWallets = new Set([...filters.selectedWallets]);
                    selectedWallets.delete(wallet);
                    return { ...filters, selectedWallets };
                }),
        };
    });

    const networkPills: PillProps[] = [...filters.selectedNetworks].map(network => ({
        label: NETWORKS[network].label,
        clear: () =>
            setFilters(filters => {
                const selectedNetworks = new Set([...filters.selectedNetworks]);
                selectedNetworks.delete(network);
                return { ...filters, selectedNetworks };
            }),
    }));

    const tokenPills: PillProps[] = [...filters.selectedTokenSymbols].map(symbol => ({
        label: symbol,
        clear: () =>
            setFilters(filters => {
                const selectedTokenSymbols = new Set([...filters.selectedTokenSymbols]);
                selectedTokenSymbols.delete(symbol);
                return { ...filters, selectedTokenSymbols };
            }),
    }));

    const directionPills: PillProps[] =
        filters.direction == 'In And Out'
            ? []
            : [
                  {
                      label: filters.direction,
                      clear: () => setFilters(filters => ({ ...filters, direction: 'In And Out' })),
                  },
              ];

    const statusPills: PillProps[] =
        filters.claimStatus == undefined
            ? []
            : [{ label: filters.claimStatus, clear: () => setFilters(filters => ({ ...filters, claimStatus: undefined })) }];

    const categoryPill: PillProps[] = !!filters.category
        ? [{ label: filters.category, clear: () => setFilters(filters => ({ ...filters, category: undefined })) }]
        : [];

    return [
        ...searchPillProps,
        ...dateFilterPills,
        ...walletPills,
        ...networkPills,
        ...tokenPills,
        ...directionPills,
        ...statusPills,
        ...categoryPill,
    ];
};

export const factoringInvoiceFilterToPills =
    (setFilters: React.Dispatch<React.SetStateAction<InvoiceTableFactoringFilterValues>>) =>
    (filters: InvoiceTableFactoringFilterValues) => {
        const contactsContext = useExtendedContacts();

        const searchPillProps: PillProps[] =
            filters.search == undefined || filters.search.trim() == ''
                ? []
                : [{ label: `"${filters.search}"`, clear: () => setFilters(filters => ({ ...filters, search: '' })) }];

        const dateFilterPills: PillProps[] = [
            ...(!!filters.date.startDate
                ? [
                      {
                          label: `From: ${toDateDisplay(filters.date.startDate)}`,
                          clear: () => setFilters(filters => ({ ...filters, date: { ...filters.date, startDate: undefined } })),
                      },
                  ]
                : []),
            ...(!!filters.date.endDate
                ? [
                      {
                          label: `To: ${toDateDisplay(filters.date.endDate)}`,
                          clear: () => setFilters(filters => ({ ...filters, date: { ...filters.date, endDate: undefined } })),
                      },
                  ]
                : []),
        ];

        const walletPills: PillProps[] = [...filters.selectedWallets].map(wallet => {
            const contact = isContactsReady(contactsContext) ? contactsContext.getContact(wallet) : 'not-found';
            return {
                label: contact == 'not-found' ? shortAddress(wallet, 3) : contact.name,
                clear: () =>
                    setFilters(filters => {
                        const selectedWallets = new Set([...filters.selectedWallets]);
                        selectedWallets.delete(wallet);
                        return { ...filters, selectedWallets };
                    }),
            };
        });

        const statusPills: PillProps[] =
            filters.claimStatus == undefined
                ? []
                : [{ label: filters.claimStatus, clear: () => setFilters(filters => ({ ...filters, claimStatus: undefined })) }];

        return [...searchPillProps, ...dateFilterPills, ...walletPills, ...statusPills];
    };

type InvoiceFactoringFilterProps = {
    searchPlaceholder: string;
    filters: InvoiceTableFactoringFilterValues;
    setFilters: React.Dispatch<SetStateAction<InvoiceTableFactoringFilterValues>>;
};

export const InvoiceFactoringTableFilter = ({ setFilters, searchPlaceholder, filters }: InvoiceFactoringFilterProps) => {
    const setFilter = (key: keyof InvoiceTableFactoringFilterValues, value: any) => setFilters(filters => ({ ...filters, [key]: value }));
    const setDate = (type: 'startDate' | 'endDate', date: Date | undefined) =>
        setFilters(filters => ({ ...filters, date: { ...filters.date, [type]: date } }));
    const resetDates = () => setFilters(filters => ({ ...filters, date: {} }));

    return (
        <Box w="100%" pb="4">
            <ResponsiveStack>
                <SearchFilter value={filters.search} setValue={str => setFilter('search', str)} placeholder={searchPlaceholder} />
                <DateSelect
                    startDate={filters.date.startDate}
                    endDate={filters.date.endDate}
                    setDate={setDate}
                    resetDates={resetDates}
                    paymentTimestamps={[]}
                />
                <WalletFilter
                    selectedWallets={filters.selectedWallets}
                    onClick={address =>
                        setFilter(
                            'selectedWallets',
                            filters.selectedWallets.has(address)
                                ? (() => {
                                      const newSet = new Set(filters.selectedWallets);
                                      newSet.delete(address);
                                      return newSet;
                                  })()
                                : new Set(filters.selectedWallets).add(address),
                        )
                    }
                />
                <ClaimStatusFilter status={filters.claimStatus} setStatus={claimStatus => setFilters(prev => ({ ...prev, claimStatus }))} />
            </ResponsiveStack>
        </Box>
    );
};

export const buildInvoiceFactoringTableFilters = (filterValues: InvoiceTableFactoringFilterValues): TableFilters<TableItem> => {
    const {
        search,
        date: { startDate, endDate },
        selectedWallets,
        claimStatus,
    } = filterValues;

    const filterByText = !!search
        ? (item: TableItem) => {
              if (!search) return true;
              const filterParameters = [
                  item.tags,
                  item.id,
                  item.creditor,
                  item.debtor,
                  item.displayAmount,
                  item.description,
                  item.factoringFees,
              ];
              return filterParameters.some(value => String(value).toLowerCase().includes(search.trim().toLowerCase()));
          }
        : undefined;

    const filterByDate =
        !startDate && !endDate
            ? undefined
            : (item: TableItem) => checkDate(item.displayDate, startDate, endDate) || checkDate(item.created, startDate, endDate);

    const filterByWallets =
        selectedWallets.size !== 0
            ? (item: TableItem) => [item.creditor, item.debtor, item.originalCreditor].some(x => x && selectedWallets.has(x.toLowerCase()))
            : undefined;

    const filterByClaimStatus = claimStatus === undefined ? undefined : (item: TableItem) => item.itemStatus === claimStatus;

    return [filterByText, filterByDate, filterByWallets, filterByClaimStatus].filter((x): x is TableFilter<TableItem> => x !== undefined);
};

import { ChevronDownIcon, HamburgerIcon, WarningIcon } from '@chakra-ui/icons';
import {
    Box,
    Button,
    Collapse,
    Flex,
    Heading,
    HStack,
    IconButton,
    Image,
    Link,
    Popover,
    PopoverTrigger,
    Spacer,
    Text,
    useBreakpointValue,
    useDisclosure,
} from '@chakra-ui/react';
import { chakra } from '@chakra-ui/system';
import React from 'react';
import { Link as ReactLink, useLocation, useNavigate } from 'react-router-dom';
import BullaIcon from 'url:../../assets/logo_orange.svg';
import { addressEquality } from '../../data-lib/ethereum';
import { chainIds } from '../../data-lib/networks';
import { useGettingStarted } from '../../hooks/useGettingStarted';
import { useIsMobile } from '../../hooks/useIsMobile';
import { useMembership, useMembershipUnsafe } from '../../hooks/useMembership';
import { useWeb3, useWeb3Unsafe } from '../../hooks/useWeb3';
import { isUserReady, useAuth } from '../../state/auth-state';
import { useGnosisSafe } from '../../state/gnosis-state';
import { BULLA_NETWORK_DISCORD_INVITE, isBrave, isEdge } from '../../tools/common';
import { bypassProForReportingExplorer, enableMandatoryPremium, enableReporting, enableSwaps } from '../../tools/featureFlags';
import { PopoverAction, PopoverExternalAction, StyledPopoverContent } from '../display/actionPopover';
import { MobileMenu } from '../display/mobile-menu';
import { BetaTag } from '../display/views/financing';
import { WalletInfo } from '../display/wallet-info';
import { LinkButton, LinkButtonText } from '../inputs/buttons';
import { GnosisOnboardingModal } from '../modals/gnosis-onboarding-modal';

export const Logo = ({ omitTitle }: { omitTitle: boolean }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();

    return (
        <Flex direction={{ base: 'column', md: 'row' }} justifyContent="space-between" alignItems="center">
            <ReactLink to={'/'} data-testid="home-link">
                <HStack spacing="2" display={{ base: 'none', md: 'flex' }}>
                    <Box w="32px" h="32px">
                        <Image w="100%" h="100%" src={BullaIcon} />
                    </Box>

                    {!omitTitle && (
                        <>
                            <Heading fontSize="24px" fontWeight="700" color="white">
                                Bulla
                            </Heading>
                        </>
                    )}
                </HStack>
            </ReactLink>
            <IconButton
                icon={<HamburgerIcon boxSize={6} />}
                display={{ base: 'block', md: 'none' }}
                onClick={onOpen}
                aria-label={''}
                backgroundColor="transparent"
                _hover={{ backgroundColor: 'transparent' }}
            />

            <MobileMenu isOpen={isOpen} onClose={onClose} />
        </Flex>
    );
};

type NavDropdownProps = { title: string; actions: React.ReactNode[]; isBeta?: boolean };
const NavDropdown = ({ title, actions, isBeta }: NavDropdownProps) => (
    <Popover placement="top-start">
        <PopoverTrigger>
            <HStack as="button" spacing="1">
                <LinkButtonText>{title}</LinkButtonText>
                {isBeta && <BetaTag />}
                <ChevronDownIcon />
            </HStack>
        </PopoverTrigger>
        <StyledPopoverContent title={title} actions={actions} />
    </Popover>
);

const FinancingDropdown = () => {
    const navigate = useNavigate();

    return (
        <NavDropdown
            title="Financing"
            actions={[
                <PopoverAction onClick={() => navigate('/financing')}>Wallet-to-wallet Lending</PopoverAction>,
                <PopoverAction onClick={() => navigate('/factoring/pools')}>Financing Pools</PopoverAction>,
            ]}
            isBeta
        />
    );
};

const HelpDropdown = ({ showGettingStarted }: { showGettingStarted?: boolean }) => {
    const { setGettingStartedEnabled } = useGettingStarted();
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const goToGettingStarted = () => {
        setGettingStartedEnabled(true);
        if (pathname !== '/') navigate('/');
    };

    const actions = [
        showGettingStarted && <PopoverAction onClick={goToGettingStarted}>Getting Started</PopoverAction>,
        <PopoverExternalAction href="https://bulla-network.gitbook.io/bulla-network/">Gitbook</PopoverExternalAction>,
        <PopoverExternalAction href={BULLA_NETWORK_DISCORD_INVITE}>Discord</PopoverExternalAction>,
    ].filter(Boolean);

    return <NavDropdown title="Help" actions={actions} />;
};

const Navigation = () => {
    const isMobile = useIsMobile();
    const premiumMembership = useMembership();

    return isMobile ? null : (
        <HStack spacing="6">
            <LinkButton to={'/'} color="gray.50">
                <>Explorer</>
            </LinkButton>
            <LinkButton to={'/contacts'} color="gray.50">
                <>Contacts</>
            </LinkButton>
            <LinkButton to={'/factoring/pools'} color="gray.50">
                <>Finance Pools</>
            </LinkButton>
            {enableReporting && (premiumMembership?.tier == 'pro' || bypassProForReportingExplorer) && (
                <LinkButton to={'/reporting'} color="gray.50">
                    <>Reporting</>
                </LinkButton>
            )}

            <HelpDropdown showGettingStarted={true} />
        </HStack>
    );
};

type GnosisBannerConnectedState = { connectedSafeAddress: string; isOwner: boolean };
type GnosisBannerState = GnosisBannerConnectedState | 'can-connect-to-safe' | 'no-safes-to-connect-to';
export type GnosisBannerContext = { showBanner: boolean; badUserAgent: boolean; gnosisBannerState: GnosisBannerState };
export const isGnosisConnectedState = (state: GnosisBannerState): state is GnosisBannerConnectedState => typeof state == 'object';

export const useGnosisSafeBanner = (): GnosisBannerContext => {
    const { userAddress, connectedNetwork } = useWeb3();
    const { userSafes, connectedSafeAddress, isSafeReady, safeInfo } = useGnosisSafe();

    const gnosisBannerState: GnosisBannerState = (() => {
        if (!!connectedSafeAddress) return { connectedSafeAddress, isOwner: !!safeInfo?.owners.some(o => addressEquality(o, userAddress)) };
        if (!userSafes) return 'no-safes-to-connect-to';
        return 'can-connect-to-safe';
    })();

    const badUserAgent = (isBrave() || isEdge()) && connectedNetwork == chainIds.RSK;
    const showBanner = !!connectedSafeAddress && (!isSafeReady || badUserAgent);

    return { showBanner, badUserAgent, gnosisBannerState };
};

export const GnosisBanner = ({ showBanner, gnosisBannerState, badUserAgent }: GnosisBannerContext) => (
    <Collapse in={showBanner} style={{ zIndex: 1, top: 0, position: 'fixed' }}>
        <Flex
            bg={'orangeBg'}
            w="100vw"
            align="center"
            justify="center"
            h="min-content"
            color="gray.50"
            textAlign={'center'}
            fontSize={'1.1em'}
        >
            <HStack ml="10" px="2" py="px">
                {isGnosisConnectedState(gnosisBannerState) && !gnosisBannerState.isOwner ? (
                    <>
                        <WarningIcon color="white" />
                        <Text>
                            <b>View Only:</b> You are not an owner on this Gnosis Safe
                        </Text>
                    </>
                ) : badUserAgent ? (
                    <>
                        <WarningIcon color="white" />
                        <Text>
                            Gnosis Safe has known issues with RSK on {isBrave() ? 'Brave Browser' : 'Microsoft Edge'}, please consider using
                            Google Chrome.
                        </Text>
                    </>
                ) : (
                    <>
                        <WarningIcon color="red.500" />
                        <Text>
                            The Bulla Banker Module for your Gnosis Safe is not installed or out of date. Please install to make new
                            transactions.
                        </Text>
                        <GnosisOnboardingModal
                            triggerElement={({ install }) => (
                                <Button size="xs" onClick={install} colorScheme={'gray'} color="black">
                                    Install
                                </Button>
                            )}
                        />
                    </>
                )}
            </HStack>
        </Flex>
    </Collapse>
);

const SupportText = () => (
    <chakra.span fontWeight={400}>
        <chakra.span fontSize={'16px'}>Talk with our team on </chakra.span>
        <Link fontSize={'16px'} textDecoration={'initial'} color="#3376C2" href={BULLA_NETWORK_DISCORD_INVITE} target={'_blank'}>
            Discord
        </Link>
        <chakra.span fontSize={'16px'}> for support.</chakra.span>
    </chakra.span>
);

type PricingToolbarProps = {
    daysLeft: number;
    onClick: () => void;
};

export const UpgradeButton: React.FC<{ onClick: () => void; h?: string }> = ({ onClick, h }) => (
    <Button
        ml={5}
        bg="#FCEDE2"
        color="#DF5907"
        border="1px solid #F3B685"
        borderRadius={'8px'}
        _hover={{ bg: '#F8D3B6', cursor: 'pointer' }}
        onClick={onClick}
        h={h}
    >
        Upgrade Now
    </Button>
);

export const PricingToolbar: React.FC<PricingToolbarProps> = ({ daysLeft, onClick }) => (
    <Flex w="100%" align="center" justify="center" bg="white" color="black" p="3">
        <Text>You have {daysLeft} days left in your free trial</Text>
        <UpgradeButton onClick={onClick} />
    </Flex>
);

export const Header = () => {
    const gnosisBannerContext = useGnosisSafeBanner();
    const { connectedNetworkConfigUnsafe } = useWeb3Unsafe();
    const { user } = useAuth();
    const isConnected = !!connectedNetworkConfigUnsafe && typeof connectedNetworkConfigUnsafe === 'object';
    const isMobile = useBreakpointValue({ base: true, sm: false }, { ssr: false });
    const isLoggedIn = isConnected && isUserReady(user);
    const premiumMembership = useMembershipUnsafe();
    const navigate = useNavigate();

    const handleUpgradeClick = () => {
        navigate('/upgrade');
    };
    const upgradeToolbarState: 'dont-show' | { daysLeft: number } = React.useMemo(() => {
        const now = new Date();
        const nowInSeconds = now.getTime() / 1000;
        return enableMandatoryPremium &&
            premiumMembership !== 'not-connected' &&
            premiumMembership !== null &&
            premiumMembership.isFreeTrial &&
            nowInSeconds < premiumMembership.exp
            ? { daysLeft: Math.ceil((premiumMembership.exp - nowInSeconds) / (60 * 60 * 24)) }
            : 'dont-show';
    }, [premiumMembership]);

    return (
        <>
            <GnosisBanner {...gnosisBannerContext} />
            <Box bg="headerBg">
                {upgradeToolbarState !== 'dont-show' && (
                    <PricingToolbar daysLeft={upgradeToolbarState.daysLeft} onClick={handleUpgradeClick} />
                )}
                <Flex
                    as="header"
                    w="100%"
                    minH="15"
                    align="center"
                    justify={'center'}
                    m="0"
                    p="5"
                    bg="headerBg"
                    pt={gnosisBannerContext.showBanner ? '10' : '5'}
                >
                    <HStack w="100%" maxW="pageMax" align="center" spacing="6">
                        <Logo omitTitle={!!isMobile} />
                        {isLoggedIn && premiumMembership != null && <Navigation />}
                        {premiumMembership == null && <HelpDropdown />}
                        <Spacer />
                        {!isLoggedIn && <SupportText />}
                        {isConnected && <WalletInfo />}
                    </HStack>
                </Flex>
            </Box>
        </>
    );
};

import {
    Box,
    Divider,
    HStack,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
    VStack,
} from '@chakra-ui/react';
import { Formik, Form, Field, FieldProps, FormikProps } from 'formik';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { SwapCoins } from '../../../assets/swap-coins';
import { CloseModalButton } from '../common';
import { IconBox } from '../common-reporting/progress-steps';
import {
    ChainSelector,
    ClaimAmountField,
    claimAmountValidationSchema,
    EmailField,
    RecipientField,
} from '../create-claim-modal/create-claim-inputs';
import { useOnboard, useWeb3 } from '../../../hooks/useWeb3';
import { ChainId, NETWORKS, SWAP_SUPPORTED_NETWORKS, TokenDto } from '../../../data-lib/networks';
import { CreateWhiteButton, OrangeButton } from '../../inputs/buttons';
import * as Yup from 'yup';
import { apply } from '../../../tools/common';
import { useUIState } from '../../../state/ui-state';
import { useAllowances } from '../../../hooks/useAllowances';
import { BigNumber } from 'ethers';
import { useTokenBalances } from '../../../hooks/useChainData';
import { parseUnits } from 'ethers/lib/utils';
import { TokenApprovalModal } from '../token-approval.modal';
import { useAppState } from '../../../state/app-state';
import { useGnosisSafe } from '../../../state/gnosis-state';
import { CreateOrderParams, useBullaSwap } from '../../../hooks/useBullaSwap';
import { useActingWalletAddress } from '../../../hooks/useWalletAddress';
import { useGnosisTransaction } from '../../../hooks/useGnosisTransaction';
import { addressEquality } from '../../../data-lib/ethereum';
import { useGlobalUserData } from '../../../hooks/useUserData';
import { PastSwapsTable } from './past-swaps-table';

const supportedSwapNetworks = [...SWAP_SUPPORTED_NETWORKS] as ChainId[];

type CreateSwapModalProps = { isOpen: boolean; onClose: VoidFunction };

const CancelButton = CreateWhiteButton('Cancel');

const errorSchema = Yup.object().shape({
    offerAmount: claimAmountValidationSchema.required('Offer amount is required'),
    receiveAmount: claimAmountValidationSchema.required('Receive amount is required'),
    offerTo: Yup.string().required('Recipient address is required'),
    emailAddress: Yup.string()
        .email('Invalid email address')
        .optional()
        .transform(value => (value === '' ? undefined : value)),
    offerToken: Yup.object().required('Offer token is required'),
    receiveToken: Yup.object().required('Receive token is required'),
});

interface SwapFormValues {
    offerAmount: string;
    offerToken: TokenDto;
    receiveAmount: string;
    receiveToken: TokenDto;
    offerTo: string;
    emailAddress?: string;
}

export const CreateSwapModal = ({ isOpen, onClose }: CreateSwapModalProps) => {
    const {
        connectedNetwork,
        connectedNetworkConfig: { bullaSwap },
    } = useWeb3();
    const { swaps } = useGlobalUserData('exclude-originating-claims');
    const [approvalPending, { getAllowanceForTokenAddress, approveTokens, approveModalOpen, closeApproveModal }] =
        useAllowances('exact-allowance');
    const [allowance, setAllowance] = React.useState<'init' | BigNumber>('init');
    const [executingSafeTx, setExecutingSafeTx] = useState<boolean>(false);
    const [executing, { createOrder, createOrderInputToMultisendTxDTO }] = useBullaSwap();
    const isLoading = approvalPending || executingSafeTx || allowance == 'init' || executing;
    const { changeNetwork } = useOnboard();
    const { transactionPending } = useUIState();
    const modalContentRef = useRef<HTMLDivElement>(null);
    const tokenBalances = useTokenBalances({ chainId: connectedNetwork, poll: true });
    const { readyToTransact } = useAppState();
    const isDisabled = !readyToTransact;
    const { safeInfo } = useGnosisSafe();
    const actingWallet = useActingWalletAddress();
    const { executeTransactions } = useGnosisTransaction();

    const sortedTokens = useMemo(() => {
        const tokensWithBalance = Object.values(NETWORKS[connectedNetwork].supportedTokens).map(tokenInfo => {
            const balance = tokenBalances.getBalanceForToken(tokenInfo.token.address)?.toString() ?? '0';
            return { ...tokenInfo, balance };
        });

        tokensWithBalance.sort((a, b) => parseFloat(b.balance) - parseFloat(a.balance));

        return tokensWithBalance;
    }, [connectedNetwork, tokenBalances]);

    const defaultOfferToken = sortedTokens[0]?.token;
    const defaultReceiveToken = sortedTokens[1]?.token;

    const formikRef = useRef<FormikProps<SwapFormValues>>(null);

    useEffect(() => {
        if (formikRef.current && sortedTokens.length >= 2) {
            formikRef.current.setFieldValue('offerToken', sortedTokens[0]?.token);
            formikRef.current.setFieldValue('receiveToken', sortedTokens[1]?.token);
        }
    }, [connectedNetwork, sortedTokens]);

    const isApproved = useMemo(() => {
        if (allowance === 'init' || formikRef.current?.values.offerAmount === '') return false;
        if (!formikRef.current?.values.offerToken) return false;
        const parsedOfferAmount = parseUnits(formikRef.current.values.offerAmount, formikRef.current.values.offerToken.decimals);
        return allowance.gt(parsedOfferAmount);
    }, [allowance, formikRef.current?.values.offerAmount, formikRef.current?.values.offerToken.decimals]);

    useEffect(() => {
        if (!formikRef.current?.values.offerToken) return;
        getAllowanceForTokenAddress(bullaSwap!, connectedNetwork)(formikRef.current?.values.offerToken.address).then(setAllowance);
    }, [formikRef.current?.values.offerToken.address, connectedNetwork]);

    const handleSwap = async (
        offerToken: TokenDto,
        offerAmount: string,
        receiveToken: TokenDto,
        receiveAmount: string,
        offerTo: string,
    ) => {
        if (offerAmount === '' || receiveAmount === '') return;

        const parsedOfferAmount = parseUnits(offerAmount, offerToken.decimals);
        const parsedReceiveAmount = parseUnits(receiveAmount, receiveToken.decimals);
        const oneWeekFromNow = Math.floor(new Date().setDate(new Date().getDate() + 7) / 1000);

        const order: CreateOrderParams = {
            expiry: BigNumber.from(oneWeekFromNow),
            signerWallet: actingWallet,
            signerToken: offerToken.address,
            signerAmount: parsedOfferAmount,
            senderWallet: offerTo,
            senderToken: receiveToken.address,
            senderAmount: parsedReceiveAmount,
        };

        if (safeInfo) {
            setExecutingSafeTx(true);
            try {
                const { transaction } = await createOrderInputToMultisendTxDTO(bullaSwap!, order, offerToken);
                const { success } = await executeTransactions(safeInfo, [transaction], true);
                if (success) onClose();
            } finally {
                setExecutingSafeTx(false);
            }
        } else if (isApproved) {
            await createOrder(order).then(success => {
                if (success) {
                    onClose();
                }
            });
        } else {
            await approveTokens(bullaSwap!)([
                {
                    amount: parsedOfferAmount,
                    token: offerToken,
                },
            ]).then(({ success }) => {
                if (success) {
                    setAllowance(parsedOfferAmount);
                }
            });
        }
    };

    return (
        <Modal
            isCentered
            isOpen={isOpen}
            onClose={onClose}
            motionPreset="slideInBottom"
            closeOnOverlayClick={true}
            closeOnEsc={true}
            size="2xl"
        >
            <ModalOverlay />
            <ModalContent ref={modalContentRef}>
                <Formik
                    innerRef={formikRef}
                    initialValues={{
                        offerAmount: '',
                        offerToken: defaultOfferToken,
                        receiveAmount: '',
                        receiveToken: defaultReceiveToken,
                        offerTo: '',
                        emailAddress: '',
                    }}
                    onSubmit={(values, actions) => {
                        const { offerAmount, offerToken, receiveAmount, receiveToken, offerTo } = values;

                        handleSwap(offerToken, offerAmount, receiveToken, receiveAmount, offerTo).then(() => {
                            actions.setSubmitting(false);
                        });
                    }}
                    validationSchema={errorSchema}
                >
                    {({ errors, touched, values, setFieldValue, setFieldTouched }) => {
                        const [insufficientFunds, setInsufficientFunds] = React.useState<boolean>();
                        const pastSwapsToSender =
                            values.offerTo && swaps.filter(p => addressEquality(p.senderWallet, values.offerTo)).slice(0, 2);

                        useEffect(() => {
                            if (tokenBalances && values.offerAmount != '') {
                                const insufficientFundsResult: boolean =
                                    +(tokenBalances.getBalanceForToken(values.offerToken.address) ?? 0) < +values.offerAmount;
                                setInsufficientFunds(insufficientFundsResult);
                            }
                        }, [values.offerAmount]);

                        return (
                            <Form placeholder={''}>
                                <ModalHeader display="flex" flexDirection="row">
                                    <HStack alignItems="center">
                                        <IconBox icon={SwapCoins} boxShadow="none" w="50px" h="50px" />
                                        <VStack align="flex-start" ml={3} spacing={0}>
                                            <Text color="heading" fontWeight={'700'} fontSize="20px">
                                                Swap Offer
                                            </Text>
                                            <Text fontSize={'15px'} fontWeight={'400'} color="gray.600">
                                                Offer to swap coins with another wallet.
                                            </Text>
                                        </VStack>
                                    </HStack>
                                </ModalHeader>
                                <Box mt={4}>
                                    <Divider width="calc(100%)" sx={{ height: '0.5px' }} />
                                </Box>
                                <ModalBody>
                                    <Box>
                                        <Text fontSize="14px" fontWeight="500" mb="2">
                                            Chain
                                        </Text>
                                        <ChainSelector
                                            chainId={connectedNetwork}
                                            isDisabled={isLoading}
                                            selectableChains={supportedSwapNetworks}
                                            textAlign={'left'}
                                            onChainSelected={changeNetwork}
                                            w="100%"
                                            mb="4"
                                        />
                                    </Box>
                                    <VStack spacing="3">
                                        <Field name="offerAmount">
                                            {({ field }: FieldProps) => (
                                                <ClaimAmountField
                                                    {...{
                                                        claimType: 'Swap',
                                                        field,
                                                        isDisabled: transactionPending,
                                                        includeNativeToken: false,
                                                        error: errors.offerAmount,
                                                        touched: touched.offerAmount,
                                                        setAmount: apply(setFieldValue, 'offerAmount'),
                                                        setToken: apply(setFieldValue, 'offerToken'),
                                                        amount: values.offerAmount,
                                                        token: values.offerToken,
                                                        setFieldTouched,
                                                        label: 'Offer',
                                                    }}
                                                />
                                            )}
                                        </Field>
                                        <Field name="receiveAmount">
                                            {({ field }: FieldProps) => (
                                                <ClaimAmountField
                                                    {...{
                                                        claimType: 'Swap',
                                                        field,
                                                        isDisabled: transactionPending,
                                                        includeNativeToken: false,
                                                        error: errors.receiveAmount,
                                                        touched: touched.receiveAmount,
                                                        setAmount: apply(setFieldValue, 'receiveAmount'),
                                                        setToken: apply(setFieldValue, 'receiveToken'),
                                                        amount: values.receiveAmount,
                                                        token: values.receiveToken,
                                                        setFieldTouched,
                                                        label: 'Receive',
                                                    }}
                                                />
                                            )}
                                        </Field>
                                        <Field name="offerTo">
                                            {({ field }: FieldProps) => (
                                                <RecipientField
                                                    {...{
                                                        field,
                                                        isDisabled: transactionPending,
                                                        error: errors.offerTo,
                                                        touched: touched.offerTo,
                                                        setRecipient: name => !!name && setFieldValue('offerTo', name),
                                                        label: 'Offer to',
                                                        dropdownModalRef: modalContentRef,
                                                        initialValue: values.offerTo,
                                                        required: true,
                                                    }}
                                                />
                                            )}
                                        </Field>
                                        {pastSwapsToSender && (
                                            <PastSwapsTable swaps={pastSwapsToSender} readyToTransact={readyToTransact} />
                                        )}
                                    </VStack>
                                    <Divider sx={{ height: '0.5px' }} my="4" />
                                    <Field name="emailAddress">
                                        {({ field }: FieldProps) => (
                                            <EmailField
                                                {...{
                                                    field,
                                                    isDisabled: isLoading,
                                                    error: errors.emailAddress,
                                                    touched: touched.emailAddress,
                                                    label: 'Confirmation Email',
                                                    required: false,
                                                    mt: '6',
                                                }}
                                            />
                                        )}
                                    </Field>
                                </ModalBody>
                                <Box mt={4}>
                                    <Divider width="calc(100%)" sx={{ height: '0.5px' }} />
                                </Box>
                                <ModalFooter>
                                    <HStack w="100%" spacing={4}>
                                        <CancelButton onClick={onClose} h="12" w="50%" />
                                        <>
                                            <OrangeButton
                                                isLoading={isLoading}
                                                isDisabled={isDisabled || isLoading || Object.keys(errors).length > 0 || insufficientFunds}
                                                type="submit"
                                                w="50%"
                                            >
                                                {insufficientFunds
                                                    ? 'Insufficient funds'
                                                    : isApproved || safeInfo
                                                    ? 'Send Offer'
                                                    : 'Send Offer (Approve Token)'}
                                            </OrangeButton>
                                            {allowance != 'init' && (
                                                <TokenApprovalModal
                                                    modalOpen={approveModalOpen}
                                                    closeModal={closeApproveModal}
                                                    approvalInfos={[
                                                        {
                                                            approvalAmount:
                                                                values.offerAmount != ''
                                                                    ? parseUnits(values.offerAmount, values.offerToken.decimals)
                                                                    : BigNumber.from('0'),
                                                            token: values.offerToken,
                                                        },
                                                    ]}
                                                />
                                            )}
                                        </>
                                    </HStack>
                                </ModalFooter>
                            </Form>
                        );
                    }}
                </Formik>

                <CloseModalButton onClose={onClose} />
            </ModalContent>
        </Modal>
    );
};

import { Box } from '@chakra-ui/react';
import React from 'react';
import { ChainId, NETWORKS } from '../../../../data-lib/networks';
import { useGnosisSafe } from '../../../../state/gnosis-state';
import { toDateDisplay } from '../../../../tools/common';
import { shortAddress } from '../../../base/address-label';
import { ResponsiveStack } from '../../responsive-stack';
import { TokenMultiselect } from '../../token-multiselect';
import { NetworkMultiselect } from '../../view-network-selector';
import { SearchFilter, DateSelect, WalletFilter, PillProps } from './common';

export type SwapFilterValues = {
    date: { startDate?: Date; endDate?: Date };
    selectedWallets: Set<string>;
    selectedNetworks: Set<ChainId>;
    selectedTokenSymbols: Set<string>;
};

export const getInitialSwapFilterValues = (initialSelectedWallet?: string): SwapFilterValues => ({
    date: { startDate: undefined, endDate: undefined },
    selectedWallets: new Set(initialSelectedWallet ? [initialSelectedWallet] : []),
    selectedNetworks: new Set(),
    selectedTokenSymbols: new Set(),
});

export const emptySwapFilterValues: SwapFilterValues = {
    date: { startDate: undefined, endDate: undefined },
    selectedWallets: new Set(),
    selectedNetworks: new Set(),
    selectedTokenSymbols: new Set(),
};

type SetFilter = (key: keyof SwapFilterValues, value: SwapFilterValues[keyof SwapFilterValues]) => void;
type SetDate = (type: 'startDate' | 'endDate', date: Date | undefined) => void;

interface SwapFilterProps {
    filters: SwapFilterValues;
    setFilters: React.Dispatch<React.SetStateAction<SwapFilterValues>>;
    searchPlaceholder?: string;
}

export const SwapFilter: React.FC<SwapFilterProps> = ({ filters, setFilters, searchPlaceholder = 'Search swaps...' }) => {
    const { safeInfo } = useGnosisSafe();

    const setFilter: SetFilter = (key, value) => setFilters(filters => ({ ...filters, [key]: value }));

    const setDate: SetDate = (type, date) => setFilters(filters => ({ ...filters, date: { ...filters.date, [type]: date } }));

    const resetDates = () => setFilters(filters => ({ ...filters, date: {} }));

    return (
        <Box w="100%" pb="4">
            <ResponsiveStack>
                <DateSelect
                    startDate={filters.date.startDate}
                    endDate={filters.date.endDate}
                    setDate={setDate}
                    resetDates={resetDates}
                    paymentTimestamps={[]}
                />
                <WalletFilter
                    selectedWallets={filters.selectedWallets}
                    onClick={address =>
                        setFilter(
                            'selectedWallets',
                            filters.selectedWallets.has(address)
                                ? (() => {
                                      filters.selectedWallets.delete(address);
                                      return filters.selectedWallets;
                                  })()
                                : filters.selectedWallets.add(address),
                        )
                    }
                />
                {!safeInfo && (
                    <NetworkMultiselect
                        selectedNetworks={filters.selectedNetworks}
                        toggleNetwork={chainId =>
                            setFilter(
                                'selectedNetworks',
                                filters.selectedNetworks.has(chainId)
                                    ? (() => {
                                          filters.selectedNetworks.delete(chainId);
                                          return filters.selectedNetworks;
                                      })()
                                    : filters.selectedNetworks.add(chainId),
                            )
                        }
                    />
                )}
                <TokenMultiselect
                    selectedTokenSymbols={filters.selectedTokenSymbols}
                    toggleTokenSymbol={symbol =>
                        setFilter(
                            'selectedTokenSymbols',
                            filters.selectedTokenSymbols.has(symbol)
                                ? (() => {
                                      filters.selectedTokenSymbols.delete(symbol);
                                      return filters.selectedTokenSymbols;
                                  })()
                                : filters.selectedTokenSymbols.add(symbol),
                        )
                    }
                />
            </ResponsiveStack>
        </Box>
    );
};

export const swapFilterToPills =
    (setFilters: React.Dispatch<React.SetStateAction<SwapFilterValues>>) =>
    (filters: SwapFilterValues): PillProps[] => {
        const dateFilterPills: PillProps[] = [
            ...(filters.date.startDate
                ? [
                      {
                          label: `From: ${toDateDisplay(filters.date.startDate)}`,
                          clear: () =>
                              setFilters(filters => ({
                                  ...filters,
                                  date: { ...filters.date, startDate: undefined },
                              })),
                      },
                  ]
                : []),
            ...(filters.date.endDate
                ? [
                      {
                          label: `To: ${toDateDisplay(filters.date.endDate)}`,
                          clear: () =>
                              setFilters(filters => ({
                                  ...filters,
                                  date: { ...filters.date, endDate: undefined },
                              })),
                      },
                  ]
                : []),
        ];

        const networkPills: PillProps[] = [...filters.selectedNetworks].map(network => ({
            label: NETWORKS[network].label,
            clear: () =>
                setFilters(filters => {
                    const selectedNetworks = new Set([...filters.selectedNetworks]);
                    selectedNetworks.delete(network);
                    return { ...filters, selectedNetworks };
                }),
        }));

        const tokenPills: PillProps[] = [...filters.selectedTokenSymbols].map(symbol => ({
            label: symbol,
            clear: () =>
                setFilters(filters => {
                    const selectedTokenSymbols = new Set([...filters.selectedTokenSymbols]);
                    selectedTokenSymbols.delete(symbol);
                    return { ...filters, selectedTokenSymbols };
                }),
        }));

        const walletPills: PillProps[] = [...filters.selectedWallets].map(wallet => ({
            label: shortAddress(wallet, 3),
            clear: () =>
                setFilters(filters => {
                    const selectedWallets = new Set([...filters.selectedWallets]);
                    selectedWallets.delete(wallet);
                    return { ...filters, selectedWallets };
                }),
        }));

        return [...dateFilterPills, ...networkPills, ...tokenPills, ...walletPills];
    };

import { Flex, HStack, Text } from '@chakra-ui/react';
import React, { useCallback, useRef } from 'react';
import { WarningIconWithTooltip } from '../../../assets/warning';
import { FrendLendInfo, FrendLendOffer } from '../../../data-lib/data-model';
import { getFinancingSummaryLabel, TermLength, termLengthToDays } from '../../../data-lib/dto/bulla-finance-dto';
import { addressEquality, EthAddress } from '../../../data-lib/ethereum';
import { ChainId } from '../../../data-lib/networks';
import { useOpenLoanOffer } from '../../../hooks/useClaimDetailDisclosure';
import { useFrendLend } from '../../../hooks/useFrendLend';
import { useIsMobile } from '../../../hooks/useIsMobile';
import { BullaItemInfoWithPayment } from '../../../hooks/useUserData';
import { useActingWalletAddress } from '../../../hooks/useWalletAddress';
import { useWeb3 } from '../../../hooks/useWeb3';
import { ContactNameOrAddress } from '../../base/address-label';
import { ChainSymbol } from '../../chain-symbol';
import { LoanOfferButton, ViewDetailsButton } from '../../inputs/buttons';
import {
    shadowProps,
    ListViewCard,
    CHAIN_COLUMN_WIDTH,
    FROM_TO_COLUMN_WIDTH,
    ListViewCardProps,
    VIEW_COLUMN_WIDTH,
    TwoLineTextWithLabel,
    ListItemProps,
} from '../../layout/cards';
import { Direction } from '../claim-table';

type LabelType = 'offer' | 'receive';

const createLoanOffersAddressLabel = (labelType: LabelType) => {
    const LabelComponent: React.FC<{ address: EthAddress; chainId: ChainId }> = ({ address, chainId }) => (
        <Flex alignItems="center">
            <Text mr={1}>{labelType === 'offer' ? 'Offered to' : 'Received from'}</Text>
            <ContactNameOrAddress chainId={chainId} hideAddressIfFound>
                {address}
            </ContactNameOrAddress>
        </Flex>
    );

    return LabelComponent;
};

const OfferLabel = createLoanOffersAddressLabel('offer');
const ReceiveLabel = createLoanOffersAddressLabel('receive');

const LOAN_HEADERS: ListViewCardProps['headers'] = [
    { label: 'offered to / received by', relativeColumnWidth: FROM_TO_COLUMN_WIDTH },
    { label: 'description' },
    { label: 'offer', relativeColumnWidth: FROM_TO_COLUMN_WIDTH },
    { label: 'chain', relativeColumnWidth: CHAIN_COLUMN_WIDTH },
    { label: '', relativeColumnWidth: VIEW_COLUMN_WIDTH },
    { label: '', relativeColumnWidth: VIEW_COLUMN_WIDTH },
    { label: '', relativeColumnWidth: VIEW_COLUMN_WIDTH },
];
const MOBILE_HEADERS = ['date', 'offer'];

export type LoanOffersTableItem = FrendLendInfo & {
    viewLoanOffer: VoidFunction;
    direction: Direction;
    acceptLoan: (loanId: string) => void;
    rejectLoan: (loanId: string) => void;
};

export const mapFrendLendOfferToLoanOffersTableItem = (
    items: FrendLendOffer[],
    userAddress: EthAddress,
    openLoanOffer: (loanOfferId: string, network: string | ChainId, useFinancingPath: boolean) => void,
    acceptLoan: (loanId: string) => void,
    rejectLoan: (loanId: string) => void,
): LoanOffersTableItem[] =>
    items.map(item => {
        const viewLoanOffer = () => openLoanOffer(item.loanId, item.chainId, false);
        const direction = addressEquality(userAddress, item.debtor) ? 'In' : 'Out';

        return {
            ...item,
            direction,
            viewLoanOffer,
            acceptLoan,
            rejectLoan,
        };
    });

interface LoanOffersProps {
    offers: FrendLendOffer[];
}

export const LoanOffersTemplate: React.FC<LoanOffersProps> = ({ offers }) => {
    const [, { acceptLoan, rejectLoan }] = useFrendLend();
    const actingWallet = useActingWalletAddress();
    const openLoanOffer = useOpenLoanOffer();
    const { connectedNetwork } = useWeb3();
    const getOfferActionButtonDisabled = useCallback(
        (chainId: ChainId) => {
            return chainId !== connectedNetwork;
        },
        [connectedNetwork],
    );

    const lineItems: LoanOffersTableItem[] = mapFrendLendOfferToLoanOffersTableItem(
        offers,
        actingWallet,
        openLoanOffer,
        acceptLoan,
        rejectLoan,
    );
    const isMobile = useIsMobile();
    const listViewCardRef = useRef<HTMLDivElement>(null);

    const stringifiedOffers = JSON.stringify(lineItems);

    const displayedListItems = lineItems.map((item): ListItemProps => {
        const {
            debtor,
            creditor,
            description,
            tokenInfo,
            chainId,
            viewLoanOffer,
            acceptLoan,
            rejectLoan,
            direction,
            interestBPS,
            loanAmount,
        } = item;

        const isOfferSent = addressEquality(actingWallet, creditor);
        const otherUserAddress = isOfferSent ? debtor : creditor;
        const AddressLabel = isOfferSent ? OfferLabel : ReceiveLabel;
        const offer = getFinancingSummaryLabel(tokenInfo.token, {
            interestRateBPS: interestBPS,
            loanValue: loanAmount,
        });

        const onClickAcceptLoan = () => acceptLoan(item.loanId);
        const onClickRejectLoan = () => rejectLoan(item.loanId);
        const termLengthDays = termLengthToDays(item.termLength);
        const timeElapsed = Math.abs(new Date().getTime() - item.offerDate.getTime());
        const daysElapsed = Math.ceil(timeElapsed / (1000 * 3600 * 24));
        const showWarning = daysElapsed > 10;
        const offerActionButtonDisabled = item.chainId !== connectedNetwork;

        return {
            columnValues: isMobile
                ? [<AddressLabel address={otherUserAddress} chainId={chainId} />, offer]
                : [
                      <HStack>
                          {showWarning && (
                              <WarningIconWithTooltip
                                  label="This has been offered for more than 10 days"
                                  warningOverrides={{ color: 'gray.900', w: '16px', h: '16px' }}
                              />
                          )}
                          <AddressLabel address={otherUserAddress} chainId={chainId} />
                      </HStack>,
                      <TwoLineTextWithLabel>{description}</TwoLineTextWithLabel>,
                      offer,
                      <ChainSymbol chainId={chainId} />,
                      <ViewDetailsButton onClick={viewLoanOffer} />,
                      direction == 'In' && (
                          <LoanOfferButton onClick={onClickAcceptLoan} text="Accept Loan" isDisabled={offerActionButtonDisabled} />
                      ),
                      <LoanOfferButton
                          onClick={onClickRejectLoan}
                          text={direction == 'In' ? 'Reject Loan' : 'Cancel Offer'}
                          isDisabled={offerActionButtonDisabled}
                      />,
                  ],
            selectId: item.loanId,
        };
    });

    const mainSection = React.useMemo(
        () => (
            <>
                <Flex {...shadowProps} direction={'column'} flex="1" overflowX="auto" overflow={'visible'} ref={listViewCardRef}>
                    <ListViewCard
                        headers={isMobile ? MOBILE_HEADERS : LOAN_HEADERS}
                        displayedListItems={displayedListItems}
                        bordered={false}
                        totalItemCount={displayedListItems.length}
                        emptyMessage="No loan offers to display"
                    />
                </Flex>
            </>
        ),
        [stringifiedOffers, isMobile, connectedNetwork],
    );

    return mainSection;
};

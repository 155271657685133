import {
    Box,
    chakra,
    Divider,
    Flex,
    Heading,
    HStack,
    Link,
    Modal,
    ModalCloseButton,
    ModalContent,
    ModalOverlay,
    Spacer,
    Stack,
    Text,
} from '@chakra-ui/react';
import { errors } from 'ethers';
import { Field, FieldProps, Formik, validateYupSchema, yupToFormErrors } from 'formik';
import React, { useRef, useState } from 'react';
import * as Yup from 'yup';
import { useBullaToast } from '../../../components/base/toast';
import { OrangeButton } from '../../../components/inputs/buttons';
import { ShadowBox } from '../../../components/layout/cards';
import { SkeletonCard } from '../../../components/layout/loan-cards';
import { BasicWalletAddressField, RecipientField } from '../../../components/modals/create-claim-modal/create-claim-inputs';
import { Feature, PricingBox } from '../../../components/modals/importer-free-pricing-modal';
import { getMaximumWalletsPerPremiumTier, PurchasePremium } from '../../../components/modals/premium-pricing-modal';
import { addressEquality, isValidAddress } from '../../../data-lib/ethereum';
import { FullMembershipInfoDto, useAuthApi } from '../../../hooks/useAuthApi';
import { useMembership } from '../../../hooks/useMembership';
import { useActingWalletAddress } from '../../../hooks/useWalletAddress';
import { apply, BULLA_NETWORK_DISCORD_INVITE, fillToCount, PremiumTier, toDisplayLabel } from '../../../tools/common';
import { enableEditingWalletsInMembership } from '../../../tools/featureFlags';

const walletAddressesSchema = (actingWalletAddress: string) =>
    Yup.object().shape({
        additionalAddresses: Yup.array(
            Yup.string().test(
                'is-valid-address',
                'Invalid wallet address',
                value => value == undefined || value == '' || isValidAddress(value),
            ),
        ).test('unique', 'Cannot add duplicate addresses', (values: (string | undefined)[] | undefined) => {
            if (values == undefined) return true;

            const definedAddresses = values.filter((x): x is string => x !== '' && x !== undefined).map(x => x.toLowerCase());

            return (
                values === undefined ||
                new Set([...definedAddresses, actingWalletAddress.toLowerCase()]).size === definedAddresses.length + 1
            );
        }),
    });

const MembershipSubscriptionPage: React.FC<{
    mainMembership: FullMembershipInfoDto;
    isSaving: boolean;
    onSubmit: (membershipId: string, additionalAddresses: string[]) => Promise<void>;
}> = ({ mainMembership, isSaving, onSubmit }) => {
    const additionalAddresses: (string | undefined)[] = fillToCount(
        mainMembership.additionalAddresses,
        getMaximumWalletsPerPremiumTier(mainMembership.tier) - 1,
    );

    return (
        <Formik
            initialValues={{ additionalAddresses }}
            onSubmit={values =>
                onSubmit(
                    mainMembership.id,
                    values.additionalAddresses.filter((x): x is string => x !== undefined && x !== ''),
                )
            }
            validate={values => {
                try {
                    validateYupSchema(values, walletAddressesSchema(mainMembership.mainAddress), true);
                    return {};
                } catch (err) {
                    return yupToFormErrors(err);
                }
            }}
            validateOnBlur
            enableReinitialize
        >
            {({ values, errors: _errors, touched, setFieldValue, dirty, submitForm, resetForm }) => {
                const errors = Array.isArray(_errors.additionalAddresses)
                    ? fillToCount(_errors.additionalAddresses, values.additionalAddresses.length)
                    : _errors.additionalAddresses !== undefined
                    ? _errors.additionalAddresses
                    : values.additionalAddresses.map((_, i) => _errors.additionalAddresses?.[i]);

                return (
                    <Box>
                        <Heading size="md" color="black" fontWeight={500} pb="1">
                            Wallets
                        </Heading>
                        <Stack spacing={4}>
                            <Text color="gray.600">Update your wallets</Text>
                            <Divider />

                            <RecipientField
                                field={{
                                    name: 'Main wallet',
                                    value: mainMembership.mainAddress,
                                    onBlur: undefined,
                                    onChange: undefined,
                                }}
                                isDisabled={true}
                                setRecipient={() => {}}
                                initialValue={mainMembership.mainAddress}
                                label="Main wallet"
                            />

                            {values.additionalAddresses.map((address, index) => (
                                <Box key={index}>
                                    <Field name={`additionalAddresses.${index}`}>
                                        {({ field }: FieldProps) => (
                                            <BasicWalletAddressField
                                                {...{
                                                    field,
                                                    isDisabled: isSaving,
                                                    selectableWallets: new Set(),
                                                    error: typeof errors == 'string' ? errors : errors[index],
                                                    touched: touched.additionalAddresses,
                                                    setRecipient: apply(setFieldValue, field.name),
                                                    label: `Additional wallet ${index + 1}`,
                                                    required: false,
                                                }}
                                            />
                                        )}
                                    </Field>
                                </Box>
                            ))}

                            <Flex>
                                <OrangeButton
                                    onClick={submitForm}
                                    isDisabled={isSaving || typeof errors == 'string' || errors.some(x => x !== undefined) || !dirty}
                                    isLoading={isSaving}
                                >
                                    Update
                                </OrangeButton>
                                <Spacer />
                            </Flex>
                        </Stack>
                    </Box>
                );
            }}
        </Formik>
    );
};

export const SubscriptionsPage = () => {
    const premiumMembership = useMembership();
    const actingWallet = useActingWalletAddress();
    const userIsPremium = !!premiumMembership;
    const { getFullMembershipInfo, editAdditionalAddresses } = useAuthApi();
    const triggerToast = useBullaToast();
    const [purchaseView, setPurchaseView] = useState<PremiumTier | undefined>();
    const modalContentRef = useRef<HTMLDivElement>(null);
    const [isSaving, setIsSaving] = useState(false);
    const [mainMembership, setMainMembership] = useState<FullMembershipInfoDto | 'loading' | undefined>();

    const onClose = () => {
        setPurchaseView(undefined);
    };

    const onSubmit = React.useCallback((membershipId: string, values: string[]) => {
        setIsSaving(true);
        return editAdditionalAddresses(membershipId, values)
            .catch(e => triggerToast({ title: 'Error saving changes', status: 'error' }))
            .then(_ => getFullMembershipInfo(membershipId))
            .then(setMainMembership)
            .then(() => {
                triggerToast({ title: 'Changes saved successfully!' });
            })
            .finally(() => setIsSaving(false));
    }, []);

    const membershipIds = premiumMembership?.membershipIds ?? [];

    React.useEffect(() => {
        setMainMembership('loading');
        const getMemberships = async () => {
            const _memberships = await Promise.all(membershipIds.map(membershipId => getFullMembershipInfo(membershipId)));
            const memberships = _memberships
                .filter((x): x is FullMembershipInfoDto => x !== undefined)
                .filter(x => addressEquality(x.mainAddress, actingWallet));

            if (memberships.length > 0) setMainMembership(memberships.find(x => x.tier == 'pro') ?? memberships[0]);
            else setMainMembership(undefined);
        };

        if (membershipIds.length !== 0) {
            getMemberships();
        }
    }, [membershipIds]);

    return (
        <Stack spacing="8" w="50%" minW="450px">
            <ShadowBox w="2xl">
                {userIsPremium ? (
                    <HStack spacing={5} p="1">
                        <Stack spacing={'4'}>
                            <Text fontSize={'18px'} fontWeight="700">
                                Your Subscription Status
                            </Text>
                            <Text fontSize={'16px'} fontWeight="600" color="brand.bulla_blue">
                                {premiumMembership.isFreeTrial
                                    ? 'Free trial'
                                    : `Bulla ${toDisplayLabel(
                                          (mainMembership == 'loading' || mainMembership == undefined ? premiumMembership : mainMembership)
                                              .tier,
                                      )}`}
                            </Text>
                        </Stack>
                    </HStack>
                ) : (
                    <HStack spacing={5} p="1">
                        <Stack spacing={'4'} w="100%">
                            <Text fontSize={'20px'} fontWeight="700">
                                Sign up for Bulla Premium.
                            </Text>
                            <PricingBox price={49} />
                            <Stack>
                                <Text fontSize={'14px'}>
                                    What you get with <Text as="b">Bulla Premium:</Text>
                                </Text>
                                <Stack spacing={1} fontWeight={500}>
                                    <Feature feature={'External Transaction imports and categorization'} />
                                    <Feature feature={'Private notes only you can see'} />
                                    <Feature feature={'PDF Creation for receipts or invoices'} />
                                </Stack>

                                <Box pt="1" color="#A3AED0" fontWeight={500}>
                                    <chakra.span>
                                        <chakra.span>Have questions or more support needs? </chakra.span>
                                        <Link
                                            href={BULLA_NETWORK_DISCORD_INVITE}
                                            isExternal
                                            target={'_blank'}
                                            textDecoration={'underline'}
                                            color={'#12525B'}
                                            fontWeight={500}
                                        >
                                            Reach out
                                        </Link>
                                        <chakra.span> to our sales team.</chakra.span>
                                    </chakra.span>
                                </Box>
                            </Stack>
                            <Flex justifyContent="flex-end">
                                <OrangeButton onClick={() => setPurchaseView('basic')}>Pay now</OrangeButton>
                            </Flex>
                        </Stack>
                    </HStack>
                )}
            </ShadowBox>

            {userIsPremium && !premiumMembership.isFreeTrial && (
                <>
                    {mainMembership === 'loading' ? (
                        <SkeletonCard />
                    ) : (
                        !!mainMembership &&
                        enableEditingWalletsInMembership && (
                            <Box py="6">
                                <MembershipSubscriptionPage mainMembership={mainMembership} isSaving={isSaving} onSubmit={onSubmit} />
                            </Box>
                        )
                    )}
                </>
            )}

            <Modal
                isOpen={purchaseView !== undefined}
                onClose={onClose}
                size="xl"
                isCentered
                closeOnOverlayClick={false}
                closeOnEsc={false}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalCloseButton />
                    <PurchasePremium tier={purchaseView ?? 'basic'} onClose={onClose} modalContentRef={modalContentRef} />
                </ModalContent>
            </Modal>
        </Stack>
    );
};

import { BigNumber } from 'ethers';
import React from 'react';
import { getBullaSwapContract, IBullaSwap } from '../data-lib/dto/contract-interfaces';
import { EthAddress, toChecksumAddress } from '../data-lib/ethereum';
import { Multihash } from '../data-lib/multihash';
import { waitForTransaction, TXStatus, TokenInfo, chainIds, TokenDto, TokenVariant } from '../data-lib/networks';
import { useUIState } from '../state/ui-state';
import { MinimalGnosisItemInfo, MultisendCreateOrderTransaction } from './useGnosisMultisend';
import { useWeb3 } from './useWeb3';

export type CreateOrderParams = {
    expiry: BigNumber;
    signerWallet: string;
    signerToken: string;
    signerAmount: BigNumber;
    senderWallet: string;
    senderToken: string;
    senderAmount: BigNumber;
};

export const useBullaSwap = () => {
    const { addPendingTxn } = useUIState();
    const [pending, setPending] = React.useState(false);
    const {
        signer,
        signerProvider,
        connectedNetwork,
        connectedNetworkConfig: { bullaSwap },
    } = useWeb3();

    const createOrder = async (order: CreateOrderParams) => {
        setPending(true);
        try {
            const contract = getBullaSwapContract(bullaSwap!).connect(signer);
            const result = await contract.createOrder(order);
            addPendingTxn(signerProvider, connectedNetwork, result.hash);
            const receipt = await waitForTransaction(connectedNetwork, signerProvider, result.hash);
            return receipt.status == TXStatus.SUCCESS;
        } catch (e) {
            console.error(e);
            return false;
        } finally {
            setPending(false);
        }
    };

    const createOrderInputToMultisendTxDTO = async (
        bullaSwapAddress: EthAddress,
        orderParams: CreateOrderParams,
        offerToken: TokenDto,
    ): Promise<{ transaction: MultisendCreateOrderTransaction }> => {
        return { transaction: buildCreateOrderMultisendTx(bullaSwapAddress, orderParams, offerToken) };
    };

    function buildCreateOrderMultisendTx(
        bullaSwapAddress: EthAddress,
        orderParams: CreateOrderParams,
        offerToken: TokenDto,
    ): MultisendCreateOrderTransaction {
        const itemInfo: MinimalGnosisItemInfo = {
            __type: 'CreateOrder',
            description: 'Bulla Swap Create Order',
            creditor: '',
            debtor: '',
            id: '',
        };

        return {
            label: `Create Swap Order`,
            transactionInput: getCreateOrderTransaction(bullaSwapAddress, orderParams),
            itemInfo,
            interaction: 'Create Swap Order',
            approvalNeeded: {
                amount: orderParams.signerAmount,
                spendingContract: bullaSwapAddress,
            },
            token: offerToken,
        };
    }

    const getCreateOrderTransaction = (_bullaSwapAddress: EthAddress, orderParams: CreateOrderParams) => {
        const bullaSwapAddress = toChecksumAddress(_bullaSwapAddress);
        const value = '0';

        return {
            to: bullaSwapAddress,
            value,
            operation: 0,
            data: IBullaSwap.encodeFunctionData('createOrder', [orderParams]),
        };
    };

    return [
        pending,
        {
            createOrder,
            createOrderInputToMultisendTxDTO,
        },
    ] as const;
};

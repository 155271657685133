import React, { forwardRef } from 'react';
import { IconProps } from '@phosphor-icons/react';

export const SwapCoins = forwardRef<SVGSVGElement, IconProps>(({ size = 24, ...props }, ref) => (
    <svg ref={ref} width={size} height={size} viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <g clipPath="url(#clip0_70_2106)">
            <g clipPath="url(#clip1_70_2106)">
                <path
                    d="M5.04232 4.54159L6.39648 3.18742M6.39648 3.18742L5.04232 1.83325M6.39648 3.18742H5.04232C3.54655 3.18742 2.33398 4.39998 2.33398 5.89575M13.1673 12.6666L11.8132 14.0208M11.8132 14.0208L13.1673 15.3749M11.8132 14.0208H13.1673C14.6631 14.0208 15.8757 12.8082 15.8757 11.3124M7.87864 4.88013C8.32962 3.12794 9.92019 1.83325 11.8132 1.83325C14.0568 1.83325 15.8757 3.6521 15.8757 5.89575C15.8757 7.7887 14.581 9.37926 12.8288 9.83025M10.459 11.3124C10.459 13.5561 8.64014 15.3749 6.39648 15.3749C4.15283 15.3749 2.33398 13.5561 2.33398 11.3124C2.33398 9.06876 4.15283 7.24992 6.39648 7.24992C8.64014 7.24992 10.459 9.06876 10.459 11.3124Z"
                    stroke="currentColor"
                    strokeWidth="1.35417"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
        </g>
        <defs>
            <clipPath id="clip0_70_2106">
                <rect width="16.25" height="16.25" fill="white" transform="translate(0.97998 0.479004)" />
            </clipPath>
            <clipPath id="clip1_70_2106">
                <rect width="16.25" height="16.25" fill="white" transform="translate(0.97998 0.479004)" />
            </clipPath>
        </defs>
    </svg>
));

SwapCoins.displayName = 'SwapCoins';

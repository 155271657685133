import { SearchIcon } from '@chakra-ui/icons';
import { Box, Button, Heading, HStack, Text, VStack } from '@chakra-ui/react';
import { Wallet } from 'phosphor-react';
import React, { useRef, useState } from 'react';
import { NETWORKS } from '../../../data-lib/networks';
import { useWeb3 } from '../../../hooks/useWeb3';
import { ContactNameOrAddress } from '../../base/address-label';
import { buttonSizeProps, OrangeButton } from '../../inputs/buttons';
import { IconBox } from '../common-reporting/progress-steps';
import { RecipientField } from '../create-claim-modal/create-claim-inputs';

interface AddRecipientsCardProps {
    onContinue: (selection: Set<string>) => void;
    onCancel: VoidFunction;
    ownWallets: Set<String>;
}

export const AddRecipientsCard: React.FC<AddRecipientsCardProps> = ({ ownWallets, onContinue, onCancel }) => {
    const { connectedNetwork } = useWeb3();

    const [selectedWallet, setSelectedWallet] = useState<string>('');
    const [selectedWallets, setSelectedWallets] = useState<Set<string>>(new Set());

    const selectWallet = (_wallet: string) => {
        console.log(_wallet);
        setSelectedWallet(_wallet);
        const wallet = _wallet?.toLowerCase() ?? '';
        if (!ownWallets.has(wallet)) setSelectedWallets(prev => prev.add(wallet));
    };

    return (
        <Box maxW="450px" mx="auto" mt="8" textAlign="left" shadow="xl" p="5" borderRadius="md">
            <IconBox icon={Wallet} boxShadow="none" w="fit-content" mb="6" />
            <Heading as="h2" size="md" mb="2">
                Add Recipients
            </Heading>
            <Text mb="6">Select the recipient wallets for which you wish to generate a 1099 form.</Text>

            <Box my={3}>
                <RecipientField
                    initialValue={''}
                    field={{
                        name: 'selectedWallet',
                        value: selectedWallet,
                        onBlur: undefined,
                        onChange: e => {},
                    }}
                    setRecipient={wallet => !!wallet && selectWallet(wallet)}
                    placeholder="Search or paste an address"
                    hasQrScanner={false}
                    inputLeftElement={<SearchIcon color="gray.300" />}
                    isDisabled={false}
                />
            </Box>
            <VStack align="stretch" spacing={1} mb={6} my="3">
                {[...selectedWallets]
                    .map(x => x.toLowerCase())
                    .map(wallet => (
                        <HStack key={wallet} spacing={2} flex={1}>
                            <ContactNameOrAddress chainId={NETWORKS[connectedNetwork].chainId} excludeYouBadge removeParenthesis>
                                {wallet}
                            </ContactNameOrAddress>
                            <Button
                                size="sm"
                                colorScheme="red"
                                variant="ghost"
                                onClick={() => setSelectedWallets(new Set([...selectedWallets].filter(w => w !== wallet)))}
                            >
                                Remove
                            </Button>
                        </HStack>
                    ))}
            </VStack>

            <HStack justify="space-between" w="100%">
                <Button variant="outline" flex={1} {...buttonSizeProps} onClick={onCancel}>
                    Cancel
                </Button>
                <OrangeButton flex={1} isDisabled={selectedWallets.size === 0} onClick={() => onContinue(selectedWallets)}>
                    Continue
                </OrangeButton>
            </HStack>
        </Box>
    );
};

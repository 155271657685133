import React, { useMemo } from 'react';
import { FinancedClaimInfo, FrendLendOffer } from '../../../data-lib/data-model';
import { addressEquality } from '../../../data-lib/ethereum';
import { isFinancingAccepted } from '../../../data-lib/helpers';
import { useGlobalUserData } from '../../../hooks/useUserData';
import { useActingWalletAddress } from '../../../hooks/useWalletAddress';
import { LoanListTemplate } from './loan-list-template';
import { LoanOffersTemplate } from './loan-offers-template';

interface LoanTableProps {
    type: 'payables' | 'receivables' | 'offers';
}

export const LoanTable: React.FC<LoanTableProps> = ({ type }) => {
    const { payables, receivables, frendLends } = useGlobalUserData('exclude-originating-claims');
    const actingWallet = useActingWalletAddress();

    const filteredLoans = useMemo(() => {
        const items = type === 'payables' ? payables : receivables;
        return items.filter(
            (item): item is FinancedClaimInfo =>
                item.__type == 'Claim' && (item.claimStatus == 'Repaying' || item.claimStatus == 'Paid') && isFinancingAccepted(item),
        );
    }, [type, JSON.stringify(payables), JSON.stringify(receivables)]);

    const frendLendOffersReceived = useMemo(
        () => frendLends.filter((lend): lend is FrendLendOffer => addressEquality(lend.debtor, actingWallet) && lend.status === 'Offered'),
        [JSON.stringify(frendLends)],
    );

    const frendLendOffersSent = useMemo(
        () =>
            frendLends.filter((lend): lend is FrendLendOffer => addressEquality(lend.creditor, actingWallet) && lend.status === 'Offered'),
        [JSON.stringify(frendLends)],
    );

    return type === 'offers' ? (
        <LoanOffersTemplate offers={[...frendLendOffersSent, ...frendLendOffersReceived]} />
    ) : (
        <LoanListTemplate loans={filteredLoans} />
    );
};

import { TokenDto } from '../../../data-lib/networks';
import { addDaysToToday } from '../../../data-lib/helpers';
import { ClaimType } from '../../../data-lib/data-model';
import { BullaItemAttachment } from '../create-claim-modal/create-claim-modal';
import { Optional } from '../../../tools/types';

export type PaymentDetails = {
    walletAddress: string;
    name: string;
    emailAddress: string;
    confirmationEmailAddress: string;
    token?: TokenDto;
    amount?: string;
    description?: string;
    tags?: string[];
    emailMessage?: string;
    attachment?: BullaItemAttachment;
};

export type InvoiceDetails = PaymentDetails & { dueDate?: Date };

export type CompleteDetails<T extends PaymentDetails | InvoiceDetails> = Optional<
    Required<T>,
    T extends PaymentDetails ? 'attachment' : 'dueDate' | 'attachment'
>;

export const emptyPaymentDetails: PaymentDetails = {
    walletAddress: '',
    name: '',
    emailAddress: '',
    confirmationEmailAddress: '',
};

export const emptyInvoiceDetails: InvoiceDetails = { ...emptyPaymentDetails, dueDate: undefined };

export type PaymentTime = 'now' | 'later';
export type BatchMetadata = {
    batchId: string;
    claimType: ClaimType;
    createdOn: string;
};

export type DefaultBatchClaimValues = {
    defaultAmount: string;
    defaultDescription: string;
    defaultDueDate?: Date;
    defaultToken: TokenDto;
    defaultAccountTags: string[];
    defaultEmailMessage: string;
    generatePDF: boolean;
};

export type SelectPaymentDateState = {
    kind: 'SelectPaymentType';
    paymentTime?: PaymentTime;
    defaultValues?: DefaultBatchClaimValues;
    claimDetails?: PaymentDetails[] | InvoiceDetails[];
    batchIndex?: number;
    saveToContacts?: boolean;
} & BatchMetadata;

export type DefaultBatchValuesState = {
    kind: 'DefaultValueSelection';
    defaultValues: DefaultBatchClaimValues;
    claimDetails?: PaymentDetails[] | InvoiceDetails[];
    batchIndex?: number;
    saveToContacts?: boolean;
} & BatchMetadata;

export type ClaimConfigurationState = {
    kind: 'ClaimConfiguration';
    defaultValues: DefaultBatchClaimValues;
    claimDetails: PaymentDetails[] | InvoiceDetails[];
    batchIndex?: number;
    saveToContacts?: boolean;
} & BatchMetadata;

export type ReviewState = {
    kind: 'ReviewState';
    defaultValues: DefaultBatchClaimValues;
    claimDetails: PaymentDetails[] | InvoiceDetails[];
    batchIndex: number;
    saveToContacts?: boolean;
} & BatchMetadata;

export type SuccessState = {
    kind: 'SuccessState';
    defaultValues: DefaultBatchClaimValues;
    claimDetails: PaymentDetails[] | InvoiceDetails[];
} & BatchMetadata;

export type StartFromNewOrDraftState = {
    kind: 'StartFromNewOrDraft';
    drafts: BatchCreationState[];
    selectedItem: 'create-new' | string | undefined;
};

export type BatchCreationState = SelectPaymentDateState | DefaultBatchValuesState | ClaimConfigurationState | ReviewState | SuccessState;

export type BatchWizardState = BatchCreationState | StartFromNewOrDraftState;

export const isBatchCreationState = (wizardState: BatchWizardState): wizardState is BatchCreationState =>
    (wizardState as BatchCreationState)?.batchId !== undefined;

export type DefaultValues = {
    defaultAmount: string;
    defaultDescription: string;
    defaultDueDate: Date;
    defaultToken: TokenDto | undefined;
    defaultAccountTags: string[];
    defaultEmailMessage: string;
    generatePDF: boolean;
};

export const initialDefaultValues: DefaultValues = {
    defaultAmount: '',
    defaultDescription: '',
    defaultDueDate: addDaysToToday(30),
    defaultToken: undefined,
    defaultAccountTags: [],
    defaultEmailMessage: '',
    generatePDF: false,
};

export const detailsWithDefaultValues =
    (defaultValues: DefaultBatchClaimValues) =>
    (itemDetails: InvoiceDetails): CompleteDetails<PaymentDetails> & { dueDate?: Date } => {
        const paymentDetails = {
            walletAddress: itemDetails.walletAddress,
            name: itemDetails.name,
            emailAddress: itemDetails.emailAddress,
            confirmationEmailAddress: itemDetails.confirmationEmailAddress,
            token: itemDetails.token ?? defaultValues.defaultToken,
            amount: itemDetails.amount ?? defaultValues.defaultAmount,
            description: itemDetails.description ?? defaultValues.defaultDescription,
            tags: itemDetails.tags ?? defaultValues.defaultAccountTags,
            emailMessage: itemDetails.emailMessage ?? defaultValues.defaultEmailMessage,
            attachment: itemDetails.attachment ?? (defaultValues.generatePDF ? ('generate' as const) : undefined),
        };

        return !!defaultValues.defaultDueDate
            ? { ...paymentDetails, dueDate: itemDetails.dueDate ?? defaultValues.defaultDueDate }
            : paymentDetails;
    };

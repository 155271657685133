import { IconProps } from 'phosphor-react';

export const homeDashboardTabs = [
    'Payments',
    'NetFlows',
    'NftTransfers',
    'Payables',
    'Receivables',
    'LoanPayables',
    'LoanReceivables',
    'LoanOffers',
    'Swaps',
] as const;

export type HomeDashboardTab = typeof homeDashboardTabs[number];

export type QuickActionProps = {
    text: string;
    Icon: React.ForwardRefExoticComponent<IconProps & React.RefAttributes<SVGSVGElement>>;
    menuItems?: { label: string; action: () => void }[];
    onClick?: VoidFunction;
    isDisabled?: boolean;
    blimpCount?: number;
};

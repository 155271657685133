import { AddIcon, ExternalLinkIcon } from '@chakra-ui/icons';
import { Box, Collapse, Grid, GridItem, HStack, IconButton, Image, Spacer, Text, Tooltip, useDisclosure } from '@chakra-ui/react';
import { FormikErrors } from 'formik';
import { CheckCircle, Trash, Warning } from 'phosphor-react';
import { stringify } from 'querystring';
import React, { Ref, RefObject, useEffect } from 'react';
import BullaBatchLogo from 'url:../../../assets/bulla-batch.svg';
import { ClaimType } from '../../../data-lib/data-model';
import { addressEquality } from '../../../data-lib/ethereum';
import { TokenDto, TokenInfo } from '../../../data-lib/networks';
import { useCompanyDetailsRepo } from '../../../hooks/useCompanyDetailsRepo';
import { useActingWalletAddress } from '../../../hooks/useWalletAddress';
import { toDateDisplay } from '../../../tools/common';
import { openIpfsLink } from '../../../tools/ipfs';
import { AccountTagViewer } from '../../inputs/account-tag-input';
import { FileNameLabel } from '../../inputs/attachment-input';
import {
    AccountTagField,
    AttachmentField,
    ClaimAmountField,
    ClaimDescriptionField,
    DueByField,
    EmailField,
    FormiklessField,
    NameField,
    PreviewGeneratedAttachmentLink,
    TokenSelector,
    WalletAddressField,
} from '../create-claim-modal/create-claim-inputs';
import { isUploadedFile } from '../create-claim-modal/create-claim-modal';
import { CompleteDetails, InvoiceDetails, PaymentDetails } from './batch-state';

type BullaItemRowProps = {
    columnString: string;
    error: any | undefined;
    touched: any | undefined;
    transactionPending: boolean;
    getQualifiedFieldName: (fieldName: string) => string;
    hasDueDate: boolean;
    direction: ClaimType;
    setFieldValue: (fieldName: keyof InvoiceDetails) => (value: any) => void;
    value: CompleteDetails<PaymentDetails> & { dueDate?: Date };
    setStatus: (status?: any) => void;
    deleteRow: VoidFunction;
    openCloseOverride: 'Open' | 'Close';
    onDuplicate: () => void;
    dropdownPortalRef?: RefObject<HTMLDivElement>;
};

const drawerColumns = '2fr 2fr 2fr 2fr 2fr';
const drawerLabelProps = { fontWeight: 500, fontSize: '12px', color: '#2D3748' };

const getErrorStrings = (error: string | string[] | FormikErrors<CompleteDetails<PaymentDetails>> | undefined): string[] =>
    error === undefined
        ? []
        : Array.isArray(error)
        ? error
        : typeof error === 'string'
        ? [error]
        : Object.values(error)
              .map(getErrorStrings)
              .reduce<string[]>((acc, next) => [...acc, ...next], []);

export const BullaItemRow = React.memo(
    ({
        columnString,
        transactionPending,
        error,
        touched,
        setFieldValue,
        hasDueDate,
        direction,
        value,
        getQualifiedFieldName,
        setStatus,
        deleteRow,
        openCloseOverride,
        onDuplicate,
        dropdownPortalRef,
    }: BullaItemRowProps) => {
        const { onToggle, isOpen, onOpen, onClose } = useDisclosure();
        const errorStrings = getErrorStrings(error);
        const createFormiklessField = (fieldName: keyof InvoiceDetails, excludeOnChange: boolean = false): FormiklessField => ({
            name: fieldName,
            onChange: excludeOnChange ? undefined : (e: React.ChangeEvent<any>) => setFieldValue(fieldName)(e.target.value),
            value: value[fieldName],
        });

        useEffect(() => {
            openCloseOverride == 'Open' ? onOpen() : onClose();
        }, [openCloseOverride]);

        const border = '1px solid #E2E8F0';
        const drawer = (
            <Collapse in={isOpen}>
                <Box bgColor="rgba(0, 0, 0, 0.02)" borderTop={border} borderBottom={border} mt="3" p="4">
                    <Grid templateColumns={drawerColumns} columnGap={3}>
                        <GridItem key="description">
                            <Text {...drawerLabelProps}>Description</Text>
                        </GridItem>
                        <GridItem key="tag">
                            <Text {...drawerLabelProps}>Categories (optional)</Text>
                        </GridItem>
                        <GridItem key="EmailAddress">
                            <Text {...drawerLabelProps}>Email (optional)</Text>
                        </GridItem>
                        <GridItem key="ConfirmationEmailAddress">
                            <Text {...drawerLabelProps}>Confirmation Email (optional)</Text>
                        </GridItem>
                        <GridItem key="FileUpload">
                            <Text {...drawerLabelProps}>File Upload (optional)</Text>
                        </GridItem>
                        <GridItem key="descriptionV">
                            <ClaimDescriptionField
                                {...{
                                    field: createFormiklessField('description'),
                                    isDisabled: transactionPending,
                                    error: error?.description,
                                    touched: touched?.description,
                                    disableErrorLabels: true,
                                }}
                            />
                        </GridItem>
                        <GridItem key="tagV" rowSpan={2}>
                            <AccountTagField
                                {...{
                                    field: createFormiklessField('tags'),
                                    isDisabled: transactionPending,
                                    error: error?.tags,
                                    touched: touched?.tags,
                                    setTags: setFieldValue('tags'),
                                    setStatus,
                                    creatingExpense: false,
                                    dropdownModalRef: dropdownPortalRef,
                                }}
                            />
                        </GridItem>
                        <GridItem key="EmailAddressV">
                            <EmailField
                                {...{
                                    field: createFormiklessField('emailAddress'),
                                    isDisabled: transactionPending,
                                    error: error?.emailAddress,
                                    touched: touched?.emailAddress,
                                    disableErrorLabels: true,
                                }}
                            />
                        </GridItem>
                        <GridItem key="ConfirmationEmailAddressV">
                            <EmailField
                                {...{
                                    field: createFormiklessField('confirmationEmailAddress'),
                                    isDisabled: transactionPending,
                                    error: error?.confirmationEmailAddress,
                                    touched: touched?.confirmationEmailAddress,
                                    disableErrorLabels: true,
                                }}
                            />
                        </GridItem>
                        <GridItem key="FileUploadV">
                            <AttachmentField
                                field={createFormiklessField('attachment')}
                                setAttachment={setFieldValue('attachment')}
                                attachment={value.attachment}
                                amount={value.amount}
                                description={value.description}
                                recipient={value.walletAddress}
                                tokenSymbol={value.token.symbol}
                                type={direction}
                                transactionPending={transactionPending}
                                uploadToIpfsOnAccept
                            />
                        </GridItem>
                    </Grid>
                </Box>
            </Collapse>
        );

        return (
            <Box p="0">
                <Grid px="4" templateColumns={columnString} pt="3">
                    <GridItem key={getQualifiedFieldName('icon')} colSpan={1} display="table" mr="4">
                        <Box p="0" verticalAlign={'middle'} display="table-cell">
                            {errorStrings.length == 0 ? (
                                <CheckCircle size="20px" color={'green'} />
                            ) : (
                                <Tooltip
                                    placement="left"
                                    label={
                                        <>
                                            {errorStrings.map(x => (
                                                <Text key={x}>{x}</Text>
                                            ))}
                                        </>
                                    }
                                >
                                    <Warning size="20px" color={'red'} />
                                </Tooltip>
                            )}
                        </Box>
                    </GridItem>
                    <GridItem key={getQualifiedFieldName('name')} colSpan={1} mr="2">
                        <NameField
                            {...{
                                field: createFormiklessField('name'),
                                isDisabled: transactionPending,
                                error: error?.name,
                                touched: touched?.name,
                                required: false,
                            }}
                        />
                    </GridItem>
                    <GridItem key={getQualifiedFieldName('walletAddress')} colSpan={1} mr="2">
                        <WalletAddressField
                            {...{
                                field: createFormiklessField('walletAddress'),
                                isDisabled: transactionPending,
                                error: error?.walletAddress,
                                touched: touched?.walletAddress,
                                required: false,
                            }}
                        />
                    </GridItem>
                    {hasDueDate && (
                        <GridItem key={getQualifiedFieldName('dueDate')} colSpan={1} mr="2">
                            <DueByField
                                {...{
                                    field: createFormiklessField('dueDate'),
                                    isDisabled: transactionPending,
                                    error: error?.dueDate,
                                    touched: touched?.dueDate,
                                    setDueBy: setFieldValue('dueDate'),
                                    required: true,
                                }}
                            />
                        </GridItem>
                    )}
                    <GridItem key={getQualifiedFieldName('amount')} colSpan={1} mr="2">
                        <ClaimAmountField
                            {...{
                                claimType: 'Invoice',
                                field: createFormiklessField('amount'),
                                isDisabled: transactionPending,
                                error: error?.amount,
                                touched: touched?.amount,
                                setAmount: setFieldValue('amount'),
                                setToken: setFieldValue('token'),
                                amount: value.amount,
                                token: value.token,
                                required: true,
                                disableErrorLabels: true,
                                includeNativeToken: direction === 'Payment' && !hasDueDate,
                            }}
                        />
                    </GridItem>
                    <GridItem key={getQualifiedFieldName('details')} colSpan={1} mr="2" display="table">
                        <Text
                            fontWeight={600}
                            fontSize="12px"
                            color={'#0054FD'}
                            verticalAlign={'middle'}
                            display="table-cell"
                            _hover={{ cursor: 'pointer' }}
                            onClick={onToggle}
                        >
                            {isOpen ? 'Close' : 'Details'}
                        </Text>
                    </GridItem>
                    <GridItem key={getQualifiedFieldName('duplicate-trash')} colSpan={1} display="table">
                        <Box
                            pr="2"
                            pb="1"
                            verticalAlign={'middle'}
                            display="table-cell"
                            color={'green'}
                            _hover={{ cursor: 'pointer' }}
                            onClick={onDuplicate}
                        >
                            <AddIcon />
                        </Box>
                        <Box verticalAlign={'middle'} display="table-cell" color={'red'} _hover={{ cursor: 'pointer' }} onClick={deleteRow}>
                            <Trash size="20px" weight="bold" />
                        </Box>
                    </GridItem>
                </Grid>
                {drawer}
            </Box>
        );
    },
    ({ dropdownPortalRef, ...prev }, { dropdownPortalRef: _, ...next }) => JSON.stringify(prev) == JSON.stringify(next),
);

type ReadOnlyBullaItemRowProps = {
    columnString: string;
    hasDueDate: boolean;
    value: CompleteDetails<PaymentDetails> & { dueDate?: Date };
    openCloseOverride: 'Open' | 'Close';
};

const openWithIpfs = (hash: string) => () => openIpfsLink(hash);

type BullaFeeRowProps = {
    columnString: string;
    amount: string;
    usdAmount: number;
    tokenSymbol: string;
    transactionPending: boolean;
    onTokenSelected: (token: TokenDto) => void;
    selectableTokens: TokenInfo[];
};

export const BullaFeeRow = ({
    columnString,
    transactionPending,
    onTokenSelected,
    selectableTokens,
    amount,
    tokenSymbol,
    usdAmount,
}: BullaFeeRowProps) => {
    return (
        <Box p="4" bg="#FFF3DC" borderRadius={'7px'} border={'1px solid #FFD98B'}>
            <Grid px="4" templateColumns={columnString} templateRows="fit-content">
                <GridItem key={'name'} colSpan={1} mr="2" overflow={'hidden'}>
                    <HStack h="100%">
                        <Image src={BullaBatchLogo} />
                        <Text textOverflow={'ellipsis'} noOfLines={1} h="fit-content">
                            Bulla Batch Fees
                        </Text>
                    </HStack>
                </GridItem>
                <GridItem key={'skip'} colSpan={1} mr="2"></GridItem>
                <GridItem key={'usdAmount'} colSpan={3} mr="2">
                    <HStack spacing={3}>
                        <Spacer />
                        <TokenSelector
                            isDisabled={transactionPending}
                            onTokenSelected={onTokenSelected}
                            selectableTokens={selectableTokens}
                            selectedTokenSymbol={tokenSymbol}
                            bg="white"
                        />
                        <Text>
                            {amount} {tokenSymbol}
                        </Text>
                    </HStack>
                </GridItem>
            </Grid>
        </Box>
    );
};

export const ReadOnlyBullaItemRow = ({ columnString, hasDueDate, value, openCloseOverride }: ReadOnlyBullaItemRowProps) => {
    const actingWallet = useActingWalletAddress();
    const { getAttachmentGenerationLink } = useCompanyDetailsRepo();
    const { onToggle, isOpen, onOpen, onClose } = useDisclosure();
    const drawerColumns = '2fr 2fr 2fr 2fr 3fr';
    const border = '1px solid #E2E8F0';

    useEffect(() => {
        openCloseOverride == 'Open' ? onOpen() : onClose();
    }, [openCloseOverride]);

    const drawer = (
        <Collapse in={isOpen}>
            <Box bgColor="rgba(0, 0, 0, 0.02)" borderTop={border} borderBottom={border} mt="3" p="4">
                <Grid templateColumns={drawerColumns}>
                    <GridItem key="file">
                        <Text {...drawerLabelProps}>File (optional)</Text>
                    </GridItem>
                    <GridItem key="tag">
                        <Text {...drawerLabelProps}>Categories (optional)</Text>
                    </GridItem>
                    <GridItem key="EmailAddress">
                        <Text {...drawerLabelProps}>Email (optional)</Text>
                    </GridItem>
                    <GridItem key="confirmationEmailAddress">
                        <Text {...drawerLabelProps}>Confirmation Email (optional)</Text>
                    </GridItem>
                    <GridItem key="Message">
                        <Text {...drawerLabelProps}>Message (optional)</Text>
                    </GridItem>
                </Grid>
                <Grid templateColumns={drawerColumns}>
                    <GridItem key="file" mr="2" overflow={'hidden'}>
                        {isUploadedFile(value.attachment) ? (
                            <HStack>
                                <FileNameLabel filename={value.attachment.fileName} />
                                <IconButton
                                    mx="0"
                                    aria-label="open file"
                                    onClick={openWithIpfs(value.attachment.ipfsHash)}
                                    icon={<ExternalLinkIcon />}
                                    colorScheme="gray"
                                    size="sm"
                                />
                            </HStack>
                        ) : value.attachment === 'generate' ? (
                            <HStack>
                                <Text fontWeight={'bold'}>Generated File</Text>
                                <PreviewGeneratedAttachmentLink
                                    onClick={() =>
                                        window.open(
                                            getAttachmentGenerationLink(
                                                addressEquality(actingWallet, value.walletAddress) ? 'Payment' : 'Invoice',
                                                value.walletAddress,
                                                value.amount,
                                                value.token.symbol,
                                                value.description,
                                            ),
                                            '_blank',
                                        )
                                    }
                                />
                            </HStack>
                        ) : null}
                    </GridItem>
                    <GridItem key="tags" mr="2" overflow={'hidden'}>
                        <AccountTagViewer
                            tags={value.tags}
                            removeTag={() => {}}
                            addTag={() => {}}
                            creatingExpense={false}
                            setEditing={{
                                on: () => {},
                                off: () => {},
                            }}
                            isDisabled={false}
                            fieldName={'tags'}
                            editState={'viewing only'}
                        />
                    </GridItem>
                    <GridItem key="EmailAddress" mr="2" overflow={'hidden'}>
                        <Text textOverflow={'ellipsis'} noOfLines={1}>
                            {value.emailAddress}
                        </Text>
                    </GridItem>
                    <GridItem key="ConfirmationEmailAddress" mr="2" overflow={'hidden'}>
                        <Text textOverflow={'ellipsis'} noOfLines={1}>
                            {value.confirmationEmailAddress}
                        </Text>
                    </GridItem>
                    <GridItem key="Message" mr="2" overflow={'hidden'}>
                        <Text textOverflow={'ellipsis'} noOfLines={4}>
                            {value.emailMessage}
                        </Text>
                    </GridItem>
                </Grid>
            </Box>
        </Collapse>
    );

    return (
        <Box p="0">
            <Grid px="4" templateColumns={columnString} pt="3">
                <GridItem key={'name'} colSpan={1} mr="2" overflow={'hidden'}>
                    <Text textOverflow={'ellipsis'} noOfLines={1}>
                        {value.name}
                    </Text>
                </GridItem>
                <GridItem key={'walletAddress'} colSpan={1} mr="2">
                    <Text>{value.walletAddress}</Text>
                </GridItem>
                {hasDueDate && (
                    <GridItem key={'dueDate'} colSpan={1} mr="2">
                        <Text>{toDateDisplay(value.dueDate!)}</Text>
                    </GridItem>
                )}
                <GridItem key={'amount'} colSpan={1} mr="2">
                    <Text textOverflow={'ellipsis'} noOfLines={1}>
                        {value.amount} {value.token.symbol}
                    </Text>
                </GridItem>
                <GridItem key={'description'} colSpan={1} mr="2" overflow={'hidden'}>
                    <Text textOverflow={'ellipsis'} noOfLines={1}>
                        {value.description}
                    </Text>
                </GridItem>
                <GridItem key={'details'} colSpan={1} mr="2" display="table">
                    <Text
                        fontWeight={600}
                        fontSize="12px"
                        color={'#0054FD'}
                        verticalAlign={'middle'}
                        display="table-cell"
                        _hover={{ cursor: 'pointer' }}
                        onClick={onToggle}
                    >
                        {isOpen ? 'Close' : 'See Details'}
                    </Text>
                </GridItem>
            </Grid>
            {drawer}
        </Box>
    );
};

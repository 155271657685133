import { ChevronDownIcon, ChevronRightIcon } from '@chakra-ui/icons';
import {
    Alert,
    AlertIcon,
    Button,
    Flex,
    HStack,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalOverlay,
    Progress,
    Spacer,
    Text,
    useDisclosure,
} from '@chakra-ui/react';
import { Parser, Row } from '@gregoranders/csv';
import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { OrangeButton, SecondaryButton } from '../inputs/buttons';
import { ListItemProps, ListViewCard, ListViewCardProps } from '../layout/cards';
import { CloseModalButton } from './common';
import { ExportToCsv } from 'export-to-csv';
import { useExtendedContacts } from '../../hooks/useExtendedContacts';

const FILE_SIZE_MB = 100 * 1000 * 1024;

export type OverridenFields<TParsedEntity> = { fields?: (keyof TParsedEntity)[] };
export type ErrorFields<TParsedEntity> = { errorFields: (keyof TParsedEntity)[] };

export type ParsedContent<TParsedEntity> = {
    successes: (TParsedEntity & OverridenFields<TParsedEntity>)[];
    failures: (TParsedEntity & ErrorFields<TParsedEntity>)[];
};

export type CsvParseError = 'invalid-format';
export type CsvParseResult = readonly Row[] | CsvParseError;
export type ImportTemplate =
    | {
          Name: string;
          'Wallet address': string;
          'Email address': string;
          Groups: string;
      }[]
    | {
          WALLET_ADDRESS: string;
          NAME: string;
          EMAIL_ADDRESS: string;
          CONFIRMATION_EMAIL_ADDRESS: string;
          TOKEN_ADDRESS: string;
          TOKEN_AMOUNT: string;
          DESCRIPTION: string;
          CATEGORY: string;
      }[];

type ImportCsvModalProps<TParsedEntity, TParseError extends string> = {
    triggerElement: (onOpen: () => void) => React.ReactNode;
    parseFromRowsAsync: (rows: readonly Row[]) => Promise<ParsedContent<TParsedEntity> | TParseError>;
    getLabelForError: (error: TParseError | CsvParseError) => string;
    getDisplayListItemsForParsedContent: (content: ParsedContent<TParsedEntity>['successes'][number]) => ListItemProps;
    getDisplayListItemsForError: (content: ParsedContent<TParsedEntity>['failures'][number]) => ListItemProps;
    onSubmitAsync: (successes: ParsedContent<TParsedEntity>['successes']) => Promise<void>;
    preuploadInfoBox: React.ReactElement;
    headers: ListViewCardProps['headers'];
    entityName: string;
    importTemplate: ImportTemplate;
    fileName: string;
};

const ErrorView = <TParsedEntity,>({
    errors,
    isOpen,
    onToggle,
    getDisplayListItemsForError,
    headers,
    entityName,
}: {
    errors: ParsedContent<TParsedEntity>['failures'];
    isOpen: boolean;
    onToggle: VoidFunction;
    getDisplayListItemsForError: (content: ParsedContent<TParsedEntity>['failures'][number]) => ListItemProps;
    headers: ListViewCardProps['headers'];
    entityName: string;
}) => {
    const count = errors.length;

    return (
        <>
            <Alert status="error" my="8" onClick={onToggle} _hover={{ cursor: 'pointer' }}>
                <AlertIcon />
                {count} {entityName.toLowerCase()}
                {count == 1 ? '' : 's'} could not be imported.
                <Spacer />
                {isOpen ? <ChevronDownIcon /> : <ChevronRightIcon />}
            </Alert>
            {isOpen && <ListViewCard headers={headers} flexHeight={true} displayedListItems={errors.map(getDisplayListItemsForError)} />}
        </>
    );
};

async function getFileTextAsync(file: File): Promise<CsvParseError | string> {
    try {
        return await file.text();
    } catch (e: any) {
        return 'invalid-format';
    }
}

function parseCsv(fileContent: string): CsvParseResult {
    try {
        return new Parser().parse(fileContent).map(row => row.map(col => col.trim()));
    } catch (e: any) {
        return 'invalid-format';
    }
}

export const ImportCsvModal = <TParsedEntity, TParseError extends string>({
    triggerElement,
    parseFromRowsAsync,
    getLabelForError,
    getDisplayListItemsForParsedContent,
    getDisplayListItemsForError,
    onSubmitAsync,
    preuploadInfoBox,
    headers,
    entityName,
    importTemplate,
    fileName,
}: ImportCsvModalProps<TParsedEntity, TParseError>) => {
    const [state, setState] = useState<ParsedContent<TParsedEntity> | 'init' | 'uploading' | TParseError | CsvParseError>('init');
    const isContactsImport = headers.length === 3; // ['Name', 'Wallet Address', 'Email Address']

    const { isOpen, onOpen, onClose } = useDisclosure();
    const contactsContext = useExtendedContacts();
    const [inProgress, setInProgress] = useState(false);
    const { isOpen: isErrorOpen, onToggle } = useDisclosure({ defaultIsOpen: false });

    const onDrop = async ([acceptedFile]: File[]) => {
        setState('uploading');
        const fileContent = await getFileTextAsync(acceptedFile);
        const rows = fileContent == 'invalid-format' ? 'invalid-format' : await parseCsv(fileContent);
        const parsingState = rows == 'invalid-format' ? 'invalid-format' : await parseFromRowsAsync(rows);
        setState(parsingState);
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        multiple: false,
        maxSize: FILE_SIZE_MB,
    });

    const uploading = state == 'uploading';
    const uploaded = !!state && typeof state !== 'string';
    const isError = !uploaded && !uploading && state != 'init';

    function wipeAndClose() {
        onClose();
        setInProgress(false);
        setState('init');
    }

    const DropFileZone = (
        <HStack w="fit-content">
            <Text>Drag and drop here or</Text>
            <Button variant="link" color={'scheme.accent_dark'} textStyle="textButton" fontWeight="500">
                browse
            </Button>
            <input {...getInputProps()} />
        </HStack>
    );

    const ProgressBar = <Progress colorScheme="green" size="sm" isIndeterminate borderRadius="md" mr="2" h="6" />;

    const DragAndDropBox = (
        <Flex
            _disabled={{ color: 'gray.300' }}
            p="10"
            mt="10"
            w="100%"
            h="100%"
            border={`${isDragActive ? '2px' : '1px'} dashed #C4C0BC`}
            transition="all .2s"
            borderRadius="8"
            flexDirection={'column'}
            align={'center'}
            {...getRootProps()}
        >
            <Text color="icon_dark" textStyle="labelLg" pb="6">
                {uploading ? 'File uploading' : 'CSV File Upload'}
            </Text>
            {uploading ? ProgressBar : DropFileZone}
            {isError && (
                <Text color="red.500" mt="6">
                    Failed parsing csv file. {getLabelForError(state)}
                </Text>
            )}
        </Flex>
    );

    const BeforeUploadView = (
        <>
            {DragAndDropBox}
            {preuploadInfoBox}
        </>
    );

    const AfterUploadView = uploaded && (
        <>
            <Flex _disabled={{ color: 'gray.300' }} flexDirection="column" p="4" my="4" w="100%" h="100%" borderRadius="8" align={'center'}>
                <Text color="icon_dark" textStyle="labelLg" pb="6">
                    Upload Complete
                </Text>
                <Progress colorScheme="green" size="sm" value={100} borderRadius="md" mr="2" />
            </Flex>
            <Flex p="0" h="40vh" direction="column">
                {!isErrorOpen && (
                    <ListViewCard
                        headers={headers}
                        flexHeight={true}
                        displayedListItems={state.successes.map(getDisplayListItemsForParsedContent)}
                    />
                )}

                {state.failures.length > 0 && (
                    <ErrorView
                        errors={state.failures}
                        isOpen={isErrorOpen}
                        onToggle={onToggle}
                        getDisplayListItemsForError={getDisplayListItemsForError}
                        headers={headers}
                        entityName={entityName}
                    />
                )}
            </Flex>
        </>
    );

    return (
        <>
            {triggerElement(onOpen)}
            <Modal
                isCentered
                isOpen={isOpen}
                onClose={wipeAndClose}
                motionPreset="slideInBottom"
                size="6xl"
                closeOnEsc
                scrollBehavior="inside"
            >
                <ModalOverlay />
                <ModalContent py="4" px="2" maxH="80vh">
                    <CloseModalButton onClose={wipeAndClose} />
                    <ModalBody pb={6} pt={4} h="100%">
                        <Text color="icon_dark" textStyle="labelLg">
                            Import {entityName.toLowerCase()}s
                        </Text>
                        {uploaded ? AfterUploadView : BeforeUploadView}
                    </ModalBody>
                    <ModalFooter>
                        <Spacer />
                        <Flex w="full" flexDirection="row" justifyContent={'space-between'}>
                            <SecondaryButton
                                onClick={() => {
                                    const data = importTemplate;
                                    const options = {
                                        filename: fileName,
                                        fieldSeparator: ',',
                                        quoteStrings: '"',
                                        decimalSeparator: '.',
                                        showLabels: true,
                                        showTitle: false,
                                        title: 'My CSV',
                                        useTextFile: false,
                                        useBom: true,
                                        useKeysAsHeaders: true,
                                    };

                                    const csvExporter = new ExportToCsv(options);

                                    csvExporter.generateCsv(data);
                                }}
                            >
                                Download Template
                            </SecondaryButton>
                            <OrangeButton
                                type="submit"
                                isLoading={inProgress}
                                isDisabled={!uploaded || state.successes.length == 0}
                                onClick={async _ => {
                                    setInProgress(true);
                                    if (!uploaded) return;
                                    await onSubmitAsync(state.successes).then(_ => wipeAndClose());
                                }}
                            >
                                Save
                            </OrangeButton>
                        </Flex>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};

import { ApolloClient, gql, InMemoryCache } from '@apollo/client';
import { BigNumber } from 'ethers';
import React from 'react';
import { BullaFactoringFeesAndAmounts } from '../components/modals/item-details-modal/item-details-components';
import { ClaimInfo } from '../data-lib/data-model';
import { getPoolEventInfo } from '../data-lib/data-transforms';
import {
    getBullaClaimContract,
    getBullaFactoringContractV2,
    IBullaFactoringV1,
    getBullaFactoringContractV1,
    IBullaFactoringV2,
    I_IERC721,
} from '../data-lib/dto/contract-interfaces';
import { PoolEvent } from '../data-lib/dto/mapped-event-types';
import { DepositMadeEvent__graphql, SharesRedeemedEvent__graphql } from '../data-lib/graphql/graph-domain';
import { mapGraphEventToEvent } from '../data-lib/graphql/graphql-dto';
import { FACTORING_EVENTS_QUERY } from '../data-lib/graphql/userQuery';
import { Multihash } from '../data-lib/multihash';
import {
    ChainId,
    FactoringConfig,
    FactoringConfigVersion,
    NETWORKS,
    TokenDto,
    TokenInfo,
    TXStatus,
    waitForTransaction,
} from '../data-lib/networks';
import { useUIState } from '../state/ui-state';
import { addressEquality, EthAddress, toChecksumAddress } from './../data-lib/ethereum';
import {
    MinimalGnosisItemInfo,
    MultisendApproveInvoiceTransaction,
    MultisendDepositTransaction,
    MultisendFundInvoiceTransaction,
    MultisendRedeemTransaction,
    MultisendUnfactorInvoiceTransaction,
} from './useGnosisMultisend';
import { useTokenRepo } from './useTokenRepo';
import { useWeb3 } from './useWeb3';

export const toHex = (num: number) => `0x${num.toString(16)}`;

const POOL_DEPOSITS_AND_REDEMPTIONS_QUERY = gql`
    query GetPoolDepositsAndRedemptions($poolAddress: String!) {
        depositMadeEvents(where: { poolAddress: $poolAddress }) {
            ${FACTORING_EVENTS_QUERY}
        }
        sharesRedeemedEvents(where: { poolAddress: $poolAddress }) {
            ${FACTORING_EVENTS_QUERY}
        }
    }
`;

const USER_DEPOSITS_AND_REDEMPTIONS_QUERY = gql`
    query GetUserDepositsAndRedemptions($userAddress: String!, $poolAddress: String!) {
        depositMadeEvents(where: { depositor: $userAddress, poolAddress: $poolAddress }) {
            assets
            sharesIssued
            timestamp
        }
        sharesRedeemedEvents(where: { redeemer: $userAddress, poolAddress: $poolAddress }) {
            assets
            shares
            timestamp
        }
    }
`;

const FACTORING_PRICE_QUERY = gql`
    query GetFactoringPrices($address: String!, $first: Int!, $skip: Int!) {
        factoringPricePerShares(where: { address: $address }, first: $first, skip: $skip) {
            address
            priceHistory {
                price
                timestamp
            }
        }
    }
`;

const HISTORICAL_CASH_QUERY = gql`
    query GetHistoricalFactoringStatistics($address: String!, $first: Int!, $skip: Int!) {
        historicalFactoringStatistics_collection(where: { address: $address }, first: $first, skip: $skip) {
            address
            statistics {
                timestamp
                fundBalance
                capitalAccount
                deployedCapital
            }
        }
    }
`;

interface HistoricalCashQueryResult {
    historicalFactoringStatistics_collection: {
        address: string;
        statistics: {
            timestamp: string;
            fundBalance: string;
            capitalAccount: string;
            deployedCapital: string;
        }[];
    }[];
}

interface FactoringPriceQueryResult {
    factoringPricePerShares: {
        address: string;
        priceHistory: {
            price: string;
            timestamp: string;
        }[];
    }[];
}

export type FactoringPriceHistory = { poolTokenAddress: string; chainId: ChainId; timestamp: number; price: string };

async function getFactoringTokenHistoricalPrices(
    graphEndpoint: string,
    chainId: ChainId,
    poolTokenAddress: string,
): Promise<FactoringPriceHistory[]> {
    const client = new ApolloClient({
        uri: graphEndpoint,
        cache: new InMemoryCache(),
    });

    let allPriceHistory: FactoringPriceHistory[] = [];
    let skip = 0;
    const first = 1000;

    while (true) {
        const { data } = await client.query<FactoringPriceQueryResult>({
            query: FACTORING_PRICE_QUERY,
            variables: {
                address: poolTokenAddress.toLowerCase(),
                first,
                skip,
            },
            fetchPolicy: 'network-only',
        });

        if (data.factoringPricePerShares.length === 0) {
            break;
        }

        const priceHistory = data.factoringPricePerShares[0].priceHistory;
        const newPriceHistory = priceHistory.map(({ price, timestamp }) => ({
            chainId,
            poolTokenAddress,
            timestamp: parseInt(timestamp),
            price,
        }));

        allPriceHistory = allPriceHistory.concat(newPriceHistory);

        if (priceHistory.length < first) {
            break;
        }

        skip += first;
    }

    return allPriceHistory;
}

export type FactoringCashHistory = {
    poolTokenAddress: string;
    chainId: ChainId;
    timestamp: number;
    fundBalance: string;
    capitalAccount: string;
    deployedCapital: string;
};

async function getFactoringHistoricalCashView(
    graphEndpoint: string,
    chainId: ChainId,
    poolTokenAddress: string,
): Promise<FactoringCashHistory[]> {
    const client = new ApolloClient({
        uri: graphEndpoint,
        cache: new InMemoryCache(),
    });

    let allFactoringCashHistory: FactoringCashHistory[] = [];
    let skip = 0;
    const first = 1000;

    while (true) {
        const { data } = await client.query<HistoricalCashQueryResult>({
            query: HISTORICAL_CASH_QUERY,
            variables: {
                address: poolTokenAddress.toLowerCase(),
                first,
                skip,
            },
            fetchPolicy: 'network-only',
        });

        if (data.historicalFactoringStatistics_collection.length === 0) {
            break;
        }

        const cashStatistics = data.historicalFactoringStatistics_collection[0].statistics;
        const newCashHistory = cashStatistics.map(({ timestamp, fundBalance, capitalAccount, deployedCapital }) => ({
            chainId,
            poolTokenAddress,
            timestamp: parseInt(timestamp),
            fundBalance,
            capitalAccount,
            deployedCapital,
        }));

        allFactoringCashHistory = allFactoringCashHistory.concat(newCashHistory);

        if (cashStatistics.length < first) {
            break;
        }

        skip += first;
    }

    return allFactoringCashHistory;
}

export async function fetchFactoringHistoricalCash(chainId: ChainId, poolTokenAddress: string): Promise<FactoringCashHistory[]> {
    const network = NETWORKS[chainId];
    if (!network.connections.graphEndpoint) {
        console.warn(`No graph endpoint for chain ${chainId}`);
        return [];
    }

    try {
        return await getFactoringHistoricalCashView(network.connections.graphEndpoint, chainId, poolTokenAddress);
    } catch (error) {
        console.error(`Failed to fetch factoring historical cash for chain ${chainId}:`, error);
        return [];
    }
}

export async function fetchFactoringTokenHistoricalPrices(chainId: ChainId, poolTokenAddress: string): Promise<FactoringPriceHistory[]> {
    const network = NETWORKS[chainId];
    if (!network.connections.graphEndpoint) {
        console.warn(`No graph endpoint for chain ${chainId}`);
        return [];
    }

    try {
        return await getFactoringTokenHistoricalPrices(network.connections.graphEndpoint, chainId, poolTokenAddress);
    } catch (error) {
        console.error(`Failed to fetch factoring token prices for chain ${chainId}:`, error);
        return [];
    }
}

interface UserDepositsAndRedemptionsQueryResult {
    depositMadeEvents: {
        assets: string;
        sharesIssued: string;
        timestamp: string;
    }[];
    sharesRedeemedEvents: {
        assets: string;
        shares: string;
        timestamp: string;
    }[];
}

interface PoolDepositsAndRedemptionsQueryResult {
    depositMadeEvents: DepositMadeEvent__graphql[];
    sharesRedeemedEvents: SharesRedeemedEvent__graphql[];
}

export const useBullaFactoring = (factoringConfig: FactoringConfig) => {
    const { addPendingTxn } = useUIState();
    const [pending, setPending] = React.useState(false);
    const {
        signer,
        signerProvider,
        connectedNetwork,
        connectedNetworkConfig: { bullaClaimAddress },
        providersByChainId,
    } = useWeb3();
    const bullaFundAddress = factoringConfig.bullaFactoringToken.token.address;

    const crossChainProvider = providersByChainId[factoringConfig.bullaFactoringToken.chainId];
    const { resolveTokenInfo } = useTokenRepo();

    const IBullaFactoring = factoringConfig.version == FactoringConfigVersion.V1 ? IBullaFactoringV1 : IBullaFactoringV2;
    const getBullaFactoringContract =
        factoringConfig.version == FactoringConfigVersion.V1 ? getBullaFactoringContractV1 : getBullaFactoringContractV2;

    const getPoolDepositsAndRedemptions = async () => {
        const network = NETWORKS[factoringConfig.bullaFactoringToken.chainId];
        if (!network.connections.graphEndpoint) {
            console.warn(`No graph endpoint for chain ${factoringConfig.bullaFactoringToken.chainId}`);
            return null;
        }

        const client = new ApolloClient({
            uri: network.connections.graphEndpoint,
            cache: new InMemoryCache(),
        });

        const { data } = await client.query<PoolDepositsAndRedemptionsQueryResult>({
            query: POOL_DEPOSITS_AND_REDEMPTIONS_QUERY,
            variables: {
                poolAddress: bullaFundAddress.toLowerCase(),
            },
            fetchPolicy: 'network-only',
        });

        const allEvents = [...(data.depositMadeEvents || []), ...(data.sharesRedeemedEvents || [])];

        const mappedEvents = allEvents.map(mapGraphEventToEvent).filter((event): event is PoolEvent => event !== null);

        const { poolDeposits, poolRedemptions } = await getPoolEventInfo(
            mappedEvents,
            resolveTokenInfo,
            factoringConfig.bullaFactoringToken.chainId,
        );

        const result = [...poolDeposits, ...poolRedemptions];

        return result;
    };

    const calculateAverageCostPerUser = async (userAddress: string): Promise<number> => {
        const network = NETWORKS[factoringConfig.bullaFactoringToken.chainId];
        if (!network.connections.graphEndpoint) {
            console.warn(`No graph endpoint for chain ${factoringConfig.bullaFactoringToken.chainId}`);
            return 0;
        }

        const client = new ApolloClient({
            uri: network.connections.graphEndpoint,
            cache: new InMemoryCache(),
        });

        try {
            const { data } = await client.query<UserDepositsAndRedemptionsQueryResult>({
                query: USER_DEPOSITS_AND_REDEMPTIONS_QUERY,
                variables: {
                    userAddress: userAddress.toLowerCase(),
                    poolAddress: bullaFundAddress.toLowerCase(),
                },
                fetchPolicy: 'network-only',
            });

            const allDeposits = data.depositMadeEvents;
            const allRedemptions = data.sharesRedeemedEvents;

            let totalAssets = BigNumber.from(0);
            let totalShares = BigNumber.from(0);

            allDeposits.forEach(deposit => {
                totalAssets = totalAssets.add(BigNumber.from(deposit.assets));
                totalShares = totalShares.add(BigNumber.from(deposit.sharesIssued));
            });

            allRedemptions.forEach(redemption => {
                totalAssets = totalAssets.sub(BigNumber.from(redemption.assets));
                totalShares = totalShares.sub(BigNumber.from(redemption.shares));
            });

            if (totalShares.isZero()) {
                return 0;
            }

            const tokenDecimals = factoringConfig.bullaFactoringToken.token.decimals;
            return parseFloat(totalAssets.mul(BigNumber.from(10).pow(tokenDecimals)).div(totalShares).toString()) / 10 ** tokenDecimals;
        } catch (error) {
            console.error('Error fetching user deposits and redemptions:', error);
            return 0;
        }
    };

    const unfactorInvoice = async (invoiceId: BigNumber) => {
        setPending(true);
        try {
            const contract = getBullaFactoringContract(bullaFundAddress).connect(signer);
            const result = await contract.unfactorInvoice(invoiceId);

            addPendingTxn(signerProvider, connectedNetwork, result.hash);
            const receipt = await waitForTransaction(connectedNetwork, signerProvider, result.hash);
            return receipt.status == TXStatus.SUCCESS;
        } catch (e) {
            console.error(e);
            return false;
        } finally {
            setPending(false);
        }
    };

    const getAvailableAssets = async () => {
        try {
            const contract = getBullaFactoringContract(bullaFundAddress).connect(signer);
            const result = await contract.availableAssets();
            return result;
        } catch (e) {
            console.error(e);
        }
    };

    const getTotalAssets = async () => {
        try {
            const contract = getBullaFactoringContract(bullaFundAddress).connect(signer);
            const result = await contract.totalAssets();
            return result;
        } catch (e) {
            console.error(e);
        }
    };

    const getTotalSupply = async () => {
        try {
            const contract = getBullaFactoringContract(bullaFundAddress).connect(crossChainProvider);
            const result = await contract.totalSupply();
            return result;
        } catch (e) {
            console.error(e);
        }
    };

    const fundInvoice = async (invoiceId: BigNumber, factorerUpfrontBps: number) => {
        setPending(true);
        try {
            const contract = getBullaFactoringContract(bullaFundAddress).connect(signer);
            const result = await contract.fundInvoice(invoiceId, factorerUpfrontBps);

            addPendingTxn(signerProvider, connectedNetwork, result.hash);
            const receipt = await waitForTransaction(connectedNetwork, signerProvider, result.hash);
            return receipt.status == TXStatus.SUCCESS;
        } catch (e) {
            console.error(e);
            return false;
        } finally {
            setPending(false);
        }
    };

    const approveInvoiceInputToMultisendTxDTO = async (
        bullaClaimAddress: EthAddress,
        invoiceId: BigNumber,
        bullaFundAddress: EthAddress,
    ): Promise<{ transaction: MultisendApproveInvoiceTransaction }> => {
        return { transaction: buildApproveInvoiceMultisendTx(bullaClaimAddress, invoiceId, bullaFundAddress) };
    };

    function buildApproveInvoiceMultisendTx(
        bullaClaimAddress: EthAddress,
        invoiceId: BigNumber,
        bullaFundAddress: EthAddress,
    ): MultisendApproveInvoiceTransaction {
        const itemInfo: MinimalGnosisItemInfo = {
            __type: 'ApproveInvoice',
            description: 'Approve Invoice Transfer',
            creditor: '',
            debtor: '',
            id: '',
        };

        return {
            label: `Approve Invoice Transfer`,
            transactionInput: getApprovalTransaction(bullaClaimAddress, invoiceId, bullaFundAddress),
            itemInfo,
            interaction: 'Approve Invoice',
            invoiceId: invoiceId,
        };
    }

    const getApprovalTransaction = (_bullaClaimAddress: EthAddress, invoiceId: BigNumber, _bullaFundAddress: EthAddress) => {
        const bullaClaimAddress = toChecksumAddress(_bullaClaimAddress);
        const bullaFundAddress = toChecksumAddress(_bullaFundAddress);
        const value = '0';

        return {
            to: bullaClaimAddress,
            value,
            operation: 0,
            data: I_IERC721.encodeFunctionData('approve', [bullaFundAddress, invoiceId]),
        };
    };

    const fundInvoiceInputToMultisendTxDTO = async (
        bullaFundAddress: EthAddress,
        invoiceId: BigNumber,
        factorerUpfrontBps: number,
    ): Promise<{ transaction: MultisendFundInvoiceTransaction }> => {
        return { transaction: buildFundInvoiceMultisendTx(bullaFundAddress, invoiceId, factorerUpfrontBps) };
    };

    function buildFundInvoiceMultisendTx(
        bullaFundAddress: EthAddress,
        invoiceId: BigNumber,
        factorerUpfrontBps: number,
    ): MultisendFundInvoiceTransaction {
        const itemInfo: MinimalGnosisItemInfo = {
            __type: 'FundInvoice',
            description: 'Bulla Fund Invoice Funding',
            creditor: '',
            debtor: '',
            id: '',
        };

        return {
            label: `Fund Invoice in Bulla Fund`,
            transactionInput: getFundInvoiceTransaction(bullaFundAddress, invoiceId, factorerUpfrontBps),
            itemInfo,
            interaction: 'Fund Invoice',
            invoiceId: invoiceId,
            factorerUpfrontBps: factorerUpfrontBps,
        };
    }

    const getFundInvoiceTransaction = (_bullaFundAddress: EthAddress, invoiceId: BigNumber, factorerUpfrontBps: number) => {
        const bullaFundAddress = toChecksumAddress(_bullaFundAddress);
        const value = '0';

        return {
            to: bullaFundAddress,
            value,
            operation: 0,
            data: IBullaFactoring.encodeFunctionData('fundInvoice', [invoiceId, factorerUpfrontBps]),
        };
    };

    const approve = async (claim: ClaimInfo) => {
        setPending(true);
        try {
            const contract = getBullaClaimContract(bullaClaimAddress).connect(signer);
            const result = await contract.approve(bullaFundAddress, BigNumber.from(claim.id));
            addPendingTxn(signerProvider, connectedNetwork, result.hash);
            const receipt = await waitForTransaction(connectedNetwork, signerProvider, result.hash);
            return receipt.status == TXStatus.SUCCESS;
        } catch (e) {
            console.error(e);
            return false;
        } finally {
            setPending(false);
        }
    };

    const isApproved = async (claim: ClaimInfo) => {
        setPending(true);
        try {
            const contract = getBullaClaimContract(bullaClaimAddress).connect(signer);
            const approvedAddress = await contract.getApproved(BigNumber.from(claim.id));
            return addressEquality(approvedAddress, bullaFundAddress);
        } catch (e) {
            console.error(e);
            return false;
        } finally {
            setPending(false);
        }
    };

    const maxRedeemAmout = async () => {
        try {
            const contract = getBullaFactoringContract(bullaFundAddress).connect(signer);
            const result = await contract['maxRedeem()']();
            return result;
        } catch (e) {
            console.error(e);
        }
    };

    const calculateTargetFees = async (invoiceId: BigNumber, factorerUpfrontBps: number) => {
        try {
            const contract = getBullaFactoringContract(bullaFundAddress).connect(signer);
            const result: BullaFactoringFeesAndAmounts = await contract.calculateTargetFees(invoiceId, factorerUpfrontBps);
            return result;
        } catch (e) {
            console.error(e);
        }
    };

    const previewDeposit = async (assets: BigNumber) => {
        try {
            const contract = getBullaFactoringContract(bullaFundAddress).connect(signer);
            const result = await contract.previewDeposit(assets);
            return result;
        } catch (e) {
            console.error(e);
        }
    };

    const depositHandler = async (depositAmount: BigNumber, receiver: EthAddress, attachment?: Multihash) => {
        return attachment ? await depositWithAttachment(depositAmount, receiver, attachment) : await deposit(depositAmount, receiver);
    };

    const redeemHandler = async (redeemAmount: BigNumber, receiver: EthAddress, owner: EthAddress, attachment?: Multihash) => {
        return attachment
            ? await redeemWithAttachment(redeemAmount, receiver, owner, attachment)
            : await redeem(redeemAmount, receiver, owner);
    };

    const deposit = async (depositAmount: BigNumber, receiver: EthAddress) => {
        setPending(true);
        try {
            const contract = getBullaFactoringContract(bullaFundAddress).connect(signer);
            const result = await contract.deposit(depositAmount, receiver);

            addPendingTxn(signerProvider, connectedNetwork, result.hash);
            const receipt = await waitForTransaction(connectedNetwork, signerProvider, result.hash);
            return receipt.status == TXStatus.SUCCESS;
        } catch (e) {
            console.error(e);
            return false;
        } finally {
            setPending(false);
        }
    };

    const depositWithAttachment = async (depositAmount: BigNumber, receiver: EthAddress, attachment: Multihash) => {
        setPending(true);
        try {
            const contract = getBullaFactoringContract(bullaFundAddress).connect(signer);
            const result = await contract.depositWithAttachment(depositAmount, receiver, attachment);

            addPendingTxn(signerProvider, connectedNetwork, result.hash);
            const receipt = await waitForTransaction(connectedNetwork, signerProvider, result.hash);
            return receipt.status == TXStatus.SUCCESS;
        } catch (e) {
            console.error(e);
            return false;
        } finally {
            setPending(false);
        }
    };

    const previewRedeem = async (shares: BigNumber) => {
        try {
            const contract = getBullaFactoringContract(bullaFundAddress).connect(signer);
            const result = await contract.previewRedeem(shares);
            return result;
        } catch (e) {
            console.error(e);
        }
    };

    const redeem = async (redeemAmount: BigNumber, receiver: EthAddress, owner: EthAddress) => {
        setPending(true);
        try {
            const contract = getBullaFactoringContract(bullaFundAddress).connect(signer);
            const result = await contract.redeem(redeemAmount, receiver, owner);
            addPendingTxn(signerProvider, connectedNetwork, result.hash);
            const receipt = await waitForTransaction(connectedNetwork, signerProvider, result.hash);
            return receipt.status == TXStatus.SUCCESS;
        } catch (e) {
            console.error(e);
            return false;
        } finally {
            setPending(false);
        }
    };

    const redeemWithAttachment = async (redeemAmount: BigNumber, receiver: EthAddress, owner: EthAddress, attachment: Multihash) => {
        setPending(true);
        try {
            const contract = getBullaFactoringContract(bullaFundAddress).connect(signer);
            const result = await contract.redeemWithAttachment(redeemAmount, receiver, owner, attachment);
            addPendingTxn(signerProvider, connectedNetwork, result.hash);
            const receipt = await waitForTransaction(connectedNetwork, signerProvider, result.hash);
            return receipt.status == TXStatus.SUCCESS;
        } catch (e) {
            console.error(e);
            return false;
        } finally {
            setPending(false);
        }
    };

    const getPoolInfo = async () => {
        try {
            const contract = getBullaFactoringContract(bullaFundAddress).connect(crossChainProvider);
            const poolInfo = await contract.getFundInfo();
            const name = poolInfo.name;
            const dateFromTimestamp = new Date(Number(poolInfo.creationTimestamp) * 1000);
            const fundBalance = poolInfo.fundBalance;
            const deployedCapital = poolInfo.deployedCapital;
            const realizedGain = poolInfo.realizedGain;
            const capitalAccount = poolInfo.capitalAccount;
            const price = poolInfo.price;
            const tokensAvailableForRedemption = poolInfo.tokensAvailableForRedemption;
            const adminFeeBps = poolInfo.adminFeeBps;
            const impairReserve = poolInfo.impairReserve;
            const targetYieldBps = Number(poolInfo.targetYieldBps);
            return {
                name,
                dateCreated: dateFromTimestamp,
                fundBalance,
                deployedCapital,
                realizedGain,
                capitalAccount,
                price,
                tokensAvailableForRedemption,
                adminFeeBps,
                impairReserve,
                targetYieldBps,
            };
        } catch (e) {
            console.error(e);
        }
    };

    const getPricePerShare = async () => {
        try {
            const contract = getBullaFactoringContract(bullaFundAddress).connect(signer);
            const result = await contract.pricePerShare();
            return result;
        } catch (e) {
            console.error(e);
        }
    };

    const redeemInputToMultisendTxDTO = async (
        bullaFundAddress: EthAddress,
        underlyingToken: TokenInfo,
        redeemAmount: BigNumber,
        receiverAddress: EthAddress,
        ownerAddress: EthAddress,
        attachment?: Multihash,
    ): Promise<{ transaction: MultisendRedeemTransaction }> => {
        return {
            transaction: buildRedeemMultisendTx(bullaFundAddress, underlyingToken, receiverAddress, ownerAddress, redeemAmount, attachment),
        };
    };

    function buildRedeemMultisendTx(
        bullaFundAddress: EthAddress,
        underlyingToken: TokenInfo,
        userAddress: EthAddress,
        ownerAddress: EthAddress,
        redeemAmount: BigNumber,
        attachment?: Multihash,
    ): MultisendRedeemTransaction {
        const itemInfo: MinimalGnosisItemInfo = {
            __type: 'Redeem',
            description: 'Bulla Fund Redeem',
            creditor: '',
            debtor: '',
            id: '',
        };

        return {
            label: `Redeem Funds from Bulla Fund`,
            transactionInput: attachment
                ? getRedeemWithAttachmentTransaction(bullaFundAddress, userAddress, ownerAddress, redeemAmount, attachment)
                : getRedeemTransaction(bullaFundAddress, userAddress, ownerAddress, redeemAmount),
            itemInfo,
            interaction: 'Redeem from Bulla Fund',
            token: underlyingToken.token,
            redeemAmount: redeemAmount,
        };
    }

    const getRedeemTransaction = (
        _bullaFundAddress: EthAddress,
        receiverAddress: EthAddress,
        ownerAddress: EthAddress,
        redeemAmount: BigNumber,
    ) => {
        const bullaFundAddress = toChecksumAddress(_bullaFundAddress);
        const value = '0';

        return {
            to: bullaFundAddress,
            value,
            operation: 0,
            data: IBullaFactoring.encodeFunctionData('redeem', [redeemAmount, receiverAddress, ownerAddress]),
        };
    };

    const getRedeemWithAttachmentTransaction = (
        _bullaFundAddress: EthAddress,
        receiverAddress: EthAddress,
        ownerAddress: EthAddress,
        redeemAmount: BigNumber,
        attachment: Multihash,
    ) => {
        const bullaFundAddress = toChecksumAddress(_bullaFundAddress);
        const value = '0';

        return {
            to: bullaFundAddress,
            value,
            operation: 0,
            data: IBullaFactoring.encodeFunctionData('redeemWithAttachment', [redeemAmount, receiverAddress, ownerAddress, attachment]),
        };
    };

    const depositInputToMultisendTxDTO = async (
        bullaFundAddress: EthAddress,
        underlyingToken: TokenInfo,
        receiverAddress: EthAddress,
        depositAmount: BigNumber,
        attachment?: Multihash,
    ): Promise<{ transaction: MultisendDepositTransaction }> => {
        return { transaction: buildDepositMultisendTx(bullaFundAddress, underlyingToken, receiverAddress, depositAmount, attachment) };
    };

    const getDepositTransaction = (_bullaFundAddress: EthAddress, receiverAddress: EthAddress, depositAmount: BigNumber) => {
        const bullaFundAddress = toChecksumAddress(_bullaFundAddress);
        const value = '0';

        return {
            to: bullaFundAddress,
            value,
            operation: 0,
            data: IBullaFactoring.encodeFunctionData('deposit', [depositAmount, receiverAddress]),
        };
    };

    const getDepositWithAttachmentTransaction = (
        _bullaFundAddress: EthAddress,
        receiverAddress: EthAddress,
        depositAmount: BigNumber,
        attachment: Multihash,
    ) => {
        const bullaFundAddress = toChecksumAddress(_bullaFundAddress);
        const value = '0';

        return {
            to: bullaFundAddress,
            value,
            operation: 0,
            data: IBullaFactoring.encodeFunctionData('depositWithAttachment', [depositAmount, receiverAddress, attachment]),
        };
    };

    function buildDepositMultisendTx(
        bullaFundAddress: EthAddress,
        underlyingToken: TokenInfo,
        userAddress: EthAddress,
        depositAmount: BigNumber,
        attachment?: Multihash,
    ): MultisendDepositTransaction {
        const itemInfo: MinimalGnosisItemInfo = {
            __type: 'Deposit',
            description: 'Bulla Fund Deposit',
            creditor: '',
            debtor: '',
            id: '',
        };

        return {
            label: `Send Deposit to Bulla Fund`,
            transactionInput: attachment
                ? getDepositWithAttachmentTransaction(bullaFundAddress, userAddress, depositAmount, attachment)
                : getDepositTransaction(bullaFundAddress, userAddress, depositAmount),
            itemInfo,
            interaction: 'Deposit to Bulla Fund',
            approvalNeeded: {
                amount: depositAmount,
                spendingContract: bullaFundAddress,
            },
            token: underlyingToken.token,
            depositAmount: depositAmount,
        };
    }

    const unfactorInvoiceInputToMultisendTxDTO = async (
        bullaFundAddress: EthAddress,
        invoiceId: BigNumber,
        amount: BigNumber,
        poolUnderlyingTokenDto: TokenDto,
    ): Promise<{ transaction: MultisendUnfactorInvoiceTransaction }> => {
        return { transaction: buildUnfactorInvoiceMultisendTx(bullaFundAddress, invoiceId, amount, poolUnderlyingTokenDto) };
    };

    function buildUnfactorInvoiceMultisendTx(
        bullaFundAddress: EthAddress,
        invoiceId: BigNumber,
        amount: BigNumber,
        poolUnderlyingTokenDto: TokenDto,
    ): MultisendUnfactorInvoiceTransaction {
        const itemInfo: MinimalGnosisItemInfo = {
            __type: 'UnfactorInvoice',
            description: 'Invoice Unfactoring',
            creditor: '',
            debtor: '',
            id: '',
        };

        return {
            label: `Unfactor Invoice`,
            transactionInput: getUnfactorInvoiceTransaction(bullaFundAddress, invoiceId),
            itemInfo,
            interaction: 'Unfactor Invoice',
            approvalNeeded: {
                amount: amount,
                spendingContract: bullaFundAddress,
            },
            token: poolUnderlyingTokenDto,
            invoiceId: invoiceId,
        };
    }

    const getUnfactorInvoiceTransaction = (_bullaFundAddress: EthAddress, invoiceId: BigNumber) => {
        const bullaFundAddress = toChecksumAddress(_bullaFundAddress);
        const value = '0';

        return {
            to: bullaFundAddress,
            value,
            operation: 0,
            data: IBullaFactoring.encodeFunctionData('unfactorInvoice', [invoiceId]),
        };
    };

    return [
        pending,
        {
            depositHandler,
            redeemHandler,
            depositInputToMultisendTxDTO,
            redeemInputToMultisendTxDTO,
            getPricePerShare,
            maxRedeemAmout,
            calculateTargetFees,
            approve,
            isApproved,
            fundInvoice,
            getAvailableAssets,
            unfactorInvoice,
            getPoolInfo,
            fundInvoiceInputToMultisendTxDTO,
            approveInvoiceInputToMultisendTxDTO,
            getTotalAssets,
            unfactorInvoiceInputToMultisendTxDTO,
            getTotalSupply,
            calculateAverageCostPerUser,
            getPoolDepositsAndRedemptions,
            previewDeposit,
            previewRedeem,
        },
    ] as const;
};

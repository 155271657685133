import { MenuItem, Tooltip } from '@chakra-ui/react';
import React from 'react';
import { ClaimInfo } from '../../../data-lib/data-model';
import { useGnosisGlobalMultisend } from '../../../hooks/useGnosisMultisend';
import { SelectableOptions } from '../../../hooks/useSelection';
import { filterSelectedClaims, getMultisendTooltipLabel, useTransactionBundle } from '../../../hooks/useTransactionBundle';
import { useGlobalUserData } from '../../../hooks/useUserData';
import { useGnosisSafe } from '../../../state/gnosis-state';
import { menuItemDisabledProps } from '../../inputs/buttons';
import { ClaimsPage } from './claims-page-template';
import { unselectIfTrue } from './receivables';

export const Payables = () => {
    const { payables } = useGlobalUserData('exclude-originating-claims');
    const { connectedSafeAddress } = useGnosisSafe();
    const { isFull } = useGnosisGlobalMultisend();
    const { addPayClaimTransactionsToBundle, addRejectClaimTransactionsToBundle } = useTransactionBundle();
    const [selected, setSelected] = React.useState<string[]>([]);

    const handlePaySelected = () =>
        addPayClaimTransactionsToBundle(
            filterSelectedClaims(
                selected,
                payables.filter((x): x is ClaimInfo => x.__type == 'Claim'),
            ),
        );
    const handleRejectSelected = () =>
        addRejectClaimTransactionsToBundle(
            filterSelectedClaims(
                selected,
                payables.filter((x): x is ClaimInfo => x.__type == 'Claim'),
            ),
        );

    const multisendTooltipLabel = getMultisendTooltipLabel(!!connectedSafeAddress, isFull);
    const buttonsDisabled = !connectedSafeAddress || isFull;

    const dropdownActions = (selectableOptions: SelectableOptions<string>) => [
        <Tooltip label={multisendTooltipLabel} key="pay">
            <MenuItem
                onClick={async () => {
                    await unselectIfTrue(async () => {
                        handlePaySelected();
                        return true;
                    }, selectableOptions);
                }}
                isDisabled={buttonsDisabled}
                {...(buttonsDisabled && menuItemDisabledProps)}
            >
                Pay
            </MenuItem>
        </Tooltip>,
        <Tooltip label={multisendTooltipLabel} key="reject">
            <MenuItem
                onClick={async () => {
                    await unselectIfTrue(async () => {
                        handleRejectSelected();
                        return true;
                    }, selectableOptions);
                }}
                isDisabled={buttonsDisabled}
                {...(buttonsDisabled && menuItemDisabledProps)}
            >
                Reject
            </MenuItem>
        </Tooltip>,
    ];

    return (
        <ClaimsPage claims={payables} tabName="payables" dropdownActions={dropdownActions} selected={selected} setSelected={setSelected} />
    );
};

import { BigNumber, constants } from 'ethers';
import { useEffect, useState } from 'react';
import { FinancingOfferedClaimInfo } from '../data-lib/data-model';
import { getAcceptFinancingTransaction } from '../data-lib/dto/bulla-finance-dto';
import { getBullaFinanceContract, getBullaClaimContract, getERC20Contract } from '../data-lib/dto/contract-interfaces';
import { getFinancingAcceptedEvents } from '../data-lib/dto/event-filters';
import { TransactionResult } from '../data-lib/dto/events-dto';
import { weiToDisplayAmt } from '../data-lib/ethereum';
import { NetworkConfig, TXStatus } from '../data-lib/networks';
import { useGnosisSafe } from '../state/gnosis-state';
import { Require } from '../tools/types';
import { useTokenBalances } from './useChainData';
import { useOpenClaimDetails } from './useClaimDetailDisclosure';
import { useERC20 } from './useERC20';
import { MultisendTransaction } from './useGnosisMultisend';
import { useGnosisTransaction } from './useGnosisTransaction';
import { useSendTransaction } from './useSendTransaction';
import { useActingWalletAddress } from './useWalletAddress';
import { useWeb3 } from './useWeb3';

export type ERC20ApprovalState = '1-unapproved' | '2-approving' | '3-approved';
export type FinancingAcceptedState = '4-unaccepted' | '5-accepting' | '6-accepted';
export type UnderlyingRejectionState = '7-unrejected' | '8-rejecting';
export type ActiveState = {
    firstStep: 'approve-token' | 'accept-financing' | 'reject-claim';
    currentStep: ERC20ApprovalState | FinancingAcceptedState | UnderlyingRejectionState;
};
export type FinancingWizardState = 'loading' | 'unsupported-network' | 'insufficient-funds' | ActiveState | 'complete';

const isFinancingSupported = (conf: NetworkConfig): conf is Require<NetworkConfig, 'bullaFinanceAddress'> =>
    conf.bullaFinanceAddress !== undefined;

export const isActiveState = (state: FinancingWizardState): state is ActiveState => typeof state !== 'string' && 'currentStep' in state;
export const isStartingState = (state: FinancingWizardState): state is ActiveState =>
    isActiveState(state) &&
    ((state.firstStep === 'approve-token' && state.currentStep === '1-unapproved') ||
        (state.firstStep === 'accept-financing' && state.currentStep === '4-unaccepted') ||
        (state.firstStep === 'reject-claim' && state.currentStep === '7-unrejected'));
export const isinProgress = (state: FinancingWizardState): state is ActiveState => isActiveState(state) && !isStartingState(state);

export const isTokenApproved = (state: FinancingWizardState) =>
    state === 'complete' || (isActiveState(state) && +state.currentStep.charAt(0) > 2);
export const isFinancingAcceptedWizard = (state: FinancingWizardState) =>
    state === 'complete' || (isActiveState(state) && +state.currentStep.charAt(0) > 5);
export const isUnderlyingRejected = (state: FinancingWizardState) => state === 'complete';

const performAsync = async <T>(func: () => Promise<T>, setLoading: (loading: boolean) => void) => {
    setLoading(true);
    const result = await func();
    setLoading(false);
    return result;
};

export const useAcceptFinancing = (claimInfo: FinancingOfferedClaimInfo | undefined) => {
    const { connectedNetwork, connectedNetworkConfig, providersByChainId } = useWeb3();
    const userAddress = useActingWalletAddress();
    const claimChainId = claimInfo?.chainId ?? connectedNetwork;
    const { isSafeReady, inSafeApp, safeInfo } = useGnosisSafe();
    const tokenBalances = useTokenBalances({ chainId: claimChainId, poll: true });

    const { loading: gnosis_pending, executeTransactions } = useGnosisTransaction();
    const [pending, sendTransaction] = useSendTransaction();
    const [pendingApproval, { approve }] = useERC20();
    const openClaim = useOpenClaimDetails();

    const [fetching, setFetching] = useState(false);
    const [allowance, setAllowance] = useState<BigNumber | undefined>(undefined);

    const isSupportedNetwork = isFinancingSupported(connectedNetworkConfig);
    const isClaimReady = !!claimInfo;
    const isLoading = pending || pendingApproval || fetching || gnosis_pending;
    const requiresMultipleTransactions = !isSafeReady && !inSafeApp;

    const [state, setState] = useState<FinancingWizardState>('loading');

    const setCurrentStep = (currentStep: ERC20ApprovalState | FinancingAcceptedState | UnderlyingRejectionState) =>
        setState(state => (isActiveState(state) ? { ...state, currentStep } : state));

    const determineStartState = async () => {
        // do not proceed if the network is not supported
        if (!isSupportedNetwork) setState('unsupported-network');
        // if the tokenBalances provider or if the claim has not been resolved
        else if (!tokenBalances || !isClaimReady) setState('loading');
        else {
            // if they have resolved, check the allowance and balance
            const token = claimInfo.tokenInfo.token;
            const terms = claimInfo.financingState.terms;

            let tokenAllowance: BigNumber;
            if (allowance === undefined) {
                const tokenContract = getERC20Contract(token.address).connect(providersByChainId[claimChainId]);
                const result = await tokenContract.allowance(userAddress, connectedNetworkConfig.bullaFinanceAddress);
                setAllowance(result);
                tokenAllowance = result;
            } else tokenAllowance = allowance;

            const insufficientFunds =
                +(tokenBalances.getBalanceForToken(token.address) ?? 0) < weiToDisplayAmt({ amountWei: terms.downPayment, token });
            const needsApproval = tokenAllowance.lt(terms.downPayment);

            // the priority of insufficient funds for down payment ranks above the need for token approval
            if (insufficientFunds) setState('insufficient-funds');
            // they must first approve the token
            else if (needsApproval) setState({ firstStep: 'approve-token', currentStep: '1-unapproved' });
            // they are ready to accept financing
            else setState({ firstStep: 'accept-financing', currentStep: '4-unaccepted' });
        }
    };

    useEffect(() => {
        // if the state needs to be recalc'd
        if (!isActiveState(state) && state !== 'complete') performAsync(determineStartState, setFetching);
    }, [tokenBalances, claimInfo, userAddress, connectedNetworkConfig]);

    const handleApprove = async () => {
        if (isClaimReady && isSupportedNetwork) {
            setCurrentStep('2-approving');

            const result = await approve(
                connectedNetworkConfig.bullaFinanceAddress,
                claimInfo.tokenInfo.token.address,
                constants.MaxUint256,
            );

            if (result?.status === TXStatus.SUCCESS) {
                setAllowance(constants.MaxUint256);
                setCurrentStep('3-approved');
            } else {
                setCurrentStep('1-unapproved');
            }
            return result;
        }
    };

    const onFinanceComplete = (result: TransactionResult | undefined) => {
        if (result) {
            const [financeAcceptedEvent] = getFinancingAcceptedEvents(result.events);
            if (financeAcceptedEvent.tokenId) openClaim(financeAcceptedEvent.tokenId, connectedNetwork);
        }
    };

    const handleAcceptFinancing = async () => {
        if (isClaimReady && isSupportedNetwork) {
            setCurrentStep('5-accepting');
            const result = await sendTransaction(
                signer =>
                    getBullaFinanceContract(connectedNetworkConfig.bullaFinanceAddress)
                        .connect(signer)
                        .acceptFinancing(claimInfo.id, claimInfo.financingState.terms.downPayment, claimInfo.description),
                true,
            );
            result?.status === TXStatus.SUCCESS ? setState('complete') : setCurrentStep('4-unaccepted');
            onFinanceComplete(result);
            return result;
        }
    };

    const handleReject = async () => {
        if (isClaimReady && isSupportedNetwork) {
            setCurrentStep('8-rejecting');
            const result = await sendTransaction(
                signer => getBullaClaimContract(connectedNetworkConfig.bullaClaimAddress).connect(signer).rejectClaim(claimInfo.id),
                false,
            );
            result?.status === TXStatus.SUCCESS ? setState('complete') : setCurrentStep('7-unrejected');
            return result;
        }
    };

    const handleClick = async () => {
        if (isSafeReady && safeInfo && isClaimReady && isActiveState(state)) {
            const result = (
                await executeTransactions(
                    safeInfo,
                    [buildAcceptFinancingMultisendTransaction(connectedNetworkConfig.bullaFinanceAddress!, claimInfo)],
                    false,
                )
            ).transactionResult;
            onFinanceComplete(result);
            return result;
        } else return !isTokenApproved(state) ? handleApprove() : handleAcceptFinancing();
    };

    const buttonText = (function () {
        if (!isClaimReady || state === 'loading') return 'Loading...';
        if (state === 'insufficient-funds') return 'Insufficient funds';
        if (state === 'unsupported-network') return 'Unsupported network';
        if (state === 'complete') return 'Accepted';
        if (!isTokenApproved(state) && requiresMultipleTransactions) return 'Pay (Approve Token)';
        return `Pay down payment of ${weiToDisplayAmt({
            amountWei: claimInfo.financingState.terms.downPayment,
            token: claimInfo.tokenInfo.token,
        })} ${claimInfo.tokenInfo.token.symbol}`;
    })();

    return [{ isLoading, state, buttonText }, handleClick] as const;
};

export const buildAcceptFinancingMultisendTransaction = (
    bullaFinanceAddress: string,
    claimInfo: FinancingOfferedClaimInfo,
): MultisendTransaction => ({
    label: `Accept Financing and Pay Down Payment on #${claimInfo.id}`,
    transactionInput: getAcceptFinancingTransaction(bullaFinanceAddress, claimInfo),
    itemInfo: claimInfo,
    interaction: 'Accept Financing',
    approvalNeeded: {
        amount: claimInfo.financingState.terms.downPayment,
        spendingContract: bullaFinanceAddress,
    },
    token: claimInfo.tokenInfo.token,
    paymentAmount: claimInfo.financingState.terms.downPayment,
});

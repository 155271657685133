import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { Box, Flex, HStack, Spacer, Stack, Text } from '@chakra-ui/react';
import { useSelect } from 'downshift';
import React from 'react';
import { AccountTagInfo, BullaItemInfo } from '../../../data-lib/data-model';
import { useIsMobile } from '../../../hooks/useIsMobile';
import { SelectableOptions } from '../../../hooks/useSelection';
import { toUSD } from '../../../tools/common';
import { ChakraCompose } from '../../../tools/types';
import { Categories, ItemsTable, TableItem } from '../claim-table';
import { ResponsiveStack } from '../responsive-stack';
import { PaymentFilter, paymentFilterToPills, PaymentFilterValues } from './filters/claim-filter';
import { ClearFilterPillsStack, TableFilter } from './filters/common';

export const AccountTagBalanceSummary = ({ label, total }: { label: string; total: number }) => {
    return (
        <HStack>
            <Text textStyle="noWrap">{label}</Text>
            <Text>{toUSD(total)}</Text>
        </HStack>
    );
};

export const AccountTagSelector = ({
    accountTags,
    onSelect,
    initialTag,
}: {
    accountTags: AccountTagInfo[];
    onSelect: (tag: AccountTagInfo) => void;
    initialTag?: AccountTagInfo;
}) => {
    const {
        isOpen,
        selectedItem: currentTag,
        getToggleButtonProps,
        getMenuProps,
        highlightedIndex,
        getItemProps,
    } = useSelect({
        items: accountTags,
        onSelectedItemChange: ({ selectedItem }) => selectedItem && onSelect(selectedItem),
        initialSelectedItem: initialTag,
    });

    return (
        <Box>
            <Flex align="center" {...getToggleButtonProps()} as="button">
                <Text textStyle="bullaViewHeader">{currentTag?.name ?? 'Select tag...'}</Text>
                <Box w="2" />
                {!isOpen ? <ChevronDownIcon h="8" w="7" /> : <ChevronUpIcon h="8" w="7" />}
            </Flex>
            <Stack spacing="0" zIndex="1000" borderRadius="xl" boxShadow="2xl" py="4" pos="absolute" bg="#D9D7D5" {...getMenuProps()}>
                {isOpen &&
                    accountTags?.map((accountTag, index) => (
                        <Box
                            m="0"
                            py="2"
                            px="4"
                            bg={highlightedIndex === index ? 'scheme.accent_light' : 'inherit'}
                            textStyle={highlightedIndex === index ? 'hover' : 'inherit'}
                            key={accountTag.name.toString()}
                            {...getItemProps({ item: accountTag, index })}
                        >
                            {accountTag.name}
                        </Box>
                    ))}
            </Stack>
        </Box>
    );
};

export type TabOptions<T extends string | number | symbol> = { label: string; value: T };
export const TabSwitcher = <T extends string | number | symbol>({
    tab,
    setTab,
    options,
    activeColor = 'highlight',
    inactiveBorderColor = 'none',
    mb,
    noticeCounts,
}: {
    tab: T;
    setTab: (tab: T) => void;
    options: TabOptions<T>[];
    activeColor?: string;
    inactiveBorderColor?: string;
    mb?: string;
    noticeCounts?: Partial<Record<T, number>>;
}) => {
    const Options = React.useMemo(
        () =>
            options.map((option, key) => {
                const isActive = option.value === tab;
                const noticeCount = noticeCounts?.[option.value] ?? 0;

                return (
                    <Box
                        key={key}
                        px="4"
                        py="2"
                        as="button"
                        role="button"
                        onClick={() => setTab(option.value)}
                        borderBottomWidth="2px"
                        borderBottomColor={isActive ? activeColor : inactiveBorderColor}
                        mb={mb}
                    >
                        <Flex alignItems="center">
                            <Text fontSize="1.1em" color={isActive ? activeColor : 'inherit'}>
                                {option.label}
                            </Text>
                            {noticeCount > 0 && (
                                <Box
                                    ml="2"
                                    px="2"
                                    py="1"
                                    borderRadius="full"
                                    bg={'gray.50'}
                                    color={'gray.700'}
                                    fontSize="0.8em"
                                    borderWidth="1.3px"
                                    borderColor="gray.200"
                                    fontWeight="500"
                                >
                                    {noticeCount}
                                </Box>
                            )}
                        </Flex>
                    </Box>
                );
            }),
        [options, tab, noticeCounts, activeColor, inactiveBorderColor, mb, setTab],
    );
    return <HStack alignItems="flex-end">{Options}</HStack>;
};

export const emptyFilterValues: PaymentFilterValues = {
    search: '',
    date: { startDate: undefined, endDate: undefined },
    type: undefined,
    priceRange: undefined,
    direction: 'In And Out',
    selectedWallets: new Set(),
    selectedNetworks: new Set(),
    selectedTokenSymbols: new Set(),
    category: undefined,
};

export const AccountTagView = ({
    allItems,
    filteredItems,
    filters,
    actionButton,
    category,
    setCategory,
    setSelected,
    tab,
    setFilters,
    claimFilters,
    selectOptions,
    ...props
}: {
    allItems: TableItem[];
    filteredItems: TableItem[];
    filters: PaymentFilterValues;
    actionButton?: React.ReactNode;
    category: Categories;
    setCategory: (category: Categories) => void;
    setSelected?: (selected: string[]) => void;
    tab: string;
    setFilters: React.Dispatch<React.SetStateAction<PaymentFilterValues>>;
    claimFilters: [] | TableFilter<BullaItemInfo>[];
    selectOptions: SelectableOptions<string>;
} & ChakraCompose) => {
    const isMobile = useIsMobile();

    return (
        <>
            <Flex pt="8" direction={'column'} flex="1" {...props}>
                <ResponsiveStack align="flex-start">
                    <PaymentFilter filters={filters} setFilters={setFilters} searchPlaceholder={tab} hideDirectionFilter />
                    <Spacer />
                    {actionButton && !isMobile && <Box h="100%">{actionButton}</Box>}
                </ResponsiveStack>
                <ClearFilterPillsStack
                    pb="4"
                    clearAll={() =>
                        setFilters({
                            ...emptyFilterValues,
                            selectedNetworks: new Set(),
                            selectedTokenSymbols: new Set(),
                            selectedWallets: new Set(),
                        })
                    }
                    filters={filters}
                    filtersToPills={paymentFilterToPills(setFilters)}
                />
                <ItemsTable filteredItems={filteredItems} setSelected={setSelected} selectOptions={selectOptions} />
            </Flex>
        </>
    );
};

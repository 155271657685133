import React, { useMemo } from 'react';
import { FactoringConfig, FactoringConfigVersion, NETWORKS } from '../data-lib/networks';
import { hasFactoringConfig } from '../pages/financing/factoring-pools-list';
import { useBullaFactoringPermissions } from './useBullaFactoringPermissions';
import { useActingWalletAddress } from './useWalletAddress';

type Pool = {
    chainId: number;
    address: string;
    factoringConfig: FactoringConfig;
    hasFactoringPermissions: boolean;
    hasDepositPermissions: boolean;
};

type FactoringAndDepositPermissionsContext =
    | 'uninitialized'
    | {
          poolsWithPermissions: Pool[];
          isLoading: boolean;
      };

const FactoringAndDepositPermissionsContext = React.createContext<FactoringAndDepositPermissionsContext>('uninitialized');

export type FactoringAddressesByChainId = Record<number, { address: string; version: FactoringConfigVersion }[]>;

export const getFactoringConfigsByChainId = (): Record<number, FactoringConfig[]> => {
    return Object.values(NETWORKS)
        .filter(hasFactoringConfig)
        .reduce((acc, network) => {
            const chainId = network.chainId;
            if (!acc[chainId]) {
                acc[chainId] = [];
            }
            acc[chainId].push(...network.factoringConfig);
            return acc;
        }, {} as Record<number, FactoringConfig[]>);
};

export const FactoringAndDepositPermissionsProvider = ({ children }: { children: React.ReactNode }) => {
    const actingWallet = useActingWalletAddress();
    const factoringConfigs: FactoringConfig[] = useMemo(() => Object.values(getFactoringConfigsByChainId()).flatMap(x => x), []);

    const [poolsWithPermissions, setPoolsWithPermissions] = React.useState<Pool[]>(() =>
        factoringConfigs.map(factoringConfig => ({
            chainId: factoringConfig.poolUnderlyingToken.chainId,
            address: factoringConfig.bullaFactoringToken.token.address,
            factoringConfig,
            hasFactoringPermissions: false,
            hasDepositPermissions: false,
        })),
    );

    const [isLoading, setIsLoading] = React.useState<boolean>(true);
    const [_, { hasFactoringPermissions, hasDepositPermissions }] = useBullaFactoringPermissions();

    React.useEffect(() => {
        const fetchPoolPermissions = async () => {
            setIsLoading(true);
            const accessiblePools: Pool[] = [];

            for (const factoringConfig of factoringConfigs) {
                const chainId = factoringConfig.poolUnderlyingToken.chainId;
                const factoringPermissions = await hasFactoringPermissions(factoringConfig);
                const depositPermissions = await hasDepositPermissions(factoringConfig);

                accessiblePools.push({
                    chainId,
                    address: factoringConfig.bullaFactoringToken.token.address,
                    factoringConfig,
                    hasFactoringPermissions: factoringPermissions,
                    hasDepositPermissions: depositPermissions,
                });
            }

            setPoolsWithPermissions(accessiblePools);
            setIsLoading(false);
        };

        fetchPoolPermissions();
    }, [actingWallet]);

    return (
        <FactoringAndDepositPermissionsContext.Provider value={{ poolsWithPermissions, isLoading }}>
            {children}
        </FactoringAndDepositPermissionsContext.Provider>
    );
};

export const useFactoringAndDepositPermissions = () => {
    const context = React.useContext(FactoringAndDepositPermissionsContext);
    if (context == 'uninitialized') throw new Error('soulja boy tellem');
    return context;
};

import { ExternalLinkIcon } from '@chakra-ui/icons';
import { Box, HStack, IconButton, Link, ModalBody, Stack, Text, VStack } from '@chakra-ui/react';
import { formatUnits } from 'ethers/lib/utils';
import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';
import { FiMinus, FiPlus } from 'react-icons/fi';
import { PoolEventInfo } from '../../../data-lib/data-model';
import { addressEquality } from '../../../data-lib/ethereum';
import { NETWORKS } from '../../../data-lib/networks';
import { useDataReadiness } from '../../../hooks/useUserData';
import { toDateWithTime } from '../../../tools/common';
import { CopyableTextLabel, shortAddress, TXHashLabel } from '../../base/address-label';
import { WithSkeleton } from '../../base/skeleton';
import { ChainSymbol } from '../../chain-symbol';
import { IPFSLink } from '../../inputs/attachment-input';
import { CloseModalButton } from '../common';
import { claimDetailVariants, ItemDesc } from './item-details-components';

interface TransactionRowProps {
    title: string;
    icon: React.ReactNode;
    amount: string;
    symbol: string;
    color: string;
}

const TransactionRow: React.FC<TransactionRowProps> = ({ title, icon, amount, symbol, color }) => (
    <>
        <Text fontSize={'15px'} fontWeight="600">
            {title}
        </Text>
        <HStack>
            {icon}
            <Text color={color} fontWeight="600">
                {amount}
            </Text>
            <Text>{symbol}</Text>
        </HStack>
    </>
);

interface PoolTransactionSummaryProps {
    poolEvent: PoolEventInfo;
    tokenDecimals: number;
}

const PoolTransactionSummary: React.FC<PoolTransactionSummaryProps> = ({ poolEvent, tokenDecimals }) => {
    const isDeposit = poolEvent.__type === 'PoolDeposit';
    const [action, subtractedAmount, subtractedSymbol, addedAmount, addedSymbol] = isDeposit
        ? ['Deposited', poolEvent.paidAmount, poolEvent.tokenInfo.token.symbol, poolEvent.shares, poolEvent.poolTokenInfo.token.symbol]
        : ['Redeemed', poolEvent.shares, poolEvent.poolTokenInfo.token.symbol, poolEvent.paidAmount, poolEvent.tokenInfo.token.symbol];

    return (
        <Box borderRadius={'xl'} p="5" border="1px solid" borderColor={'gray.200'} my="2" shadow={'sm'}>
            <VStack spacing="4" alignItems="flex-start">
                <TransactionRow
                    title={action}
                    icon={<FiMinus color="#a53124" width={'50px'} />}
                    amount={formatUnits(subtractedAmount, tokenDecimals)}
                    symbol={subtractedSymbol}
                    color="#a53124"
                />
                <TransactionRow
                    title="Received"
                    icon={<FiPlus color="#3b7a52" />}
                    amount={formatUnits(addedAmount, tokenDecimals)}
                    symbol={addedSymbol}
                    color="#3b7a52"
                />
                <Text fontSize={'15px'} fontWeight="600">
                    Price Per Share
                </Text>
                <Text>$ {formatUnits(poolEvent.priceAfterTransaction, tokenDecimals)}</Text>
            </VStack>
        </Box>
    );
};

export type PoolTransactionDetailsProps = {
    poolTransaction: PoolEventInfo;
    handleClose: () => void;
    modalContentRef?: React.RefObject<HTMLDivElement>;
};

export const PoolTransactionDetails = ({ poolTransaction, handleClose }: PoolTransactionDetailsProps) => {
    const isDeposit = poolTransaction.__type === 'PoolDeposit';
    const { isChainInitialized } = useDataReadiness();
    const isLoading = !poolTransaction || !isChainInitialized(poolTransaction.chainId);
    const { blockExplorer } = NETWORKS[poolTransaction.chainId!];
    const bodyRef = React.useRef<HTMLDivElement>(null);
    const factoringConfig = NETWORKS[poolTransaction.chainId].factoringConfig?.find(x =>
        addressEquality(x.bullaFactoringToken.token.address, poolTransaction.poolAddress),
    );
    const poolName = factoringConfig!.poolName;
    const poolUnderlyingTokenInfo = factoringConfig!.poolUnderlyingToken;
    const poolUnderlyingTokenDecimals = poolUnderlyingTokenInfo!.token.decimals;

    return (
        <>
            <CloseModalButton onClose={handleClose} zIndex={'modal'} />
            <ModalBody pb={10} pt={4} ref={bodyRef} width="200%" px="0" overflowY="auto">
                <AnimatePresence initial={false}>
                    <motion.div
                        key="details"
                        style={{
                            width: `50%`,
                            display: 'inline-block',
                            float: 'left',
                            padding: '0 2em',
                            maxHeight: 'fit-content',
                            height: '100%',
                        }}
                        initial={'active'}
                        animate={'active'}
                        transition={{
                            x: { type: 'just' },
                        }}
                        variants={claimDetailVariants}
                    >
                        <WithSkeleton isLoading={isLoading} fixedWidth="16em" height="32px">
                            <Text color="gray.700" fontWeight={'700'} fontSize="24px" noOfLines={1} lineHeight="32px">
                                {isDeposit ? 'Deposit' : 'Redemption'} Details
                            </Text>
                        </WithSkeleton>

                        <Box h="16px" />
                        <Stack spacing="3">
                            <ItemDesc title="Pool Details">
                                <WithSkeleton isLoading={isLoading} fixedWidth="10em">
                                    <HStack>
                                        <Text>{poolName}</Text>
                                        <IconButton
                                            as={Link}
                                            href={`${blockExplorer}address/${poolTransaction.poolAddress}`}
                                            isExternal
                                            icon={<ExternalLinkIcon />}
                                            aria-label="Open in explorer"
                                            size="l"
                                            variant="ghost"
                                        />
                                    </HStack>
                                </WithSkeleton>
                            </ItemDesc>
                            <ItemDesc title="Tx Hash">
                                <WithSkeleton isLoading={isLoading} fixedWidth="6em">
                                    <HStack>
                                        <TXHashLabel
                                            fontWeight={700}
                                            blockExplorerURL={blockExplorer}
                                            txHash={poolTransaction.txHash}
                                            as="b"
                                        />
                                    </HStack>
                                </WithSkeleton>
                            </ItemDesc>
                            <ItemDesc title="Pool Address">
                                <WithSkeleton isLoading={isLoading} fixedWidth="10em">
                                    <CopyableTextLabel
                                        displayValue={shortAddress(poolTransaction.poolAddress)}
                                        children={poolTransaction.poolAddress}
                                        variant="address"
                                    />
                                </WithSkeleton>
                            </ItemDesc>
                            <ItemDesc title="Chain">
                                <WithSkeleton isLoading={isLoading} fixedWidth="10em">
                                    {poolTransaction && (
                                        <HStack>
                                            <ChainSymbol chainId={poolTransaction.chainId} />
                                            <Text>{NETWORKS[poolTransaction.chainId].label}</Text>
                                        </HStack>
                                    )}
                                </WithSkeleton>
                            </ItemDesc>
                            <ItemDesc title="Date">
                                <WithSkeleton isLoading={isLoading} fixedWidth="10em">
                                    {poolTransaction && toDateWithTime(poolTransaction.created)}
                                </WithSkeleton>
                            </ItemDesc>

                            {poolTransaction.ipfsHash && (
                                <ItemDesc title="Attachment">
                                    <WithSkeleton isLoading={isLoading} fixedWidth="6em">
                                        <IPFSLink ipfsHash={poolTransaction.ipfsHash} />
                                    </WithSkeleton>
                                </ItemDesc>
                            )}
                        </Stack>
                        <Box h="2" />
                        <PoolTransactionSummary poolEvent={poolTransaction} tokenDecimals={poolUnderlyingTokenDecimals} />
                    </motion.div>
                </AnimatePresence>
            </ModalBody>
        </>
    );
};

import {
    BullaSwapInfo,
    ClaimInfo,
    FrendLendInfo,
    ImportedExternalTransactionInfo,
    InstantPaymentInfo,
    PoolDepositInfo,
    PoolRedemptionInfo,
} from '../data-lib/data-model';
import { ExternalTransactionDTO, TransferMetadata } from '../data-lib/dto/external-transactions-dto';
import { TxHashBitmap } from '../tools/common';
import { BullaItemInfo } from '../data-lib/data-model';
import { BullaItemEvent } from '../data-lib/data-transforms';
import { ChainId } from '../data-lib/networks';

export type UserData = {
    bullaItemEvents: BullaItemEvent[];
    userClaims: ClaimInfo[];
    instantPayments: InstantPaymentInfo[];
    poolDeposits: PoolDepositInfo[];
    poolRedemptions: PoolRedemptionInfo[];
    swaps: BullaSwapInfo[];
    userBatchesByTxHash: Record<string, BullaItemInfo[]>;
    financeAcceptedClaimOriginatingClaimIds: string[];
    frendLends: FrendLendInfo[];
    importedExternalTxs: ImportedExternalTransactionInfo[];
    nonImportedExternalTxs: ExternalTransactionDTO[];
    importFetched: boolean;
    bullaTxHashBitmap: TxHashBitmap;
    refetch: () => Promise<void>;
    refetchExternalTransactions: (chainId?: ChainId) => Promise<void>;
    bullaItemMetadataById: Record<string, TransferMetadata>;
    fetched: boolean;
};

export const emptyUserData: UserData = {
    userClaims: [],
    instantPayments: [],
    poolDeposits: [],
    poolRedemptions: [],
    swaps: [],
    userBatchesByTxHash: {},
    frendLends: [],
    importedExternalTxs: [],
    nonImportedExternalTxs: [],
    importFetched: false,
    refetch: async () => {},
    refetchExternalTransactions: async () => {},
    bullaItemEvents: [],
    financeAcceptedClaimOriginatingClaimIds: [],
    bullaTxHashBitmap: {},
    bullaItemMetadataById: {},
    fetched: false,
};

import { ApolloClient, gql, InMemoryCache } from '@apollo/client';
import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { ClaimInfo, FinancingState, PoolDepositInfo, PoolRedemptionInfo } from '../data-lib/data-model';
import {
    BullaItemEvent,
    categorizeBullaItemEvents,
    ClaimLogData,
    getClaimLogData,
    getPoolEventInfo,
    mapToClaimInfo,
} from '../data-lib/data-transforms';
import { ClaimCreatedEvent } from '../data-lib/domain/bulla-claim-domain';
import {
    InvoiceFundedEvent,
    InvoiceKickbackAmountSentEvent,
    InvoiceReconciledEvent,
    InvoiceUnfactoredEvent,
} from '../data-lib/domain/factoring-domain';
import { getClaimCreatedEvents } from '../data-lib/dto/event-filters';
import { PoolEvent } from '../data-lib/dto/mapped-event-types';
import {
    Claim__graphql,
    DepositMadeEvent__graphql,
    InvoiceFundedEvent__graphql,
    InvoiceKickbackAmountSentEvent__graphql,
    InvoiceReconciledEvent__graphql,
    InvoiceUnfactoredEvent__graphql,
    SharesRedeemedEvent__graphql,
} from '../data-lib/graphql/graph-domain';
import { mapClaimLogGraphEventsToClaimEvents, mapGraphEventToEvent } from '../data-lib/graphql/graphql-dto';
import { CLAIM_FIELDS, FACTORING_EVENTS_QUERY } from '../data-lib/graphql/userQuery';
import { sortBlocknumAsc } from '../data-lib/helpers';
import { ChainId, NETWORKS } from '../data-lib/networks';
import { getFactoringConfigsByChainId } from './useFactoringAndDepositPermissions';
import { useTokenRepo } from './useTokenRepo';
import { useActingWalletAddress } from './useWalletAddress';
import { useWeb3 } from './useWeb3';

const POOL_ACTIVITY_QUERY = gql`
    query GetPoolDepositsAndRedemptions($poolAddress: String!, $first: Int!, $skip: Int!) {
        depositMadeEvents(where: { poolAddress: $poolAddress }, first: $first, skip: $skip) {
            ${FACTORING_EVENTS_QUERY}
        }
        sharesRedeemedEvents(where: { poolAddress: $poolAddress }, first: $first, skip: $skip) {
            ${FACTORING_EVENTS_QUERY}
        }
        invoiceFundedEvents(where: { poolAddress: $poolAddress }, first: $first, skip: $skip) {
            ... on InvoiceFundedEvent {
                id
                invoiceId
                fundedAmount
                originalCreditor
                eventName
                blockNumber
                transactionHash
                logIndex
                timestamp
                poolAddress
                targetInterest
                targetProtocolFee
                targetAdminFee
                targetTax
                priceAfterTransaction
                claim {
                    ${CLAIM_FIELDS()}
                }
            }
        }
        invoiceReconciledEvents(where: { poolAddress: $poolAddress }, first: $first, skip: $skip) {
            ... on InvoiceReconciledEvent {
                id
                invoiceId
                eventName
                blockNumber
                transactionHash
                logIndex
                timestamp
                poolAddress
                trueInterest
                trueProtocolFee
                trueAdminFee
                trueTax
                priceAfterTransaction
                claim {
                    ${CLAIM_FIELDS()}
                }
            }
        }
        invoiceKickbackAmountSentEvents(where: { poolAddress: $poolAddress }, first: $first, skip: $skip) {
            ... on InvoiceKickbackAmountSentEvent {
                id
                invoiceId
                kickbackAmount
                originalCreditor
                eventName
                blockNumber
                transactionHash
                logIndex
                timestamp
                poolAddress
                priceAfterTransaction
                claim {
                    ${CLAIM_FIELDS()}
                }
            }
        }
        invoiceUnfactoredEvents(where: { poolAddress: $poolAddress }, first: $first, skip: $skip) {
            ... on InvoiceUnfactoredEvent {
                id
                invoiceId
                originalCreditor
                eventName
                blockNumber
                transactionHash
                logIndex
                timestamp
                totalRefundAmount
                interestToCharge
                trueInterest
                trueProtocolFee
                trueAdminFee
                trueTax
                poolAddress
                priceAfterTransaction
                claim {
                    ${CLAIM_FIELDS()}
                }
            }
        }
    }
`;

interface PoolDetailsContextType {
    poolDeposits: PoolDepositInfo[];
    poolRedemptions: PoolRedemptionInfo[];
    factoredInvoices: ClaimInfo[];
}

const PoolDetailsContext = createContext<PoolDetailsContextType | undefined>(undefined);

interface PoolActivityQueryResult {
    depositMadeEvents: DepositMadeEvent__graphql[];
    sharesRedeemedEvents: SharesRedeemedEvent__graphql[];
    invoiceFundedEvents: InvoiceFundedEvent__graphql[];
    invoiceKickbackAmountSentEvents: InvoiceKickbackAmountSentEvent__graphql[];
    invoiceUnfactoredEvents: InvoiceUnfactoredEvent__graphql[];
    invoiceReconciledEvents: InvoiceReconciledEvent__graphql[];
}

export const PoolDetailsProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [poolDetails, setPoolDetails] = useState<PoolDetailsContextType>({
        poolDeposits: [],
        poolRedemptions: [],
        factoredInvoices: [],
    });
    const { resolveTokenInfo } = useTokenRepo();
    const { connectedNetwork } = useWeb3();
    const userAddress = useActingWalletAddress();

    useEffect(() => {
        const fetchPoolDetails = async () => {
            const factoringConfigsByChainId = getFactoringConfigsByChainId();
            let allPoolDeposits: PoolDepositInfo[] = [];
            let allPoolRedemptions: PoolRedemptionInfo[] = [];
            let allFactoredInvoices: ClaimInfo[] = [];

            for (const [chainId, factoringConfigs] of Object.entries(factoringConfigsByChainId)) {
                const networkId = parseInt(chainId);
                const network = NETWORKS[networkId];
                if (!network || !network.factoringConfig) continue;

                const client = new ApolloClient({
                    uri: network.connections.graphEndpoint,
                    cache: new InMemoryCache(),
                });

                for (const factoringConfig of factoringConfigs) {
                    const factoringAddress = factoringConfig.bullaFactoringToken.token.address;
                    let allEvents: (
                        | DepositMadeEvent__graphql
                        | SharesRedeemedEvent__graphql
                        | InvoiceFundedEvent__graphql
                        | InvoiceKickbackAmountSentEvent__graphql
                        | InvoiceUnfactoredEvent__graphql
                        | InvoiceReconciledEvent__graphql
                    )[] = [];

                    let skip = 0;
                    const first = 1000;

                    while (true) {
                        const { data } = await client.query<PoolActivityQueryResult>({
                            query: POOL_ACTIVITY_QUERY,
                            variables: { poolAddress: factoringAddress.toLowerCase(), first, skip },
                            fetchPolicy: 'network-only',
                        });

                        const newEvents = [
                            ...(data.depositMadeEvents || []),
                            ...(data.sharesRedeemedEvents || []),
                            ...(data.invoiceFundedEvents || []),
                            ...(data.invoiceKickbackAmountSentEvents || []),
                            ...(data.invoiceUnfactoredEvents || []),
                            ...(data.invoiceReconciledEvents || []),
                        ];

                        allEvents = [...allEvents, ...newEvents];

                        if (newEvents.length < first) {
                            break;
                        }

                        skip += first;
                    }

                    const mappedPoolDepositAndRedemptionEvents = allEvents
                        .map(mapGraphEventToEvent)
                        .filter((event): event is PoolEvent => event !== null);
                    const { poolDeposits, poolRedemptions } = await getPoolEventInfo(
                        mappedPoolDepositAndRedemptionEvents,
                        resolveTokenInfo,
                        networkId as ChainId,
                    );

                    const factoringEventsAll: (
                        | InvoiceFundedEvent__graphql
                        | InvoiceKickbackAmountSentEvent__graphql
                        | InvoiceUnfactoredEvent__graphql
                        | InvoiceReconciledEvent__graphql
                    )[] = allEvents.filter(
                        (
                            event,
                        ): event is
                            | InvoiceFundedEvent__graphql
                            | InvoiceKickbackAmountSentEvent__graphql
                            | InvoiceUnfactoredEvent__graphql
                            | InvoiceReconciledEvent__graphql =>
                            event.__typename === 'InvoiceFundedEvent' ||
                            event.__typename === 'InvoiceReconciledEvent' ||
                            event.__typename === 'InvoiceKickbackAmountSentEvent' ||
                            event.__typename === 'InvoiceUnfactoredEvent',
                    );

                    const mappedInvoiceFundedEvents = factoringEventsAll
                        .map(event => {
                            const mappedEvent = mapGraphEventToEvent(event);
                            if (
                                mappedEvent.__typename === 'InvoiceFundedEvent' ||
                                mappedEvent.__typename === 'InvoiceReconciledEvent' ||
                                mappedEvent.__typename === 'InvoiceKickbackAmountSentEvent' ||
                                mappedEvent.__typename === 'InvoiceUnfactoredEvent'
                            ) {
                                return mappedEvent;
                            }
                            return null;
                        })
                        .filter(
                            (
                                event,
                            ): event is
                                | InvoiceFundedEvent
                                | InvoiceReconciledEvent
                                | InvoiceKickbackAmountSentEvent
                                | InvoiceUnfactoredEvent => event !== null,
                        );

                    const [, claims] = factoringEventsAll.reduce<[string[], Claim__graphql[]]>(
                        (acc, event) =>
                            acc[0].includes(event.claim.tokenId)
                                ? acc
                                : [
                                      [...acc[0], event.claim.tokenId],
                                      [...acc[1], event.claim],
                                  ],
                        [[], []],
                    );

                    const allClaimLogs = claims.flatMap(claim => {
                        const graphClaimLogs = claim.logs || [];
                        return mapClaimLogGraphEventsToClaimEvents(graphClaimLogs);
                    });

                    const allLogs = [...allClaimLogs, ...mappedInvoiceFundedEvents].filter(claimLog => {
                        if (!claimLog) return false;
                        const isNotYourTag = claimLog.__typename === 'BullaTagUpdatedEvent';
                        return !isNotYourTag;
                    });

                    const eventsASC = Object.values(
                        allLogs.sort(sortBlocknumAsc).reduce<{ [uniqueKey: string]: BullaItemEvent }>(
                            (logs, log) => ({
                                ...logs,
                                [log.txHash + log.logIndex + (log.__typename == 'InvoiceReconciledEvent' ? log.tokenId : '')]: log,
                            }),
                            {},
                        ),
                    );

                    const { claimEvents, factoringEvents } = categorizeBullaItemEvents(eventsASC);

                    const claimEventsWithRelatedFactoringEvents = [...claimEvents, ...factoringEvents];

                    // @notice we assume there are no loan events related to factored invoices
                    const claimLogData: ClaimLogData = getClaimLogData(claimEventsWithRelatedFactoringEvents, [], userAddress);

                    const getBullaClaimInfo = (claimEvent: ClaimCreatedEvent) => {
                        const claimId = claimEvent.tokenId;

                        const financingState: FinancingState = (() => {
                            const acceptedFinancing = claimLogData.financingAccepted[claimId];
                            if (acceptedFinancing !== undefined) {
                                return { kind: 'accepted', ...acceptedFinancing };
                            } else {
                                const offeredFinancing = claimLogData.financingOffers[claimId];
                                if (offeredFinancing !== undefined) {
                                    return { kind: 'offered', terms: offeredFinancing };
                                } else return { kind: 'no-financing' };
                            }
                        })();

                        return mapToClaimInfo({
                            chainId: networkId as ChainId,
                            resolveTokenInfo,
                            claimEvent,
                            userAddress: userAddress,
                            claimTransfers: claimLogData.claimTransfers[claimId],
                            bullaTag: claimLogData.bullaTags[claimId],
                            logs: claimLogData.claimEvents[claimId] ?? [],
                            owners: [],
                            financingState,
                        });
                    };

                    const userClaims: ClaimInfo[] = await Promise.all(getClaimCreatedEvents(claimEvents).map(getBullaClaimInfo));

                    allPoolDeposits = [...allPoolDeposits, ...poolDeposits];
                    allPoolRedemptions = [...allPoolRedemptions, ...poolRedemptions];
                    allFactoredInvoices = [...allFactoredInvoices, ...userClaims];
                }
            }

            setPoolDetails({ poolDeposits: allPoolDeposits, poolRedemptions: allPoolRedemptions, factoredInvoices: allFactoredInvoices });
        };

        fetchPoolDetails();
    }, [connectedNetwork, resolveTokenInfo]);

    return <PoolDetailsContext.Provider value={poolDetails}>{children}</PoolDetailsContext.Provider>;
};

export const usePoolDetailsRepo = () => {
    const context = useContext(PoolDetailsContext);
    if (context === undefined) {
        throw new Error('usePoolDetailsRepo must be used within a PoolDetailsProvider');
    }
    return context;
};

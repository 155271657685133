import React from 'react';
import { MaxWidthWrapper, PageLayoutProvider } from '../../components/layout/page-layout';
import { Box, Flex, Heading } from '@chakra-ui/react';
import { PoolCard } from '../../components/factoring/factoring-pool-card';
import { FactoringConfig, NetworkConfig } from '../../data-lib/networks';
import { useFactoringAndDepositPermissions } from '../../hooks/useFactoringAndDepositPermissions';
import { useNavigate } from 'react-router-dom';

export function hasFactoringConfig(
    network: NetworkConfig,
): network is NetworkConfig & { factoringConfig: NonNullable<NetworkConfig['factoringConfig']> } {
    return network.factoringConfig !== undefined;
}

export const FactoringPoolsList = () => {
    const { poolsWithPermissions } = useFactoringAndDepositPermissions();

    const navigate = useNavigate();

    const handleViewPoolDetails = (factoringConfig: FactoringConfig) => {
        const poolAddress = factoringConfig.bullaFactoringToken.token.address;
        const chainId = factoringConfig.bullaFactoringToken.chainId.toString();
        navigate(`/factoring/pools/${poolAddress}-${chainId}`);
    };

    return (
        <PageLayoutProvider>
            <Flex p={{ sm: '8', md: '12' }} bg={'gray.25'} direction="column" flex="1">
                <MaxWidthWrapper>
                    <Flex justifyContent="space-between" alignItems="center">
                        <Heading color="heading" flex={1}>
                            Bulla Finance Pool List
                        </Heading>
                    </Flex>
                    <Box h="8" />
                    {poolsWithPermissions.map(pool => (
                        <PoolCard
                            key={pool.chainId}
                            factoringConfig={pool.factoringConfig}
                            hasDepositPermissions={pool.hasDepositPermissions}
                            onViewPoolDetails={() => handleViewPoolDetails(pool.factoringConfig)}
                        />
                    ))}
                </MaxWidthWrapper>
            </Flex>
        </PageLayoutProvider>
    );
};

import { Progress, HStack, VStack, Box, Icon, BoxProps, Text } from '@chakra-ui/react';
import React from 'react';

export type Step<T extends string> = { icon: React.ElementType; label: string; step: T };
interface ProgressStepsProps<T extends string> {
    currentStep: T;
    mapStepToProgress: (step: T) => number;
    steps: Step<T>[];
}

interface IconBoxProps extends BoxProps {
    icon: React.ElementType;
}

export const IconBox: React.FC<IconBoxProps> = ({ icon, boxShadow = 'md', ...props }) => (
    <Box
        bg="white"
        p={3}
        borderRadius="xl"
        boxShadow={boxShadow}
        color="gray.700"
        border="1px solid"
        borderColor="gray.200"
        display="flex"
        alignItems="center"
        justifyContent="center"
        {...props}
    >
        <Icon as={icon} boxSize={6} />
    </Box>
);

export const ProgressSteps = <T extends string>({ currentStep, mapStepToProgress, steps }: ProgressStepsProps<T>) => {
    return (
        <Box width="100%" maxW="600px" position="relative" mt="20">
            <Progress value={mapStepToProgress(currentStep)} height="2px" width="100%" colorScheme="orange" />
            <HStack justify="space-between" position="absolute" width="120%" top="-21px" left="-40px">
                {steps.map((item, index) => (
                    <VStack key={index}>
                        <IconBox icon={item.icon} />
                        <Text
                            fontSize="md"
                            color={currentStep === item.step ? 'gray.700' : 'gray.400'}
                            mt="2"
                            fontWeight={currentStep === item.step ? '600' : '400'}
                        >
                            {item.label}
                        </Text>
                    </VStack>
                ))}
            </HStack>
        </Box>
    );
};

import React from 'react';
import { MaxWidthWrapper, PageLayoutProvider } from '../../components/layout/page-layout';
import { Box, Divider, Flex, Heading, Icon, Text, Image } from '@chakra-ui/react';
import { FiArrowDown, FiArrowUpRight, FiFileText } from 'react-icons/fi';
import { TextButton } from '../../components/inputs/buttons';
import ExcelFile from '../../assets/excel-icon.svg';
import WordFile from '../../assets/word-icon.svg';
import { useNavigate } from 'react-router-dom';
import { enable1099wizard, hideRecentReportsTable } from '../../tools/featureFlags';

type ReportItems = {
    name: string;
    lastAccessed: string;
};

export const ReportingBadge = ({
    children,
    backgroundColor = '#FCEDE2',
    color = '#DF5907',
    borderColor = '#DF5907',
    borderRadius = '3xl',
    ml = '3',
}: {
    children: React.ReactNode;
    backgroundColor?: string;
    color?: string;
    borderColor?: string;
    borderRadius?: string;
    ml?: string;
}) => (
    <Box
        as="span"
        px={3}
        py={1}
        ml={ml}
        fontSize="md"
        fontWeight="semibold"
        backgroundColor={backgroundColor}
        color={color}
        borderColor={borderColor}
        borderWidth="1px"
        borderRadius={borderRadius}
    >
        {children}
    </Box>
);

const ReportingCard = ({
    title,
    description,
    action,
    badge,
    imageSrc,
    onClick,
}: {
    title: string;
    description: string;
    action: string;
    badge: string;
    imageSrc: string;
    onClick?: () => void;
}) => {
    const isComingSoon = badge === 'Coming Soon';

    return (
        <Box borderWidth="1px" borderRadius="lg" bg="white" boxShadow="sm" width="full">
            <Box p={6} minH="130px">
                <Flex alignItems="center" mb={2}>
                    <Image h="31px" w="31px" src={imageSrc} mr="3" />
                    <Heading size="lg" fontWeight="semibold">
                        {title}
                    </Heading>
                    <ReportingBadge>{badge}</ReportingBadge>
                </Flex>
                <Text color="gray.600">{description}</Text>
            </Box>
            <Divider />
            <Box
                p={4}
                cursor={isComingSoon ? 'not-allowed' : 'pointer'}
                opacity={isComingSoon ? 0.5 : 1}
                onClick={!isComingSoon ? onClick : undefined}
            >
                <Flex alignItems="center" color="orange.500" fontWeight="semibold" justifyContent="flex-end" fontSize="lg">
                    {action}
                    <Icon as={FiArrowUpRight} ml={1} />
                </Flex>
            </Box>
        </Box>
    );
};

const ReportTable = () => {
    const reports: ReportItems[] = [];

    return (
        <Box borderWidth="1px" borderRadius="lg" bg="white" boxShadow="sm" width="full">
            <Flex alignItems="center" px={4} py={4} bg="gray.50" color="gray.600" fontSize="md">
                <Text fontWeight="semibold" flex={3}>
                    Report
                </Text>

                <Flex alignItems="center">
                    <Text fontWeight="semibold">Last Date Accessed</Text>
                    <Icon as={FiArrowDown} ml={1} />
                </Flex>

                <Box width="200px" />
            </Flex>
            {reports.length > 0 ? (
                reports.map((report, index) => (
                    <Flex key={index} alignItems="center" py={3} px={6} borderTopWidth={1} borderColor="gray.200">
                        <Flex alignItems="center" flex={3} py="2">
                            <Image h="41px" w="41px" src={ExcelFile} mr="5" />
                            <Text color="gray.900" fontWeight={'500'}>
                                {report.name}
                            </Text>
                        </Flex>
                        <Text width="200px" textAlign="center" color="gray.600">
                            {report.lastAccessed}
                        </Text>
                        <Flex justifyContent="flex-end" width="200px" gap="5">
                            <TextButton fontWeight="600" color="gray.600" textDecoration={'initial'} w="fit-content" onClick={() => null}>
                                Open
                            </TextButton>
                            <TextButton
                                fontWeight="600"
                                color="brand.bulla_orange"
                                textDecoration={'initial'}
                                w="fit-content"
                                onClick={() => null}
                            >
                                Download
                            </TextButton>
                        </Flex>
                    </Flex>
                ))
            ) : (
                <Flex direction="column" alignItems="center" justifyContent="center" py={16} px={4}>
                    <Icon as={FiFileText} boxSize={12} color="gray.300" mb={4} />
                    <Text fontSize="xl" fontWeight="medium" color="gray.600" mb={2}>
                        No reports available
                    </Text>
                    <Text fontSize="md" color="gray.500" textAlign="center">
                        Your generated reports will appear here once they're ready.
                    </Text>
                </Flex>
            )}
        </Box>
    );
};

export const ReportingExplorer = () => {
    const navigate = useNavigate();

    return (
        <PageLayoutProvider>
            <Flex p={{ sm: '8', md: '12' }} bg={'gray.25'} direction="column" flex="1">
                <MaxWidthWrapper>
                    <Flex justifyContent="space-between" alignItems="center">
                        <Heading color="heading" flex={1}>
                            Reporting Explorer
                        </Heading>
                    </Flex>
                    <Box h="8" />
                    <Flex direction="row" gap={6}>
                        <ReportingCard
                            title="Ledger Export"
                            description="Export CSVs for receivables, payables, and cash disbursements and import them into your general ledger."
                            action="Start your export"
                            onClick={() => navigate('/ledger-wizard')}
                            badge="New"
                            imageSrc={ExcelFile}
                        />
                        <ReportingCard
                            title="1099 Tax Prep"
                            description="Generate organized 1099 tax data for individual wallets."
                            action="Start your 1099s"
                            onClick={() => enable1099wizard && navigate('/1099-wizard')}
                            badge={enable1099wizard ? 'New' : 'Coming Soon'}
                            imageSrc={WordFile}
                        />
                        <ReportingCard
                            title="8949 Tax Prep"
                            description="Crypto gains and losses."
                            action="Start your 8949"
                            badge="Coming Soon"
                            imageSrc={WordFile}
                        />
                    </Flex>
                    <Box h="8" />
                    {!hideRecentReportsTable && <ReportTable />}
                </MaxWidthWrapper>
            </Flex>
        </PageLayoutProvider>
    );
};

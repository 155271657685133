import { gql } from '@apollo/client';
import { ClaimStatus } from '../data-model';
import { BullaItemEvent } from '../data-transforms';
import { HumaFactorEvent } from '../domain/common-domain';
import { addressEquality, EthAddress } from '../ethereum';
import {
    ClaimLog__graphql,
    ClaimType__graphql,
    DepositMadeEvent__graphql,
    FinancingAcceptedEvent__graphql,
    FinancingOfferedEvent__graphql,
    FrendLendEvent__graphql,
    InstantPaymentLog__graphql,
    InstantPaymentTag__graphql,
    InvoiceFundedEvent__graphql,
    InvoiceKickbackAmountSentEvent__graphql,
    InvoiceReconciledEvent__graphql,
    InvoiceUnfactoredEvent__graphql,
    OrderCreatedEvent__graphql,
    OrderExecutedEvent__graphql,
    SharesRedeemedEvent__graphql,
    Token__graphql,
    User__graphql,
} from './graph-domain';
import { mapGraphEntitiesToBullaItemEvents, mapHumaGraphEntitiesToClaimPaymentEvents } from './graphql-dto';

export const getItemEventsFromUserQuery = ({ user }: UserQueryResult): BullaItemEvent[] => {
    const events = [
        ...(user?.claims?.flatMap(x => x.logs) ?? []),
        ...(user?.instantPayments?.flatMap(x => x.logs) ?? []),
        ...(user?.financeEvents ?? []),
        ...(user?.frendLendEvents ?? []),
        ...(user?.factoringEvents ?? []),
        ...(user?.swapEvents ?? []),
    ];

    return mapGraphEntitiesToBullaItemEvents(events).reduce<BullaItemEvent[]>((acc, claimLog) => {
        if (!claimLog) return acc;
        const isNotYourTag =
            (claimLog.__typename === 'BullaTagUpdatedEvent' || claimLog.__typename === 'InstantPaymentTagUpdatedEvent') &&
            !addressEquality(claimLog.updatedBy, user!.address);

        return isNotYourTag ? acc : [...acc, claimLog];
    }, []);
};

export type HumaQueryResult = {
    creditEvents: {
        owner: string;
        amount: string;
        event: number;
        tx: string;
        timestamp: string;
        receivableParam: string;
        netAmountToBorrower: string;
    }[];
};

export const getItemEventsFromHumaQuery = (humaQueryResult: HumaQueryResult): HumaFactorEvent[] => {
    return mapHumaGraphEntitiesToClaimPaymentEvents(humaQueryResult.creditEvents);
};

export type UserQueryResult = {
    user?: {
        id: EthAddress;
        address: EthAddress;
        instantPayments?: {
            from: Omit<User__graphql, 'claims'>;
            to: Omit<User__graphql, 'claims'>;
            amount: string;
            token: Token__graphql;
            description: string;
            tag: InstantPaymentTag__graphql[];
            ipfsHash: string | null;
            logs: InstantPaymentLog__graphql[];
            __typename: 'InstantPayment';
        }[];
        financeEvents?: (FinancingAcceptedEvent__graphql | FinancingOfferedEvent__graphql)[];
        frendLendEvents?: FrendLendEvent__graphql[];
        claims?: {
            accountTag: { tag: string; __typename: 'AccountTag' }[];
            amount: string;
            claimType: ClaimType__graphql;
            creator: Omit<User__graphql, 'claims'>;
            creditor: Omit<User__graphql, 'claims'>;
            debtor: Omit<User__graphql, 'claims'>;
            description: string;
            dueBy: string;
            ipfsHash: string | null;
            isTransferred: boolean;
            logs: ClaimLog__graphql[];
            paidAmount: string;
            status: ClaimStatus;
            token: Token__graphql;
            created: string;
            tokenId: string;
            transactionHash: string;
            __typename: 'Claim';
        }[];
        factoringEvents?: (
            | InvoiceFundedEvent__graphql
            | InvoiceReconciledEvent__graphql
            | InvoiceKickbackAmountSentEvent__graphql
            | InvoiceUnfactoredEvent__graphql
            | DepositMadeEvent__graphql
            | SharesRedeemedEvent__graphql
        )[];
        swapEvents?: (OrderCreatedEvent__graphql | OrderExecutedEvent__graphql)[];
        __typename: 'User';
    };
};

export const humaReceivableParamQuery = () => gql`
    query CreditEventsByPool($poolAddress: String!, $claimId: String!) {
        creditEvents(where: { pool: $poolAddress, receivableParam: $claimId }) {
            owner
            tx
            event
            timestamp
            amount
            __typename
            receivableParam
            netAmountToBorrower
        }
    }
`;

export const humaUserQuery = () => gql`
    query CreditEventsByPool($poolAddress: String!, $queryAddress: String!, $first: Int, $skip: Int) {
        creditEvents(first: $first, skip: $skip, where: { pool: $poolAddress, owner: $queryAddress }) {
            owner
            tx
            event
            timestamp
            amount
            __typename
            receivableParam
            netAmountToBorrower
        }
    }
`;

export const ORDER_EVENTS_QUERY = `
    ... on OrderCreatedEvent {
        id
        order {
            orderId
            expiry
            signerWallet
            signerToken {
                address
                decimals
                symbol
                network
            }
            signerAmount
            senderWallet
            senderToken {
                address
                decimals
                symbol
                network
            }
            senderAmount
        }
        sender
        signerWallet
        eventName
        blockNumber
        transactionHash
        logIndex
        timestamp
    }
    ... on OrderExecutedEvent {
        id
        order {
            orderId
            expiry
            signerWallet
            signerToken {
                address
                decimals
                symbol
                network
            }
            signerAmount
            senderWallet
            senderToken {
                address
                decimals
                symbol
                network
            }
            senderAmount
        }
        sender
        signerWallet
        eventName
        blockNumber
        transactionHash
        logIndex
        timestamp
    }
`;

export const FACTORING_CLAIM_EVENTS_QUERY = `... on InvoiceFundedEvent {
        id
        invoiceId
        fundedAmount
        originalCreditor
        eventName
        blockNumber
        transactionHash
        targetInterest
        targetProtocolFee
        targetAdminFee
        targetTax
        logIndex
        timestamp
        poolAddress
        priceAfterTransaction
    }
    ... on InvoiceKickbackAmountSentEvent {
        id
        invoiceId
        kickbackAmount
        originalCreditor
        eventName
        blockNumber
        transactionHash
        logIndex
        timestamp
        poolAddress
        priceAfterTransaction
    }
    ... on InvoiceUnfactoredEvent {
        id
        invoiceId
        originalCreditor
        eventName
        blockNumber
        transactionHash
        logIndex
        timestamp
        totalRefundAmount
        interestToCharge
        trueInterest
        trueProtocolFee
        trueAdminFee
        trueTax
        poolAddress
        priceAfterTransaction
    }
    ... on InvoiceReconciledEvent {
        id
        invoiceId
        trueInterest
        trueProtocolFee
        trueAdminFee
        trueTax
        priceAfterTransaction
        poolAddress
        eventName
        blockNumber
        transactionHash
        logIndex
        timestamp
        __typename
    }`;

export const FACTORING_EVENTS_QUERY = `
    ${FACTORING_CLAIM_EVENTS_QUERY}
    ... on DepositMadeEvent {
        id
        depositor
        assets
        sharesIssued
        poolAddress
        eventName
        blockNumber
        transactionHash
        logIndex
        timestamp
        priceAfterTransaction
    }
    ... on SharesRedeemedEvent {
        id
        redeemer
        assets
        shares
        poolAddress
        eventName
        blockNumber
        transactionHash
        logIndex
        timestamp
        priceAfterTransaction
    }
`;

export const CLAIM_FIELDS = () => `
    tokenId
    accountTag(where: { userAddress: $checksumAddress }) {
        tag
    }
    ipfsHash
    creator {
        id
        address
    }
    creditor {
        id
        address
    }
    debtor {
        id
        address
    }
    amount
    paidAmount
    isTransferred
    description
    created
    dueBy
    claimType
    token {
        address
        decimals
        symbol
        network
    }
    status
    transactionHash
    logs(first: 100) {
        eventName
        __typename
        transactionHash
        timestamp
        blockNumber
        logIndex
        ... on TransferEvent {
            from
            to
            tokenId
        }
        ... on ClaimCreatedEvent {
            bullaManager
            parent
            creator
            debtor
            creditor
            claimToken {
                address
                decimals
                symbol
                network
                isNative
            }
            claim {
                tokenId
            }
            description
            ipfsHash
            amount
            dueBy
        }
        ... on BullaTagUpdatedEvent {
            bullaManager
            updatedBy
            tag
            claim {
                tokenId
            }
        }
        ... on FeePaidEvent {
            bullaManager
            collectionAddress
            paymentAmount
            transactionFee
            claim {
                tokenId
            }
        }
        ... on ClaimPaymentEvent {
            bullaManager
            debtor
            paidBy
            paymentAmount
            claim {
                tokenId
            }
        }
        ... on ClaimRejectedEvent {
            managerAddress
            claim {
                tokenId
            }
        }
        ... on ClaimRescindedEvent {
            bullaManager
            claim {
                tokenId
            }
        }
    }
`;

export const userQuery = (
    includeFinanceEvents: boolean,
    includeFrendLendEvents: boolean,
    includeFactoringEvents: boolean,
    includeSwapEvents: boolean,
) => gql`
    # query address must be lowercase (not checksummed) as IDs are stored as lowercase strings
    query UserData($queryAddress: String, $checksumAddress: Bytes, $first: Int, $skip: Int) {
        user(id: $queryAddress) {
            id
            address
            instantPayments(first: $first, skip: $skip) {
                from {
                    id
                    address
                }
                to {
                    id
                    address
                }
                amount
                token {
                    address
                    decimals
                    symbol
                    isNative
                }
                description
                tag(where: { updatedBy: $queryAddress }) {
                    updatedBy {
                        address
                    }
                    tag
                }
                ipfsHash
                logs(first: 100, orderBy: timestamp) {
                    ... on InstantPaymentEvent {
                        from {
                            address
                        }
                        to {
                            address
                        }
                        amount
                        token {
                            address
                        }
                        description
                        tag
                        ipfsHash
                    }
                    ... on InstantPaymentTagUpdatedEvent {
                        updatedBy {
                            address
                        }
                        instantPayment {
                            id
                        }
                        tag
                    }
                    eventName
                    blockNumber
                    transactionHash
                    logIndex
                    timestamp
                }
            }
            ${
                includeFinanceEvents
                    ? `financeEvents(first: $first, skip: $skip) {
                ... on FinancingOfferedEvent {
                    originatingClaimId {
                        tokenId
                    }
                    minDownPaymentBPS
                    interestBPS
                    termLength
                    eventName
                    blockNumber
                    transactionHash
                    logIndex
                    timestamp
                    __typename
                }
                ... on FinancingAcceptedEvent {
                    originatingClaimId {
                        tokenId
                    }
                    financedClaimId {
                        tokenId
                    }
                    eventName
                    blockNumber
                    transactionHash
                    logIndex
                    timestamp
                    __typename
                }
            }`
                    : ''
            }
            ${
                includeFrendLendEvents
                    ? `frendLendEvents(first: $first, skip: $skip) {
                ... on LoanOfferedEvent {
                    loanId
                    offeredBy
                    interestBPS
                    termLength
                    loanAmount
                    creditor
                    debtor
                    description
                    claimToken {
                        address
                    }
                    ipfsHash
                    eventName
                    blockNumber
                    transactionHash
                    logIndex
                    timestamp
                    __typename
                }
                ... on LoanOfferAcceptedEvent {
                    loanId
                    claimId {
                        id
                    }
                    eventName
                    blockNumber
                    transactionHash
                    logIndex
                    timestamp
                    __typename
                    
                }
                ... on LoanOfferRejectedEvent {
                    loanId
                    rejectedBy
                    eventName
                    blockNumber
                    transactionHash
                    logIndex
                    timestamp
                    __typename
                }
            }`
                    : ''
            }
            ${
                includeFactoringEvents
                    ? `factoringEvents(first: $first, skip: $skip) {
                            ${FACTORING_EVENTS_QUERY}
                        }`
                    : ''
            }
            ${
                includeSwapEvents
                    ? `swapEvents(first: $first, skip: $skip) {
                ${ORDER_EVENTS_QUERY}
            }`
                    : ''
            }
            claims(first: $first, skip: $skip) {
                ${CLAIM_FIELDS()}
            }
        }
    }
`;

export const specificClaimQuery = (includeFactoringEvents: boolean) => gql`
    query ClaimData($id: String) {
        claim(id: $id) {
            ${CLAIM_FIELDS()}
        }
        ${
            includeFactoringEvents
                ? `
        invoiceFundedEvents(where: {invoiceId: $id}) {
            ${FACTORING_CLAIM_EVENTS_QUERY}
        }
        invoiceKickbackAmountSentEvents(where: {invoiceId: $id}) {
            ${FACTORING_CLAIM_EVENTS_QUERY}
        }
        invoiceUnfactoredEvents(where: {invoiceId: $id}) {
            ${FACTORING_CLAIM_EVENTS_QUERY}
        }
        `
                : ''
        }
    }
`;

export const specificLoanOfferEvent = gql`
    query LoanOffered($id: String) {
        loanOfferedEvent(id: $id) {
            id
            loanId
            offeredBy
            interestBPS
            termLength
            loanAmount
            creditor
            debtor
            description
            claimToken {
                address
            }
            ipfsHash
            eventName
            blockNumber
            transactionHash
            logIndex
            timestamp
            __typename
        }
    }
`;

export const specificInstantPaymentQuery = gql`
    query InstantPaymentData($id: String) {
        instantPayment(id: $id) {
            from {
                id
                address
            }
            to {
                id
                address
            }
            amount
            token {
                address
                decimals
                symbol
                isNative
            }
            description
            tag(where: { updatedBy: $queryAddress }) {
                updatedBy {
                    address
                }
                tag
            }
            ipfsHash
            logs(first: 100, orderBy: timestamp) {
                ... on InstantPaymentEvent {
                    from {
                        address
                    }
                    to {
                        address
                    }
                    amount
                    token {
                        address
                    }
                    description
                    tag
                    ipfsHash
                }
                ... on InstantPaymentTagUpdatedEvent {
                    updatedBy {
                        address
                    }
                    instantPayment {
                        id
                    }
                    tag
                }
                eventName
                blockNumber
                transactionHash
                logIndex
                timestamp
            }
        }
    }
`;

import { Center, Flex, HStack, Spacer, Text } from '@chakra-ui/react';
import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { WarningIcon } from '../../assets/warning';
import { ContactNameOrAddress } from '../../components/base/address-label';
import { ChainSymbol } from '../../components/chain-symbol';
import { TokenAmount } from '../../components/currency/token-display-amount';
import { PageSelector } from '../../components/display/claim-table';
import { AccountTagViewer } from '../../components/inputs/account-tag-input';
import { SecondaryButton, ViewDetailsButton } from '../../components/inputs/buttons';
import { ListItemProps, ListViewCard, ShadowBox, TwoLineTextWithLabel } from '../../components/layout/cards';
import { UpgradeButton } from '../../components/layout/header';
import { MaxWidthWrapper, PageLayoutProvider } from '../../components/layout/page-layout';
import { CreateItemLinkHandler } from '../../components/modals/create-claim-modal/create-link-handler';
import { BullaLineItems } from '../../data-lib/data-model';
import { addressEquality } from '../../data-lib/ethereum';
import { isClaim, sortDesc } from '../../data-lib/helpers';
import { FullMembershipInfoDto, useAuthApi } from '../../hooks/useAuthApi';
import { useOpenBullaItem } from '../../hooks/useClaimDetailDisclosure';
import { useIsMobile } from '../../hooks/useIsMobile';
import { useMembership } from '../../hooks/useMembership';
import { usePagination } from '../../hooks/usePagination';
import { useGlobalUserData } from '../../hooks/useUserData';
import { useActingWalletAddress } from '../../hooks/useWalletAddress';
import { toDateDisplay } from '../../tools/common';

const formatDate = (timestamp: number | undefined): string => {
    if (!timestamp) return 'N/A';

    const date = new Date(timestamp * 1000);
    const day = date.getDate().toString().padStart(2, '0');
    const month = date.toLocaleString('en-US', { month: 'short' });
    const year = date.getFullYear();

    return `${day} ${month} ${year}`;
};

const CallToActionCard = () => {
    const navigate = useNavigate();
    const premiumMembership = useMembership();
    const [mainMembership, setMainMembership] = useState<FullMembershipInfoDto | 'loading' | undefined>();
    const { getFullMembershipInfo } = useAuthApi();
    const actingWallet = useActingWalletAddress();

    const membershipIds = premiumMembership?.membershipIds ?? [];

    React.useEffect(() => {
        setMainMembership('loading');
        const getMemberships = async () => {
            const _memberships = await Promise.all(membershipIds.map(membershipId => getFullMembershipInfo(membershipId)));
            const memberships = _memberships
                .filter((x): x is FullMembershipInfoDto => x !== undefined)
                .filter(
                    x =>
                        addressEquality(x.mainAddress, actingWallet) ||
                        x.additionalAddresses.some(addr => addressEquality(addr, actingWallet)),
                );

            if (memberships.length > 0) setMainMembership(memberships.find(x => x.tier == 'pro') ?? memberships[0]);
            else setMainMembership(undefined);
        };

        if (membershipIds.length !== 0) {
            getMemberships();
        }
    }, [membershipIds]);

    return (
        <>
            <ShadowBox w="fit-content">
                <Flex px="5" pt="5" direction={'column'}>
                    <HStack spacing={5} mb="5">
                        <Center h="36px" w="36px" p="6px" bg="#EDEDED" borderRadius={'5px'}>
                            <WarningIcon color="gray.600" w="20px" />
                        </Center>
                        <Text fontSize={'20px'} fontWeight="700">
                            Your Bulla membership has expired
                        </Text>
                    </HStack>
                    <Text>
                        <strong>Last membership paid:</strong>{' '}
                        {mainMembership && mainMembership !== 'loading' ? formatDate(mainMembership.startDate) : 'N/A'}
                    </Text>
                    <Text>
                        <strong>Membership expired:</strong>{' '}
                        {mainMembership && mainMembership !== 'loading' ? formatDate(mainMembership.expiry) : 'N/A'}
                    </Text>
                    <Text my="5" maxW="550px">
                        Access is limited to managing pending items. Click here to upgrade now or contact our sales team.
                    </Text>
                </Flex>
                <HStack spacing={'5'}>
                    <UpgradeButton onClick={() => navigate('/upgrade')} h={'44px'} />
                    <SecondaryButton onClick={() => window.open('https://calendly.com/bullanetwork', '_blank')}>
                        {' '}
                        Talk to Sales{' '}
                    </SecondaryButton>
                </HStack>
            </ShadowBox>
        </>
    );
};

const PendingClaims = ({
    items,
    title,
    emptyMessage,
    walletHeader,
    withPagination,
}: {
    items: BullaLineItems[];
    title?: React.ReactNode;
    emptyMessage?: string;
    walletHeader: string;
    withPagination?: boolean;
}) => {
    const userAddress = useActingWalletAddress();
    const openItem = useOpenBullaItem();
    const isMobile = useIsMobile();
    const pendingClaims = items
        .filter(item => (item.__type === 'Claim' ? item.claimStatus === 'Pending' : item.__type === 'PendingInstantPayment' ? true : false))
        .sort((a, b) => sortDesc(a.created.getTime(), b.created.getTime()));
    const { shownItems, ...pageSelectorProps } = usePagination(pendingClaims, 5);
    const pendingClaimsSliced = pendingClaims.slice(0, 5);
    const itemsToMemo = withPagination ? shownItems : pendingClaimsSliced;

    const pendingClaimsList = useMemo(
        () =>
            itemsToMemo.reduce<ListItemProps[]>((listItems, item) => {
                const isClaim = item.__type === 'Claim';
                const { creditor, debtor, description, tags, tokenInfo: token, id, chainId } = item;
                const hasTags = tags.length !== 0;
                const walletToShow = addressEquality(userAddress, creditor) ? debtor : creditor;
                const dueDate = isClaim ? toDateDisplay(item.dueBy) : '';
                const amount = isClaim ? item.claimAmount : item.paidAmount;

                const columnValues = [
                    <Text>{dueDate}</Text>,
                    ...(isMobile
                        ? []
                        : [
                              <ChainSymbol chainId={chainId} />,
                              <ContactNameOrAddress chainId={chainId}>{walletToShow}</ContactNameOrAddress>,
                              hasTags ? (
                                  <AccountTagViewer
                                      tags={tags}
                                      removeTag={() => {}}
                                      addTag={() => {}}
                                      creatingExpense={false}
                                      setEditing={{
                                          on: () => {},
                                          off: () => {},
                                      }}
                                      isDisabled={false}
                                      fieldName={'tags'}
                                      editState={'viewing only'}
                                      maxTagsDisplayed={1}
                                  />
                              ) : (
                                  <Text color={'gray.400'}>No category</Text>
                              ),
                              <TwoLineTextWithLabel>{description}</TwoLineTextWithLabel>,
                          ]),

                    <HStack w="100%">
                        <TokenAmount amount={amount} tokenInfo={token} />
                        <Spacer />
                        <ViewDetailsButton onClick={() => openItem(item)} />
                    </HStack>,
                ];
                return [...listItems, { columnValues }];
            }, []),
        [JSON.stringify(itemsToMemo), isMobile],
    );

    const dateHeader = isMobile ? 'date' : 'due date';
    return (
        <>
            <ListViewCard
                titleElement={title}
                headers={[dateHeader, ...(isMobile ? [] : ['chain', walletHeader, 'category', 'description']), 'amount']}
                displayedListItems={pendingClaimsList}
                emptyMessage={<Text textStyle="faint">{emptyMessage}</Text>}
                mt="12"
                //{...(isMobile ? { columnsCss: '0.8fr 1fr' } : {})}
            />
            {withPagination && <PageSelector {...pageSelectorProps} mt="5" />}
        </>
    );
};

export const ExpiredWithItemsPage = () => {
    const { payables, receivables } = useGlobalUserData('exclude-originating-claims');
    const isMobile = useIsMobile();

    return (
        <PageLayoutProvider>
            <MaxWidthWrapper>
                <Flex p={['8', '8', '12']} bg={'white'} direction="column" flex="1">
                    <Flex direction="column" justifyContent="space-between">
                        <Flex direction="column">
                            <Flex direction="column" mb="12" mt="4">
                                <CallToActionCard />
                                <PendingClaims
                                    walletHeader="to wallet"
                                    items={payables.filter(isClaim)}
                                    title={
                                        <Text textStyle="listTitle" fontSize={isMobile ? '19px' : '21px'}>
                                            Pending Payables
                                        </Text>
                                    }
                                    emptyMessage="You have no pending payables"
                                    withPagination={true}
                                />
                                <PendingClaims
                                    walletHeader="from wallet"
                                    items={receivables.filter(isClaim)}
                                    title={
                                        <Text textStyle="listTitle" fontSize={isMobile ? '19px' : '21px'}>
                                            Pending Receivables
                                        </Text>
                                    }
                                    emptyMessage="You have no pending receivables"
                                    withPagination={true}
                                />
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex>
            </MaxWidthWrapper>
            <CreateItemLinkHandler />
        </PageLayoutProvider>
    );
};
